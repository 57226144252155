import React from 'react'
import '../login-with-google/loginwithgoogle.css'

import { NavLink } from 'react-router-dom'
import TextField from '@mui/material/TextField';

function VerifyMobileNumber() {
  return (
    <div className="container-fluid">
        <div className="container">

            <div className="row">
                <div className="col-12">
                    <div className="form-card">
                    <div className='d-flex justify-content-start align-items-center mb-3' >
                        <NavLink to="/"><i class="fa-solid fa-arrow-left" style={{color:'black', fontSize:'20px'}}></i></NavLink>
                        </div>
                    <div>
                        <h3 className="text-start px-1 google-heading ">Verify your mobile number</h3>
                        <p style={{color:'#cfc7c7'}} className="text-start px-1">
                       enter the OTP sent to you on   </p>
                    </div>
                    <div className="mb-3">
                            <TextField id="standard-basic"  type='text' sx={{width:'100%'}} label={"OTP"} placeholder='Enter OTP' variant="standard" color="warning" />
                            <p className='text-end' style={{fontSize:'12px'}}>Resend OTP in <span className='text-primary-imp'>0.45sec</span></p>
                            </div>
                   
                    <div className="mt-4 mb-3 mt-5">
                    <NavLink to="/signup/enter-otp"><button className="submit-button">Login</button></NavLink> 
                    
                    </div>
                   
                    </div>
                </div>
            </div>
        </div>
     </div>
  )
}

export default VerifyMobileNumber