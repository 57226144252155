import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'aos/dist/aos.css';
// import {useEffect} from 'react';
import PropertyDetails from "./pages/property-details/PropertyDetails";
import ReviewDetails from "./pages/review-details/ReviewDetails";
// import SummarisingAction from "./pages/Summarising-action-point/SummarisingAction";
// import Signup from "./pages/authpage/registration/signup/Signup";
// import EnterOTP from "./pages/authpage/registration/optpage/EnterOTP";
// import EnterYourDetails from "./pages/authpage/registration/enter-your-details/EnterYourDetails";
// import LinkAccount from "./pages/authpage/registration/link-account/LinkAccount";
import LoginWithGoogle from "./pages/authpage/login-with-google/LoginWithGoogle";
import LoginWithAnotherAccounnt from "./pages/authpage/login-with-another-account/LoginWithAnotherAccount";
//import LoginWithMobile from "./pages/authpage/login-with-mobile/LoginWithMobile";
import LoginHomePage from "./pages/authpage/login-home-page/LoginHomePage";
// import UserLoginPage from "./pages/authpage/login-home-page/UserLoginPage";
import VerifyMobileNumber from "./pages/authpage/login-home-page/VerifyMobileNumber";
import Home from "./homepage/Home";
import FirstPage from "./pages/firstPage/FirstPage";
// import LaunchFirstAd from "./pages/launchFirstAd/launch-first-ad";
import LeapxSupport from "./pages/leapx-support/leapxSupport";
import SupportSuccess from "./pages/support-success/supportSuccess";
import LeadPage from "./pages/lead/leadPage";
import LeadMgmtPage from "./pages/lead-management/LeadMgmtPage";
import CampignMgmtPage from "./pages/campign-management/CampignMgmtPage";
// import PaymentHistoryPage from "./pages/paymentHistory/PaymentHistoryPage";
import AdOverViewPage from "./pages/ad-overview/adOverViewPage";
// import DraftSuccessPage from "./pages/DraftSuccess/DraftSuccessPage";
import PrivateRoute from "./PrivateRoutes";

import AdCampaignSummeryAndPay from "./pages/review-details/AdCampaignSummeryAndPay";
// import WebUserLogin from "./adesktop-webpage/web-auth/WebUserLogin";
// import WebSignup from "./adesktop-webpage/web-register/WebSignup";

import WebNavbar from "./adesktop-webpage/web-navbar/WebNavbar";
import WebHomepage from "./adesktop-webpage/web-homepage/WebHomepage";
import WebPropertyDetails from "./adesktop-webpage/web-campaign-creation/WebPropertyDetails";
import WebReviewDetails from "./adesktop-webpage/web-campaign-creation/WebReviewDetails";
import WebLeadMgn from "./adesktop-webpage/web-leadmgn-page/WebLeadMgn";
import WebAddCampOverview from "./adesktop-webpage/web-adcampoverview-page/WebAddCampOverview";
import WebCampMgn from "./adesktop-webpage/web-campmgn-page/WebCampMgn";
import WebLeadsPage from "./adesktop-webpage/web-leadspage/WebLeadsPage";
// import WebEnterYourDetails from "./adesktop-webpage/web-register/WebEnterYourDetails";
// import WebLinkAccount from "./adesktop-webpage/web-register/WebLinkAccount";
import WebLeapxSupport from "./adesktop-webpage/web-leapx-support/WebLeapxSupport";
import WebAccountInfo from "./adesktop-webpage/web-accountinfo/WebAccountInfo";
import WebAddCampSummeryAndPay from "./adesktop-webpage/web-campaign-creation/WebAddCampSummeryAndPay";
// import WebPaymentHistory from "./adesktop-webpage/web-payment-history/WebPaymentHistory";
// import TermsOfUse from "./pages/privacy-policy-termsof-use/TermsOfUse";
// import PrivacyPolicy from "./pages/privacy-policy-termsof-use/PrivacyPolicy";
import GoogleTabManagerWeb from "./GoogleTabManagerWeb";
import PaymentSuccessful from "./pages/DraftSuccess/PaymentSuccessful";
import LeadsPageFormMobile from "./pages/lead/LeadsPageFormMobile";
import ScrollToTop from "./ScrollToTop";
import PageNotFound from "./components/page-not-found/PageNotFound";
//import LandingPage from "./landing-page/LandingPage";
import RealEstateLeasingReview from "./campaign-creation-category/real-estate-category/for-leasing/RealEstateLeasingReview";
import ConfirmAndPayForLease from "./campaign-creation-category/real-estate-category/for-leasing/ConformAndPayForLease";
// import { useIntercom } from "react-use-intercom";
import ArticleLoader from "./IntercomArticle";
//import LoginWithMobileOrEmail from "./pages/authpage/login-home-page/login-with-mobile-or-email/LoginWithMobileOrEmail";
import LandingPage from "./landingpage/LandingPage";
// import { useIntercom } from "react-use-intercom";
// import IntercomProviderPage from "./intercom-chat/IntercomProviderPage";
import UserSignup from "./auth/UserSignup";
import UserSignupMobileDetails from "./auth/UserSignupMobileDetails";
import { IntercomManager } from "./intercom/IntercomManager";
import BusinessDetails from "./pages/account-details/BusinessDetails";
import FacebookPages from "./pages/account-details/FacebookPages";
import NotificationSettings from "./pages/account-details/NotificationSettings";
import AccountDetails from "./pages/account-details/AccountDetails";
import TransactionPage from "./pages/paymentHistory/TransactionPage";
import LoginUser from "./auth/LoginUser";
import ForgotPasswordPage from "./auth/ForgotPasswordPage";
// import AddBudgetSuccessPayment from "./pages/ad-overview/addbudget/AddBudgetSuccessPage";
// import TopBanner from "./components/topbanner/TopBanner";

// import { useIntercom } from 'react-use-intercom';
// const INTERCOM_APP_ID = 'your-intercom-app-id';
// import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";





function App() {



  return (
    <>
      <div className="d-block d-md-none">
        <BrowserRouter>

          <ArticleLoader />
          <ScrollToTop />
          <GoogleTabManagerWeb />
          <IntercomManager>
            {/* <TopBanner/> */}
            <Routes>

              <Route path="/signup-mobile" element={<UserSignupMobileDetails />} />
              <Route path="/signup" element={<UserSignup />} />
              <Route
                path="/property-details"
                element={<PrivateRoute Component={PropertyDetails} />}
              />

              <Route path="/leapx-for-realestate" element={<LandingPage />} />
              {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              {/* <Route path="/terms-of-use" element={<TermsOfUse />} />
               <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
              <Route path="/" element={<PrivateRoute Component={Home} />} />
              <Route
                path="/review-details"
                element={<PrivateRoute Component={ReviewDetails} />}
              />
              <Route
                path="/review-details-lease"
                element={<PrivateRoute Component={RealEstateLeasingReview} />}
              />
              {/* <Route
          path="/summarising-action-points"
          element={<PrivateRoute Component={SummarisingAction} />}
        /> */}
              {/* <Route path="/login" element={<UserLoginPage />} /> */}
              <Route path="/login" element={<LoginUser />} />

              {/* <Route path="/signup" element={<Signup />} /> */}
              {/* <Route path="/signup-1" element={<Signup />} /> */}
              {/* <Route path="/landing-page" element={<LandingPage/>} /> */}
              {/* <Route path="/signup/enter-otp" element={<EnterOTP />} /> */}
              {/* <Route
          path="/signup-2"
          element={<PrivateRoute Component={EnterYourDetails} />}
        />  */}
              {/* <Route
          path="/signup/business-details"
          element={<PrivateRoute Component={EnterYourDetails} />}
        />  */}
              {/* /signup/enter-otp/enter-details" */}
              {/* <Route
          path="/signup-3"
          element={<PrivateRoute Component={LinkAccount} />}
        /> */}
              {/* <Route
          path="/signup/link-facebook"
          element={<PrivateRoute Component={LinkAccount} />}
        /> */}
              <Route path="/login-with-google" element={<LoginWithGoogle />} />
              <Route
                path="/login-with-another-account"
                element={<LoginWithAnotherAccounnt />}
              />
              {/* <Route path="/login-with-mobile" element={<LoginWithMobile />} /> */}
              <Route path="/user-login" element={<LoginHomePage />} />

              <Route path="/verify-mobile-number" element={<VerifyMobileNumber />} />
              {/* <Route path="/Dashboard" element={<Home />} /> */}
              <Route
                path="/profile"
                element={<PrivateRoute Component={FirstPage} />}
              />
              <Route
                  path="/account-details"
                  element={<PrivateRoute Component={AccountDetails} />}
                />
                <Route
                  path="/business-details"
                  element={<PrivateRoute Component={BusinessDetails} />}
                />
                <Route
                  path="/facebook-page"
                  element={<PrivateRoute Component={FacebookPages} />}
                />
                <Route
                  path="/notification-settings"
                  element={<PrivateRoute Component={NotificationSettings} />}
                />
              {/* <Route
          path="/launch-first-ad"
          element={<PrivateRoute Component={LaunchFirstAd} />}
        /> */}
              <Route path="/payment-successful" PrivateRoute Component={PaymentSuccessful}  />
              {/* <Route path="/payment-successful" element={<AddBudgetSuccessPayment />} /> */}
              <Route
                path="/leapx-support"
                element={<PrivateRoute Component={LeapxSupport} />}
              />

              <Route
                path="/support-success"
                element={<PrivateRoute Component={SupportSuccess} />}
              />
              <Route
                path="/campaign-summary"
                element={<PrivateRoute Component={AdCampaignSummeryAndPay} />}
              />
              <Route path="/lease-campaign-summary" element={<ConfirmAndPayForLease />} />
              <Route path="/leads" element={<PrivateRoute Component={LeadPage} />} />
              <Route path="/leads-form" element={<PrivateRoute Component={LeadsPageFormMobile} />} />
              <Route
                path="/lead-management"
                element={<PrivateRoute Component={LeadMgmtPage} />}
              />
              <Route
                path="/campaign-management"
                element={<PrivateRoute Component={CampignMgmtPage} />}
              />
              {/* <Route
                path="/transactions"
                element={<PrivateRoute Component={TransactionPage} />}
              /> */}
                <Route
                path="/transactions"
                element={<TransactionPage/>}
              />
              <Route
                path="/campaign-overview"
                element={<PrivateRoute Component={AdOverViewPage} />}
              />
              {/* <Route
          path="draft-successful"
          element={<PrivateRoute Component={DraftSuccessPage} />}
        /> */}
              {/* <Route path="reset-password" element={<ResetPasswordPage />} /> */}
              <Route path="*" element={<PageNotFound />} />






            </Routes>
          </IntercomManager>
        </BrowserRouter>
      </div>
      {/* FOR DESKTOP VIEW */}
      <div className="d-none d-md-block">
        <BrowserRouter>

          <ArticleLoader />
          {/* <GoogleTabManagerWeb/> */}
          <IntercomManager>
          {/* <TopBanner/> */}
            <Routes>

              <Route path="/signup-mobile" element={<UserSignupMobileDetails />} />
              <Route path="/signup" element={<UserSignup />} />
             

              <Route path="/leapx-for-realestate" element={<LandingPage />} />
              {/* <Route path="/login" element={<WebUserLogin />} /> */}
              <Route path="/login" element={<LoginUser />} />
              {/* <Route path="/signup-1" element={<WebSignup/>} /> */}
              {/* <Route path="/landing-page" element={<LandingPage/>} /> */}
              {/* <Route path="/forgot-password" element={<WebForgotPassword />} /> */}
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              {/* <Route path="/signup/business-details"  element={<WebEnterYourDetails/>} /> */}
              {/* <Route path="/signup-2" Component={WebEnterYourDetails}/>  */}
              {/* <Route path="/signup-3" Component={WebLinkAccount} /> */}

              {/* <Route path="/navbar" element={<WebNavbar/>} /> */}
              <Route path="/" element={<PrivateRoute Component={WebNavbar} />} >
                <Route path="/" element={<WebHomepage />} />
                <Route path="/property-details" element={<WebPropertyDetails />} />
                <Route path="/review-details" element={<WebReviewDetails />} />
                <Route
                  path="/review-details-lease"
                  element={<PrivateRoute Component={RealEstateLeasingReview} />}
                />
                <Route path="/lead-management" element={<WebLeadMgn />} />
                <Route path="/campaign-management" element={<WebCampMgn />} />
                <Route path="/campaign-overview" element={<WebAddCampOverview />} />
                <Route path="/leads" element={<WebLeadsPage />} />
                <Route path="/leapx-support" element={<WebLeapxSupport />} />
                <Route path="/profile" element={<WebAccountInfo />} />
                <Route
                  path="/account-details"
                  element={<PrivateRoute Component={AccountDetails} />}
                />
                 <Route path="/payment-successful" PrivateRoute Component={PaymentSuccessful}  />
                <Route
                  path="/business-details"
                  element={<PrivateRoute Component={BusinessDetails} />}
                />
                <Route
                  path="/facebook-page"
                  element={<PrivateRoute Component={FacebookPages} />}
                />
                <Route
                  path="/notification-settings"
                  element={<PrivateRoute Component={NotificationSettings} />}
                />
                <Route path="/campaign-summary" element={<WebAddCampSummeryAndPay />} />
                <Route path="/lease-campaign-summary" element={<ConfirmAndPayForLease />} />
                <Route path="/payment-successful" element={<PaymentSuccessful />} />
                <Route path="/transactions" element={<TransactionPage />} />
                {/* <Route path="draft-successful" element={<PrivateRoute Component={DraftSuccessPage} />}/> */}
              </Route>
              <Route path="*" element={<PageNotFound />} />

            </Routes>
          </IntercomManager>
        </BrowserRouter>
      </div>

    </>
  );
}

export default App;
