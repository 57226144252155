import React from 'react'
import './landingpage-component.css';
import './lp-meadiaquery.css'
import soicialicon1 from '../../asets/asetes/Social (1).png'
//import soicialicon1 from '../../asetes/Social (1).png'
import soicialicon2 from '../../asets/asetes/Social (2).png'
import soicialicon3 from '../../asets/asetes/Social (3).png'
import soicialicon4 from '../../asets/asetes/Vector (3).png'
import soicialicon5 from '../../asets/asetes/Social.png'
// import TryFreeAndDemo from './TryFreeAndDemo';
import magicbtnicon from '../../asets/asetes/magicbtn icon.png'
import herobgimg from '../../asets/asetes/heroscreen bg img2.svg'
import TryFreeAndDemo from './TryFreeAndDemo';
// import partnerlogo from '../../asets/asetes/login-1-image (4).png'
// import previewimg from '../../asets/asetes/video img.png';

function HeroContent() {
  return (
       <div className="container-fluid overflow-hidden position-relative hero-container-px pb-md-1 mb-0 hero-bg-background  hero-container-bg" style={{}}>
          {/* <div className='hero-container-bg position-absolute' style={{width:'100%', height:'70vh', zIndex:'0.9', opacity:'0.4' }}></div> */}
          <div className="herobgscreen postion-absolute m-0 p-0 " style={{overflow:'hidden'}} >
            <img src={herobgimg}  style={{objectFit:'cover', height:'355'}} className='position-absolute hero-dotted-bgimg' alt="" />
          </div>
        <div className="container position-realtive" style={{zIndex:'999'}}>
            <div className="row">
                <div className="hero-content-container position-relative " style={{zIndex:'999'}}>
                    <div className='text-center mt-3'>
                        <button style={{cursor:'auto'}} className="magic-of-ai-btn1">
                          <img src={magicbtnicon} alt="" /> &nbsp; AI Magic, Real Results
                        </button>
                    </div>
                    <div className='text-center my-4 position-relative' style={{zIndex:'999'}}>
                    <p className='text-center p-0 hero-text-h' style={{width:'70%', margin:'0 auto'}}><span className='text-gradient'>Transform</span>  Real Estate Advertising with LeapX AI</p>
                    {/* <p className='text-center p-0 m-0 hero-text-h'>Generate high quality <span style={{color:'#F07A4C'}}> leads and prospects</span></p> */}
                     <div className='d-flex mb-4 mt-3 justify-content-center align-items-center'>
                     <p className='text-center p-0 m-0 hero-text-p'>Effortlessly scale your property sales using our AI-driven advertising platform. Create, Deploy & Optimize ads, target ideal buyers, and accelerate your sales cycle with precision.</p>

                     </div>
                    </div>
                   
                    <div className='socialicon-container d-flex justify-content-center align-items-center my-4'>
                        <div className='d-flex justify-content-center gap-2 align-items-center socialicon-inner-container'>
                               <img src={soicialicon1} className='img-fluid' alt="" />
                               <img src={soicialicon2} className='img-fluid' alt="" />
                               <img src={soicialicon3} className='img-fluid' alt="" />
                               <img src={soicialicon4} className='img-fluid' alt="" />
                               <img src={soicialicon5} className='img-fluid' alt="" />
                        </div>
                    </div>

                    <div className="tryfreedemo-hero-container mt-4 pb-3 pb-md-5 mb-md-1">
                        <TryFreeAndDemo/>

                    </div>
                    {/* <div className='' style={{background:''}}>
                      <p className='importantoutcome-p my-3 my-md-0 pt-0 pb-0 text-center'>Supported by</p>
                      <div className='text-center ' style={{width:'100%'}}>
                        <img src={partnerlogo} className='partnerlogo'  alt="" />
                      </div>
                    </div> */}

                </div>
            </div>
        </div>
       </div>
  )
}

export default HeroContent