import { Card } from '../../../components/ui/card'
import React, {useState} from 'react'
import { CampaignOverviewDateRange } from './CampaignOverviewDateRange';

function OverviewCampaigninfoCard({projectData}) {
    //  console.log("projectData", projectData)
     const [showMore, setShowMore] = useState(false);
     const summaryText = projectData?.summary || "";
     const truncatedSummary = summaryText.slice(0, 120);
     
     const toggleShowMore = () => {
       setShowMore(prevState => !prevState);
     };
  return (
      <>
        {projectData && <Card className=" p-4 shadow-none overflow-hidden me-2">
            <div>
                <h4 className='text-[16px] text-start  font-bold'>Campaign Info</h4>

            </div>
          {projectData?.url &&  <div className='flex justify-between align-middle overview-cc-text'>
                <p className='overview-cc-text'>Website Link :</p>
                <p className='text-end  max-w-[70%] ' style={{fontWeight:'600'}}><a href={projectData?.url} target='_blank' rel="noreferrer" className='text-decoration' style={{textDecoration:'underline'}}>{projectData?.url}</a></p>
            </div>} 
           {projectData?.name && <div  className='flex justify-between align-middle overview-cc-text'>
                <p className='w-[300px]'>Property Name :</p>
                <p className='text-end w-[70%]' style={{textTransform:''}}>{projectData?.name}</p>
            </div>} 
          {projectData?.location && <div  className='flex justify-between align-middle overview-cc-text'>
                <p>Location :</p>
                <p className='text-end max-w-[70%]'>{projectData?.location}</p>
            </div>}  
           {projectData?.type && <div  className='flex justify-between align-middle overview-cc-text'>
                <p>Property Type :</p>
                <p className='text-end max-w-[70%]'>{projectData?.type}</p>
            </div>} 
            {/* <div  className='flex justify-between align-middle overview-cc-text'>
                <p>Starting Price :</p>
                <p className='text-end max-w-[70%]'>{projectData?.starting_price}</p>
            </div> */}
         {projectData?.size && <div  className='flex justify-between align-middle overview-cc-text'>
                <p>Size :</p>
                <p className='text-end max-w-[70%]'>{projectData?.size}</p>
            </div>}   
            {/* summery */}
            <div>
                <h4 className='text-[16px] text-start  font-bold'>Summary</h4>

            </div>
            <div>
      <p
        dangerouslySetInnerHTML={{ __html: showMore ? summaryText : truncatedSummary }}
        style={{ textAlign: "justify", color: '#585858', fontSize: '12px' }}
      />
      {summaryText.length > 120 && (
        <button onClick={toggleShowMore} style={{color:'rgba(102, 35, 204, 1)'}} className=" mt-2">
          {showMore ? "read less" : "read more.."}
        </button>
      )}
    </div>

    <div className='mt-3'>
    <div>
                <h4 className='text-[16px] text-start  font-bold'>Images</h4>
{/* <CampaignOverviewDateRange/> */}
            </div>
            <div className='flex justify-start align-middle gap-2'>
            {projectData?.images?.length > 0 && (
                              <>
                                {projectData?.images?.map((val) => (
                                  <div>
                                    <img src={`${val}`} width={40} height={30} alt="" />
                                  </div>

                                ))}
                              </>
                            )}
            </div>
    </div>
         </Card>} 
        
      </>
  )
}

export default OverviewCampaigninfoCard