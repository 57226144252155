import React, { useContext, useEffect, useRef, useState } from "react";
//import "../property-details/properttydetails.css";

import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MuiChipsInput } from "mui-chips-input";
import DatePicker from "react-datepicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {  Step, StepLabel, Stepper} from "@mui/material";
import { useLocation } from "react-router-dom";
// import Navbar2 from "../../navbar/Navbar2";
import property from "../../../asets/property.png";
import location from "../../../asets/location.png";
import sizeimg from "../../../asets/size.png";
import rupes from "../../../asets/rupes.png";
import calander from "../../../asets/calander.png";
import area from "../../../asets/area.png";
import donetext from "../../../asets/donetext.png";
//import { AuthContext } from "../../AuthProvider";
//import Axios from "../../Axios";
import Cookies from "js-cookie";
import CancelIcon from '@mui/icons-material/Cancel';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Button} from "@mui/material";
import { AuthContext } from "../../../AuthProvider";
import Axios from "../../../Axios";
import LoadingForReviewDetails from "../../../components/LoadingScreen/LoadingForReviewDetails";
import Navbar2 from "../../../navbar/Navbar2";
import WebStepper from "../../../adesktop-webpage/web-component/webstepper/WebStepper";
// import WebStepper from "../../adesktop-webpage/web-component/webstepper/WebStepper";

// import LoadingForReviewDetails from "../../components/LoadingScreen/LoadingForReviewDetails";


function RealEstateLeasingReview() {
  const initialState = {
    Name:"",
    Address:"",
    Property:"",
    Size:"",
    Price:"",
    Status:"",
    // Designed: "",
    Budget: 19900,
    Day: 30,
    Flat:"",
    creativesFlag:false
  };
  const navigate = useNavigate();
  const [laodGeoLocation, setLoadGeoLocation] = useState(false)
  const { userToken, setCampaignId, setPageId } =useContext(AuthContext);
  const { state } = useLocation();
 //console.log("add",state?.address)
  const urlData = state?.weburl;
  const is_sell = state?.is_sell;
  const [isLoading, setIsLoading] = useState(false);
  const [campaignBudgetData, setcampaignBudgetData] = useState(19900);
  const [showForCowrking, setForCoaworking] = useState(false)
const [getPropertyId, setGetPropertyId] = useState('')
  const [ selectedImages,  setSelectedImages] = useState([])
    const [images, setImages] = useState([]);
    const [userDetail, setUserDetail] = useState({});
    const fileInputRef = useRef(null);
    const [selectFacebookPage, setSelectFacebookPage] = useState(null);
    const [ draftgetProperty,  setDraftgetProperty] = useState("")
    const [draftcmapId, setDraftcampId] = useState("")
    const [propImages, setPropImages] = useState([])
    const [Highlights, setHighlights] = useState([]);
    const [Dates, setDates] = useState('');
    const [modal, setModal] = useState(false);
    const [value, setValue] = useState(initialState);
     const [targettingLocation, setTargettingLocation] = useState([])
     const [checkedLocation, setCheckedLocation] = useState([])
   const [searchValue, setSearchValue] = useState("")
   const [designCreativeStatus, setDesignCreativeStatus] = useState(false)

    useEffect(()=>{
      setDraftcampId(state?.campaignDfId)
    //  console.log("dfid",state.campaignDfId )
    },[state?.campaignDfId])

 
  // console.log("valueeee", value)
  const [propertyType, setPropertyType] = useState();

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      Name: state?.address?.Name || "",
      Address: state?.address?.Address || "",
      Property: state?.address?.Type || "",
      Size: state?.address?.Sizes || "",
      Price: state?.address?.['Price Range'] || "",
      Status: state?.address?.Status || "",
      Budget: 19900,
      Day: 30,
      Flat: state?.address?.Subtype || "",
     creativesFlag:state?.address?.creativesFlag
    }));
    if(state?.address?.Amenities){
        const Amenitiesdata = state?.address?.Amenities?.split(", ");
           if(Amenitiesdata?.length){
            setHighlights(Amenitiesdata)
           }
     
       // console.log("Amenitiesdata", Amenitiesdata)
    }

    if(state?.address?.['Possession Date']?.length>0){
      const parsedDate = new Date(state?.address?.['Possession Date']);
    
          setDates(parsedDate)
    }
  }, [state?.address]);
// console.log("issell", state?.is_sell)
  //get property for draft campaign
   const handleGetPropertyForDreaft = async (propertyId) =>{
        try {
           const res = await Axios.get("/get-property", {
              headers:{
                 Authorization:userToken
              },
              params:{
                property_id:propertyId
              }
           });
           if(res.status===200){
            const data = res?.data?.message
              if(data){
                    setDraftgetProperty(data)
                     setGetPropertyId(data?._id)   
                        // console.log("modifyobj",data)

                      setValue((prev) => ({
                        ...prev,
                        Name: data?.name || "",
                        Address: data?.location || "",
                        Property: data?.type || "",
                        Size: data?.size || "",
                        Price: data?.starting_price || "",
                        Status: data?.status || "",
                        Budget: 19900,
                        Day: data.days ,
                        Flat: data?.is_flat || "",
                      }));     
                      setPropImages(data?.images)   
                       if(data?.key_highlights?.length>0){
                        const keyhigh = data?.key_highlights?.split(", ");
                        if(keyhigh?.length){
                         setHighlights(keyhigh)
                        }
                       }
              }
              // console.log("propertydata", data)
           }
        } catch (error) {
          //  alert("get properrty failed")
            console.log(error.message)
        }
   }

 useEffect(()=>{
  handleGetPropertyForDreaft(state?.propertyId)
 },[state?.propertyId])


  useEffect(() => {
    if (value?.Property === "Residential") {
      setPropertyType(["Flat", "Plot"]);
    } else if(value?.Property === "Commercial"){
      setPropertyType(["Shop/Showroom", "Land", "Office", "Co-Working Space"]);
    }
  }, [value?.Property]);

  useEffect(() => {
    handleUser();
  }, []);
  const handleUser = async () => {
    //   let stringWithoutDoubleQuotes = tokenLogin.replace(/"/g, "");
    Axios.get("/user", {
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const data = response?.data?.user;
        // console.log("==============================>", data);
        setUserDetail(data);
      })
      .catch((error) => {
        console.log("Error setting up request:", error);
      });
  };
  const handleInputChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
 
  
  const handleChange = (newChips) => {
    setHighlights(newChips);
  };

  const steps = ["Provide details", "Review details", "Confirm & Pay"];

  const handleProperty = (event) => {
    setValue({
      ...value,
      Property: event.target.value,
    });
  };
  const handleFlat = (event) => {
      
    setValue({
      ...value,
      Flat: event,
    });
  };
  const handleStatus = (event) => {
    setValue({
      ...value,
      Status: event.target.value,
    });
  };

useEffect(()=>{
     if(value?.Flat==="Shop/Showroom" || value?.Flat==="Land" || value?.Flat==="Office"){
        setForCoaworking(false)
     }
    if(value?.Flat==="Co-Working Space"){
        setForCoaworking(true)
    } 

},[value?.Flat])




  const handleCreativeFlag = (e)=>{
    setDesignCreativeStatus(true)
        setValue({
          ...value,
          creativesFlag:e.target.value
        })

    
       
        if(e.target.value){
          setDesignCreativeStatus(true)
        }else{
          setDesignCreativeStatus(false)
        }

       
  }


  const handlePropertySubmit = () => {
    if (
      !(
        value.Name &&
        value.Address &&
        value.Budget &&
        value.Flat &&
        value.Price &&
        value.Size &&
        (images.length )
      )
    ) {
      alert("please entered all required fields");
      return;
    }
    if(value?.creativesFlag == 'true' && images.length>2){
        alert("You can select upto 2 images")
        return
    }
    if(value?.creativesFlag == 'false' && images.length>5){
      alert("You can select upto 5 images")
      return
    }
    setModal(true);
    const key = Highlights.join(", ");
    const dateObject = new Date(Dates);
    const year = dateObject.getFullYear();
    const body = {
      name: value?.Name,
      location: value?.Address,
      type: value?.Property,
      size: value?.Size,
      starting_price: value?.Price,
      key_highlights: key?.length?key:'85% Green and Open areas, Largest Club-house (1.25 lacs sqft), Double Height Entrance Lobby, 9+ft wide party decks',
      status: value?.Status,
      possession_date: year,
      campaign_budget: Number(campaignBudgetData),
      is_flat: value?.Flat,
      lifetime_budget: Number(campaignBudgetData),
      url: urlData,
      is_sell:is_sell,
      creativesFlag:value?.creativesFlag
    
    };

    const formData = new FormData();
   formData.append("name", body.name)
   formData.append("location", body.location)
   formData.append("type", body.type)
   formData.append("size", body.size)
   formData.append("starting_price", body.starting_price)
   formData.append("key_highlights", body.key_highlights)
   formData.append("status", body.status)
   formData.append("possession_date", body.possession_date)
   formData.append("campaign_budget", body.campaign_budget)
   formData.append("is_flat", body.is_flat)
   formData.append("lifetime_budget", body.lifetime_budget)
   formData.append("geolocation", JSON.stringify(checkedLocation))
   formData.append("url", body.url)
   formData.append("is_sell", body.is_sell)
   formData.append("creativesFlag", body.creativesFlag)
  
 //formData.append('images', selectedImages);
   if(images?.length){
    for (let i=0; i<selectedImages.length; i++){
      // formData.append("images", selectedImages[0])
      formData.append("images", selectedImages[i]);
   }
   }
//  selectedImages.forEach((val)=>{
//   formData.append('images', value);
//  })

    Axios.post("/create-property", formData, {
      headers: {
        Authorization: userToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.status === 200) {
            if(response?.data?.valid){
              const data = response?.data?.campaign;
              const data2 = response?.data?.message;
             
              setCampaignId(data?._id);
              localStorage.setItem('propertyid',data?.property_id);
              Cookies.set("campaignID", data?._id, { expires: 7 });
              
            
              setModal(false);
              navigate("/lease-campaign-summary", {state:{
                campaign:data,
                message:data2,
                status:0,
                propertyId:data?.property_id,
                campaignDfId:data?._id
              }})
            }
            setModal(false);
          
        }
        
        //  handleCampaign(data?._id, data2?.end_date, data?.page_id);
      })
      .catch((error) => {
        setModal(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setPageId(selectFacebookPage?.id);
    Cookies.set("pageId", selectFacebookPage?.id, { expires: 7 });
  }, [selectFacebookPage]);

   


 // console.log("summery", modifiedObject?.Summary);


 const handleSearchTargettingLocation = async(searchvalue)=>{
  setLoadGeoLocation(true)
        const body = {
          "location": searchvalue?searchvalue:'',
          "types": [ "city","region"]  
      }
        try {
           const res =await Axios.post("/search-location", body, {
            headers:{
              Authorization:userToken
            }
           })
         //  console.log("location", res)
           if(res.status===200){
                const data = res?.data?.message?.data;
                setTargettingLocation(data)
               // console.log("location", data)
           }
        } catch (error) {
           console.log(error?.message)
        }finally{
          setLoadGeoLocation(false)
        }
 }
  

 const searchLocation = (e)=>{
     setSearchValue(e.target.value)
 }

// useEffect(()=>{
//   handleSearchTargettingLocation(searchValue)
// },[searchValue])

  
  const handleImageUpload = async(event) => {
          
    const files = event.target.files;
              //console.log("file", event)
             
            
             if (!files.length) {
              return; // No files selected, do nothing
            }
            
        // if(files.length>5 && value.creativesFlag == 'false'){
        //     alert("You can select upto 5 images")
        //     setSelectedImages([])
        //      return 
        // }else if(files.length>2 && value.creativesFlag == 'true'){
        //   alert("You can select upto 2 images")
        //   setSelectedImages([])
        //   return 
        // }else if(files.length>0 && files.length<6){
            
        //     setSelectedImages(files)

        //   }     
      // console.log("file", files)
      setSelectedImages(files)
    const selectedImagesArray= [];
     
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        selectedImagesArray.push({ file: files[i], preview: e.target.result});
        if (selectedImagesArray.length === files.length) {
          setImages(selectedImagesArray);
        }
      
      };
      reader.readAsDataURL(files[i]);
    //  reader.readAsDataURL(files[i]);
    }
  };


 
  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  //  console.log("updatedImages", updatedImages)

    const updatedImagesSelected = [...selectedImages];
    updatedImagesSelected.splice(index, 1);
  //  console.log("selectedImages", selectedImages)
  setSelectedImages(updatedImagesSelected);
   
  fileInputRef.current.value = '';
  };

  

 //edit property for draft
 const handleEditPrpertyForDraft = async () => {
   
  
const key = Highlights.join(", ");
const dateObject = new Date(Dates);
const year = dateObject.getFullYear();
const body = {
name: value?.Name,
location: value?.Address,
type: value?.Property,
size: value?.Size,
starting_price: value?.Price,
key_highlights: key,
status: value?.Status,
possession_date: year,
campaign_budget: Number(campaignBudgetData),
is_flat: value?.Flat,
lifetime_budget: Number(campaignBudgetData),
url: urlData?.length?urlData:null,
property_id:state?.propertyId
};


if(value?.creativesFlag==true && images.length>2){
  alert("You can select upto 2 images")
  return
}
if(value?.creativesFlag==false && images.length>5){
alert("You can select upto 5 images")
return
}





const formData = new FormData();
formData.append("name", body.name)
formData.append("location", body.location)
formData.append("type", body.type)
formData.append("size", body.size)
formData.append("starting_price", body.starting_price)
formData.append("key_highlights", body.key_highlights)
formData.append("status", body.status)
formData.append("possession_date", body.possession_date)
formData.append("campaign_budget", body.campaign_budget)
formData.append("is_flat", body.is_flat)
formData.append("lifetime_budget", body.lifetime_budget)
formData.append("property_id", body?.property_id)
formData.append("geolocation", JSON.stringify(checkedLocation))
formData.append("campaign_id", draftcmapId)
    if(images.length){
      for (let i =0; i<selectedImages.length; i++){
        formData.append("images", selectedImages[i])
      }
    }


  setIsLoading(true);
  try {
    const res = await Axios.post("/edit-property", formData, {
      headers: {
        Authorization: userToken,
      },
     
    });
    if (res.status === 200) {
      const data2 = res?.data?.message;
      const data = res?.data?.campaign
        
      localStorage.setItem('propertyid',data?.property_id);

      navigate("/lease-campaign-summary", {state:{
        campaign:"",
        message:data2,
        status:state?.status,
        propertyId:state.propertyId,
        campaignDfId:state?.campaignDfId
      }})
          
    }
  } catch (error) {
    setIsLoading(false);
     //alert("failed")
    console.log(error.message);
  }
};




  function handleNextClick(statustype) {
         if(statustype===1){

         
    handlePropertySubmit();
         }else{
           
      

          handleEditPrpertyForDraft()

         }
   
   
  }

  const ImageThumbnail = ({ src, onDelete }) => {
      // console.log("src", src)
   return(
    <>
   
    <div className="image-thumbnail-container">
      <img className="image-thumbnail " src={src} alt="thumbnail" />
        <span onClick={()=>onDelete(onDelete)} className="delete-preview">
        <CancelIcon sx={{fontSize:'18px', }}  />
        </span>
      
    </div>
    </>
    )
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#6622cc', // Active step background color
      },
      secondary: {
        main: '#000', // Active step label color
      },
    },
  });

  const stepweb = [
    {
      label: 'Provide details',
      description: `Please copy paste the website link of the project for which you need the leads`,
    },
    {
      label: 'Review details',
      description:"We have pulled and pre-filled the details of the property for your convenience kindly review/ edit/ add details in the form below."
    },
    {
      label: 'Confirm & Pay',
      description:null,
    },
  ];

 

  // console.log("valueee", value)
  // console.log("img", images)
  return (
    <>
   
     {modal || isLoading? (<LoadingForReviewDetails/>):(
      <>
       <div className="p-0 m-0 d-md-none">
     <Navbar2 menu={true} />
     </div>
       <div className="container-fluid" id="reviewDetails">
              <div className="container">
                <div className="row">
                   <div className="col-12">
                   <div className="d-none d-md-block">
              <WebStepper steps={stepweb} active={1}/>
             </div> 

                    {/* test */} 
                   
                    {/* testend */}
                   <div
                          className="mt-4"
                          style={{ paddingTop: 20, paddingBottom: 10 }}
                        >
                          <Box sx={{ width: "100%", }} className="mt-1 d-md-none">

                          <ThemeProvider theme={theme}>
                            <Stepper  activeStep={1} alternativeLabel>
                              {steps.map((label) => (
                                <Step key={label} sx={{'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text':{fill:'white'},'& .MuiStepLabel-root .Mui-active':{color:'rgba(102, 34, 204, 1)'}, '& .MuiStepLabel-root .Mui-completed':{color:'rgba(102, 34, 204, 1)'} }}>
                                  <StepLabel sx={{
                                          '& .Mui-active': {
                                            color: 'secondary.main',
                                          },
                                          '& .Mui-completed': {
                                            color: 'secondary.main', // Active label color
                                          },
                                          
                                        }} style={{ fontSize: 10 }}>
                                    {label}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                            </ThemeProvider>
                              </Box>
                        </div>
                   </div>
                  <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">
                    <div className="form-card  mt-md-0 rounded">
                      <div className="form">
                       




                        <div className="mb-3 ">
                          <p className="heading-Property">Review Details</p>
                          <p
                            style={{ textAlign: "justify", color:'#B0B0B0', fontWeight:'400' }}
                            className="heading-Property1"
                          >
                            We have pulled & pre-filled the details of the property for your convenience. Kindly review/edit/add details as required
                          </p>
                        </div>
                           
                         <div className="d-flex flex-md-row flex-column  justify-content-between align-items-start ">

                       
                         <div style={{width:'100%'}} className="pe-md-5 pe-0">                        
                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b style={{fontWeight:'500', color:'black'}}>Property Name*</b>
                        </label>
                        <div
                          className={
                            "input-container mb-3 "
                            //  +(value.Name === "" && "validationFailed")
                          }
                        >
                          <img src={property} width={15} height={20} alt="" />
                          <input
                            value={value?.Name}
                            onChange={handleInputChange}
                            type="text"
                            className={"textInput-q"}
                            placeholder="Enter Property Name"
                            name="Name"
                            id="Name"
                            required
                          />
                          {value?.Name && (
                            <img src={donetext} width={14} height={14} alt="" />
                          )}
                        </div>
                        </div>
                        <div style={{width:'100%'}} className=" pe-0">                    
                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b style={{ fontWeight:'500', color:'black'}}>Location*</b>
                        </label>
                        <div
                          className="input-container "
                          // style={!value?.Address ? { border: "1px solid red" } : {}}
                        >
                          <img src={location} width={15} height={20} alt="" />
                          <input
                            value={value?.Address}
                            onChange={handleInputChange}
                            name="Address"
                            id="Address"
                            className={"textInput-q"}
                            type="text"
                            required
                            placeholder="Enter Property Location"
                          />
                          {value?.Address && (
                            <img src={donetext} width={14} height={14} alt="" />
                          )}
                        </div>
                        <p className="mb-3 creative-location">
                          "Location you want to show in the creative”

                        </p>
                        </div>
                        </div>
                        <div className="mb-3 text-start d-flex justify-content-between align-items-ceter">
                          <div>
                          <FormControl>
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              sx={{ color: "black" }}
                            >
                              <b style={{fontSize:'16px', fontWeight:'500', color:'black'}}>Property type*</b>
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={value?.Property}
                              onChange={handleProperty}
                            >
                              <div
                                className={
                                  value?.Property === "Residential"
                                    ? "checkbox"
                                    : "checkbox1"
                                }
                              >
                                <FormControlLabel
                                  value="Residential"
                                  control={
                                    <Radio style={{ color: "#6622CC" }} />
                                  }
                                  label="Residential"
                                />
                              </div>
                              <div
                                className={
                                  value?.Property === "Commercial"
                                    ? "checkbox"
                                    : "checkbox1"
                                }
                              >
                                <FormControlLabel
                                  value="Commercial"
                                  control={
                                    <Radio style={{ color: "#6622CC" }} />
                                  }
                                  label="Commercial"
                                />
                              </div>
                              
                            </RadioGroup>
                          </FormControl>
                          <div style={{ width: "100%", textAlign: "left" }}>
                            {propertyType?.map((item, index) => {
                              return (
                                <Button
                                onClick={() => {
                                  handleFlat(item);
                                }}
                                 sx={{borderRadius:'14px', marginLeft:'5px', marginBottom:'3px'}}
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      item === value?.Flat ? "#6622CC" : "#ddd",
                                    color:
                                      item === value?.Flat
                                        ? "#ffffff"
                                        : "#000000",
                                    fontSize: 12,
                                  }}
                                >
                                  {item}
                                </Button>
                              );
                            })}
                          </div>
                          </div>
                          {/* <div className="" style={{width:'50%'}}>
                          <Autocomplete
                            multiple
                            loading={laodGeoLocation}
                            id="checkboxes-tags-demo"
                            options={targettingLocation}
                            disableCloseOnSelect
                            fullWidth={true}
                            onChange={(event, value) => setCheckedLocation(value)}
                            sx={{color:'white'}}
                            ChipProps={{style:{color:'white'}}}              
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                 
                                />
                                {option?.name },{" "}{option?.country_code}, &nbsp; <b style={{border:'1px solid gray', padding:'0px 4px', borderRadius:'8px'}}>{option?.type}</b>
                              </li>
                            )}
                            style={{ width: 490 }}
                            renderInput={(params) => (
                              <TextField {...params} onChange={searchLocation}  InputLabelProps={{
                                sx: {
                                  color: 'black',
                                  fontSize:'14px',
                                  fontWeight:'600',
                                  '&.Mui-focused': {
                                    color: 'black',
                                  },
                                },
                              }} sx={{fontSize:'14px', color:'black', border:''}}  label="Targeting Location" placeholder="Search targeting location" />
                            )}
                          />
                          </div> */}
                          
                        </div>
                          {
                            !showForCowrking?(<>
<div className="d-flex flex-md-row flex-column justify-content-between align-items-start">
                         <div style={{width:'100%'}} className="pe-md-5 pe-0">
                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b  style={{ fontWeight:'500', color:'black'}}>Size*</b>
                        </label>
                        <div
                          className="input-container mb-3"
                          // style={!value?.Size ? { border: "1px solid red" } : {}}
                        >
                          {value?.Property === "Residential" ? (
                            <img src={sizeimg} width={18} height={14} alt="" />
                          ) : (
                            <img src={area} width={18} height={18} alt="" />
                          )}
                          <input
                            type="text"
                            name="Size"
                            id="Size"
                            className="textInput-qp"
                            placeholder="Enter Available Sizes"
                            value={value?.Size}
                            onChange={handleInputChange}
                            required
                          />
                          {value?.Size && (
                            <img src={donetext} width={14} height={14} alt="" />
                          )}
                        </div>
                         </div>
                          
                          <div style={{width:'100%'}} className=" pe-0">
                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b style={{ fontWeight:'500', color:'black'}}>Rent/ Lease *</b>
                        </label>
                        <div
                          className="input-container mb-3"
                          // style={!value?.Price ? { border: "1px solid red" } : {}}
                        >
                          <img src={rupes} width={12} height={14} alt="" />

                          <input
                            type="text"
                            name="Price"
                            id="Price"
                            placeholder="Enter Rent per Month"
                            className="textInput-q"
                            value={value?.Price}
                            onChange={handleInputChange}
                            required
                          />
                          {value?.Price && (
                            <img src={donetext} width={14} height={14} alt="" />
                          )}
                        </div>
                          </div>
                        </div>
                            </>):(<>
                                <div className="d-flex flex-md-row flex-column justify-content-between align-items-start">
                         <div style={{width:'100%'}} className="pe-md-5 pe-0">
                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b  style={{ fontWeight:'500', color:'black'}}>Number Of Seats*</b>
                        </label>
                        <div
                          className="input-container mb-3"
                          // style={!value?.Size ? { border: "1px solid red" } : {}}
                        >
                          {value?.Property === "Residential" ? (
                            <img src={sizeimg} width={18} height={14} alt="" />
                          ) : (
                            <img src={area} width={18} height={18} alt="" />
                          )}
                          <input
                            type="text"
                            name="Size"
                            id="Size"
                            className="textInput-qp"
                            placeholder="Enter Number of Seats to rent"
                            value={value?.Size}
                            onChange={handleInputChange}
                            required
                          />
                          {value?.Size && (
                            <img src={donetext} width={14} height={14} alt="" />
                          )}
                        </div>
                         </div>
                          
                          <div style={{width:'100%'}} className=" pe-0">
                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b style={{ fontWeight:'500', color:'black'}}>Rent per Seat*</b>
                        </label>
                        <div
                          className="input-container mb-3"
                          // style={!value?.Price ? { border: "1px solid red" } : {}}
                        >
                          <img src={rupes} width={12} height={14} alt="" />

                          <input
                            type="text"
                            name="Price"
                            id="Price"
                            placeholder="Enter Rent per Seat per Month"
                            className="textInput-q"
                            value={value?.Price}
                            onChange={handleInputChange}
                            required
                          />
                          {value?.Price && (
                            <img src={donetext} width={14} height={14} alt="" />
                          )}
                        </div>
                          </div>
                        </div>
                            </>)
                          }
                        



                        



                           
                           <div>

                        <label
                          htmlFor="weburl"
                          className="d-block text-start mb-2"
                        >
                          <b style={{fontWeight:'500', color:'black'}}>Key Highlights</b>
                        </label>
                        <div className="input-container p-0 m-0" style={{border:'none',}}>
                          <MuiChipsInput
                            value={Highlights}
                            onChange={handleChange}
                            sx={{
                              width: "100%",
                            
                              borderRadius:'5px',
                              textOverflow:'ellipsis',
                              overflowY: "auto",
                              height: "89px",
                              padding:'0px',
                              '& .MuiChip-label':{color:'white'},
                            
                            }}
                            
                            
                            placeholder="Add more"
                          />
                        </div>
                        <p className="mb-3 creative-location">
                        Enter up to 5-7 key highlights of the property. This will be used in the creatives
                        </p>
                           </div>
                          
                          {/* <div className="d-flex flex-md-row flex-column justify-content-between align-items-start">
                        <div style={{width:'100%'}} className="mb-3 text-start pe-md-5 pe-0">
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              <b style={{ fontWeight:'500', color:'black'}}>Status*</b>
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={value?.Status}
                              onChange={handleStatus}
                            >
                              <div
                                className={
                                  value?.Status === "Ready to move"
                                    ? "checkbox"
                                    : "checkbox1"
                                }
                              >
                                <FormControlLabel
                                  value="Ready to move"
                                  control={
                                    <Radio style={{ color: " #6622CC" }} />
                                  }
                                  label="Ready to move"
                                />
                              </div>
                              <div
                                className={
                                  value?.Status === "Under Construction"
                                    ? "checkbox"
                                    : "checkbox1"
                                }
                              >
                                <FormControlLabel
                                  value="Under Construction"
                                  control={
                                    <Radio style={{ color: " #6622CC" }} />
                                  }
                                  label="Under Construction"
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div style={{width:'100%'}} className="pe-md-5 pe-0">
                        {value?.Status === "Under Construction" && (
                          <>
                            <label
                              htmlFor="weburl"
                              className="d-block text-start mb-2"
                            >
                              <b style={{ fontWeight:'500', color:'black'}}>Possession Date</b>
                            </label>
                            <div
                              className="mb-3 input-container"
                              // style={
                              //   Dates?.length < 1 ? { border: "1px solid red" } : {}
                              // }
                            >
                              <img
                                src={calander}
                                width={20}
                                height={20}
                                alt=""
                              />
                              <DatePicker
                                selected={Dates?Dates:null}
                                
                                onChange={(date) => {
                                  setDates(date);
                                }}
                                // style={!Dates ? { border: "1px solid red" } : {}}
                                // style={{ width: "90%" }}
                                className="textInput-qp"
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                placeholderText="Select Possession Month & Year"
                              />
                              {Dates && (
                                <img
                                  src={donetext}
                                  width={14}
                                  height={14}
                                  alt=""
                                />
                              )}
                            </div>
                          </>
                        )}
                        </div>
                          </div> */}

                            {/* own creative & design my creative */}
                          <div className="d-flex flex-md-row flex-column justify-content-between align-items-start py-3">
                            
                          {/* <div className="d-flex flex-md-row flex-column justify-content-between align-items-start">
                        <div style={{width:'100%'}} className="mb-3 text-start pe-md-5 pe-0">
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              <b style={{ fontWeight:'500', color:'black'}}>Do you want to use your own creative?*</b>
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={value?.creativesFlag}
                              onChange={handleStatus}
                            >
                              <div
                                className={
                                  value?.OwnCreative ===true
                                    ? "checkbox"
                                    : "checkbox1"
                                }
                              >
                                <FormControlLabel
                                  value="Yes, I want to use my own creative"
                                  control={
                                    <Radio style={{ color: " #6622CC" }} />
                                  }
                                  label="Yes, I want to use my own creative"
                                />
                              </div>
                              <div
                                className={
                                  value?.OwnCreative === "No, please design a creative for me"
                                    ? "checkbox"
                                    : "checkbox1"
                                }
                              >
                                <FormControlLabel
                                  value="No, please design a creative for me"
                                  control={
                                    <Radio style={{ color: " #6622CC" }} />
                                  }
                                  label="No, please design a creative for me"
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </div>
                        
                          </div> */}
                          <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label " sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                              <b  className="" style={{ fontWeight:'500', color:'black', textAlign:'start'}}>Do you want to use your own creative?* {}</b>
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={value?.creativesFlag}
                              onChange={handleCreativeFlag}
                            >
                              <FormControlLabel value={true}  sx={{ label: { fontSize: { xs: '10px', md: '12px' }, },}} control={<Radio style={{ color: " #6622CC" }} />} label="Yes, I want to use my own creative" />
                              <FormControlLabel value={false}  sx={{ label: { fontSize: { xs: '10px', md: '12px' }, },}} control={<Radio style={{ color: " #6622CC" }} />} label="No, please design a creative for me" />
                              
                             
                            </RadioGroup>
                          </FormControl>
                          </div>
                        {/* design my creative end */}
                        <>
                        {((value?.creativesFlag===true) ||(value?.creativesFlag===false) || (value?.creativesFlag=='true') || (value?.creativesFlag=='false')) && (<>
                          <label
                            htmlFor="weburl"
                            className="d-block text-start mb-2"
                          >


                            <b style={{ fontWeight:'500', color:'black'}}>{value?.creativesFlag=='true'?"Upload your creative":'Upload project images/Ad*'}   </b>
                          </label>
                        
                          <div className="image-upload-container ">
                            {(images?.length?images:propImages).map((src, index) => (
                              <>
                              <ImageThumbnail
                                key={index}
                                src={src.preview?src.preview:`${src}`}
                                onDelete={() => handleDeleteImage(index)}
                              />
                             
                             
                              </>
                              
                            ))}
                            {/* <UploadButton onUpload={handleImageUpload} /> */}
                            <label className=" upload-box " style={{cursor:'pointer'}}>
                           <div className="plus-icon">+</div>
                            <input type="file" ref={fileInputRef} multiple   className="" onChange={handleImageUpload}  hidden />
                           </label>
                          </div>

                             


                          <p className="mb-3 creative-location">
                           {value?.creativesFlag=='true'?"*Add upto 2 designed creative":'*Add up to 5 images of the property'} 
                           
                          </p>
                        </>)}
                          
                          {/* <Button variant="contained" onClick={handleUpload}>Upload</Button>
                            {uploadProgress > 0 && <LinearProgress variant="determinate" value={uploadProgress} />} */}
                        </>

                      
                        <p className="mb-3 creative-location" style={{display:'none'}}>
                          *Amount you want to spend for this campaign
                        </p>

                        <div className="my-4 d-flex justify-content-between">
                          <div className='d-md-inline d-none' style={{width:'48%'}}>
                          <Button variant='outlined'  onClick={() =>navigate("/property-details")} sx={{border:'1px solid #6622CC', color:'#6622CC', padding:'10px 20px', width:'100%', }} startIcon={ <ArrowBackIcon />}>Back</Button>
                          </div>
                       
                         
                          <NavLink to="/property-details"  className="d-inline d-md-none"  style={{ width: "50%" }}>
                            <button
                              className="submit-button-outline me-2"
                              style={{ width: "100%" }}
                            >
                              <svg
                                minWidth="21"
                                height="11"
                                viewBox="0 0 21 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.207 0.292786C6.01947 0.105315 5.76516 0 5.5 0C5.23484 0 4.98053 0.105315 4.793 0.292786L0.292999 4.79279C0.105528 4.98031 0.000213623 5.23462 0.000213623 5.49979C0.000213623 5.76495 0.105528 6.01926 0.292999 6.20679L4.793 10.7068C4.9816 10.8889 5.2342 10.9897 5.4964 10.9875C5.7586 10.9852 6.00941 10.88 6.19482 10.6946C6.38023 10.5092 6.4854 10.2584 6.48767 9.99619C6.48995 9.73399 6.38916 9.48139 6.207 9.29279L3.5 6.49979H20C20.2652 6.49979 20.5196 6.39443 20.7071 6.20689C20.8946 6.01936 21 5.765 21 5.49979C21 5.23457 20.8946 4.98022 20.7071 4.79268C20.5196 4.60514 20.2652 4.49979 20 4.49979H3.5L6.207 1.70679C6.39447 1.51926 6.49979 1.26495 6.49979 0.999786C6.49979 0.734622 6.39447 0.480314 6.207 0.292786Z"
                                  fill="#6622CC"
                                />
                              </svg>
                              {"  "}
                              Back
                            </button>
                          </NavLink>

                          {(value.Name &&
                          value.Address &&
                          value.Budget &&
                          campaignBudgetData &&
                          value.Flat &&
                          value.Price &&
                          value.Size &&
                         
                          (images.length>0 || propImages.length>0)) ? (
                            <>
                              <button
                                onClick={()=>handleNextClick(state?.status)}
                                className="submit-button ms-2"
                                type="button"
                                style={{ width: "50%" }}
                              >
                                Next &nbsp;
                                <svg
                                  width="21"
                                  height="11"
                                  viewBox="0 0 21 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.793 0.292786C14.9805 0.105315 15.2348 0 15.5 0C15.7652 0 16.0195 0.105315 16.207 0.292786L20.707 4.79279C20.8945 4.98031 20.9998 5.23462 20.9998 5.49979C20.9998 5.76495 20.8945 6.01926 20.707 6.20679L16.207 10.7068C16.0184 10.8889 15.7658 10.9897 15.5036 10.9875C15.2414 10.9852 14.9906 10.88 14.8052 10.6946C14.6198 10.5092 14.5146 10.2584 14.5123 9.99619C14.51 9.73399 14.6108 9.48139 14.793 9.29279L17.5 6.49979H1C0.734784 6.49979 0.48043 6.39443 0.292893 6.20689C0.105357 6.01936 0 5.765 0 5.49979C0 5.23457 0.105357 4.98022 0.292893 4.79268C0.48043 4.60514 0.734784 4.49979 1 4.49979H17.5L14.793 1.70679C14.6055 1.51926 14.5002 1.26495 14.5002 0.999786C14.5002 0.734622 14.6055 0.480314 14.793 0.292786Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                disabled
                                className="submit-button-disable ms-2"
                                type="button"
                                style={{ width: "50%" }}
                              >
                                Next &nbsp;
                                <svg
                                  width="21"
                                  height="11"
                                  viewBox="0 0 21 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.793 0.292786C14.9805 0.105315 15.2348 0 15.5 0C15.7652 0 16.0195 0.105315 16.207 0.292786L20.707 4.79279C20.8945 4.98031 20.9998 5.23462 20.9998 5.49979C20.9998 5.76495 20.8945 6.01926 20.707 6.20679L16.207 10.7068C16.0184 10.8889 15.7658 10.9897 15.5036 10.9875C15.2414 10.9852 14.9906 10.88 14.8052 10.6946C14.6198 10.5092 14.5146 10.2584 14.5123 9.99619C14.51 9.73399 14.6108 9.48139 14.793 9.29279L17.5 6.49979H1C0.734784 6.49979 0.48043 6.39443 0.292893 6.20689C0.105357 6.01936 0 5.765 0 5.49979C0 5.23457 0.105357 4.98022 0.292893 4.79268C0.48043 4.60514 0.734784 4.49979 1 4.49979H17.5L14.793 1.70679C14.6055 1.51926 14.5002 1.26495 14.5002 0.999786C14.5002 0.734622 14.6055 0.480314 14.793 0.292786Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            </>
                          )}

                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </>
     )}
      </>
   
  );
}

export default RealEstateLeasingReview;
