import React, { useContext, useState } from "react";
import "./properttydetails.css";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from "@mui/material/Box";
import {  Step, StepLabel, Stepper} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar2 from "../../navbar/Navbar2";
import TextField from '@mui/material/TextField';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import globel from "../../asets/globel.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

import { AuthContext } from "../../AuthProvider";
import Axios from "../../Axios";
import WebStepper from "../../adesktop-webpage/web-component/webstepper/WebStepper";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";



function  PropertyDetails() {
  const [modal, setModal] = useState(false); 
  const navigate = useNavigate();
  const [onWant, setOnWant] = useState('sell')
  const { userToken } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    weburl: "",
    file: null,
    message: "",
    is_sell:true

  });



  const [recievedResponse, setRecievedResponse] = useState(false);
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,        
      [name]: value,
    });
  };


  
  function handleProperty(e) {
          e.preventDefault()
          setOnWant(e.target.value)
           const issell = e.target.value==="sell"?true:false
          //  console.log("iff", issell)
          setFormData({...formData, is_sell: issell})
  }


  function handlePropertySubmit(e) {
        
    setModal(true);
    const uriLink = formData?.weburl;
       if(uriLink.length>0){
        const body = {
          url: uriLink,
          is_sell:onWant?true:false
        };
        Axios.post("/lambda-scrapper", body, {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            setRecievedResponse(true);
            const data = response?.data?.message;
            const dataobj =  JSON.parse(data)
            const property_id = response?.data?.propertyId
               console.log("url", property_id)
            if (data.hasOwnProperty("error")) {
             
              navigate("/review-details", {state:{
                address:"",
                status:1,
                weburl:formData.weburl?formData.weburl:"",
                is_sell:formData?.is_sell,
                property_id:property_id || ""
              }});
            }else{
            
              navigate("/review-details", {state:{
                address:dataobj,
                status:1,
                weburl:formData.weburl?formData.weburl:"",
                is_sell:formData?.is_sell,
                 property_id:property_id || ""
              }});
            }
            setModal(false);
          })
          .catch((err) => {
            if (err) {
              
              setModal(false);
            //  alert(err.message);
              navigate("/review-details", {state:{
                address:"",
                status:1,
                weburl:formData.weburl?formData.weburl:"",
                is_sell:formData?.is_sell,
                property_id:""
              }});       
            }
          
           
            setRecievedResponse(true);
            navigate("/review-details", {state:{
              address:"",
              status:1,
              weburl:formData.weburl?formData.weburl:"",
              is_sell:formData?.is_sell
            }});       
          
            setModal(false);
          });
       }else{
        navigate("/review-details", {state:{
          address:"",
          status:1,
          weburl:formData.weburl?formData.weburl:"",
          is_sell:formData?.is_sell
        }});       
      
       }
    
  }

  function handlePropertySubmit2(e) {
       e.preventDefault();
   
    const uriLink = formData?.weburl;
    //  const urlString = `${Common.baseUrl()}/web-scrapper`;
       if(uriLink.length>0){
        setModal(true);
        const body = {
          url: uriLink,
        };
        Axios.post("/lambda-scrapper", body, {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            setRecievedResponse(true);
            const data = response?.data?.message;
            const dataobj =  JSON.parse(data)
            if (data.hasOwnProperty("error")) {
             
              navigate("/review-details-lease", {state:{
                address:"",
                status:1,
                weburl:formData.weburl?formData.weburl:"",
                is_sell:formData?.is_sell
              }});
            }else{
            
              navigate("/review-details-lease", {state:{
                address:dataobj,
                status:1,
                weburl:formData.weburl?formData.weburl:"",
                is_sell:formData?.is_sell
              }});
            }
            setModal(false);
          })
          .catch((err) => {
            if (err) {
              console.log(err.message);
              setModal(false);
            //  alert(err.message);
              navigate("/review-details-lease", {state:{
                address:"",
                status:1,
                weburl:formData.weburl?formData.weburl:"",
                is_sell:formData?.is_sell
              }});       
            }
          
            setRecievedResponse(true);
            navigate("/review-details-lease", {state:{
              address:"",
              status:1,
              weburl:formData.weburl?formData.weburl:"",
              is_sell:formData?.is_sell
            }});       
          
            setModal(false);
          });
       }else{
        navigate("/review-details-lease", {state:{
          address:"",
          status:1,
          weburl:formData.weburl?formData.weburl:"",
          is_sell:formData?.is_sell
        }}); 
       }
    
  }

  const steps = ["Provide details", "Review details", "Confirm & Pay"];
  const theme = createTheme({
    palette: {
      primary: {
        main: '#6622cc', // Active step background color
      },
      secondary: {
        main: '#000', // Active step label color
      },
    },
  });

  const stepweb = [
    {
      label: 'Provide details',
      description: `Please copy paste the website link of the project for which you need the leads`,
    },
    {
      label: 'Review details',
      description:""
    },
    {
      label: 'Confirm & Pay',
      description: ``,
    },
  ];
 

  return (
    <>
   {modal? ( <>  <LoadingScreen /> </> ):(<>
   

     <div className="d-md-none">
     <Navbar2 menu={true} />
     </div>

      <div className="container-fluid mb-5 mb-md-0 pb-3 pb-md-0">
        <div className="container">
          <div className="row">
              <div className="col-12">
              <div className="d-none d-md-block">
              <WebStepper steps={stepweb} active={0}/>
             </div>
              <Box sx={{ width: "100%", }} className="mt-4 d-md-none ">

                         <ThemeProvider theme={theme}>
                            <Stepper   activeStep={0}  alternativeLabel>
                              {steps.map((label) => (
                                <Step key={label} sx={{'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text':{fill:'white'},'& .MuiStepLabel-root .Mui-active':{color:'rgba(102, 34, 204, 1)'}, '& .MuiStepLabel-root .Mui-completed':{color:'rgba(102, 34, 204, 1)'} }}>
                                  <StepLabel className=""  sx={{
                                          '&.Mui-active': {
                                            color: 'secondary.main',
                                           
                                          },
                                          '&.Mui-completed': {
                                            color: 'secondary.main', // Active label color
                                          },
                                        
                                        }} style={{ fontSize: 10 }}>
                                    {label}
                                    
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                            </ThemeProvider>

                           
                      
                    </Box>
              </div>
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
              <div className="form-card2 mt-md-0 rounded">
                
                  <div
                    className="mb-3"
                    style={{  paddingBottom: 10 }}
                  >
                    
                  </div>

                  <div className="mb-3" style={{ textAlign: "left" }}>
                    <p className="heading-Property">Provide Property Details</p>
                    <p
                      style={{ textAlign: "justify", fontSize:'12px', color:'#B0B0B0', fontWeight:'400' }}
                      className="heading-Property1"
                    >
                     Please share landing page URL of the property you want the leads against. You can share property portals or developer project link here.

                    </p>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="weburl" className="d-block text-start mb-1">
                      <b style={{fontSize:'16px', fontWeight:'500'}}>Website URL (Optional)</b>
                    </label>
                    <div className="input-container">
                      <img src={globel} width={20} height={20} alt="" />
                      <input
                        type="text"
                        id="weburl"
                        name="weburl"
                        value={formData.weburl}
                        style={{
                          color: "#262626",
                          fontSize: 14,
                          marginLeft: 6,
                        }}
                        onChange={handleInputChange}
                        placeholder="Please enter your Landing Page URL (e.g. www.dlf.com/thearbour)"
                       
                        className="custom-input"
                      />
                    </div>
                  </div>

               
                  <div
                    style={{ textAlign: "left", marginTop: 20 }}
                    className="mb-3 "
                  >
                    <FormControl>
                      <FormLabel
                      id="demo-controlled-radio-buttons-group"
                        sx={{ color: "black" }}
                      >
                        <p className="p-0 m-0 " style={{color:'rgba(0, 0, 0, 0.87)', fontWeight:'500'}}>I want to </p>
                      </FormLabel>
                      <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={onWant}
                      row
                      onChange={handleProperty}
                    >
                      <FormControlLabel value="sell"   control={<Radio style={{ color: "#6622CC" }} />} sx={{ '&.Mui-checked': { color:'#6622cc', },color:'black' }} label="Sell" />
                      <FormControlLabel value="lease" sx={{ '&.Mui-checked': { color:'#6622cc', },color:'black' }} control={<Radio style={{ color: "#6622CC" }} />} label="Lease" />
                    </RadioGroup>
                      
                    </FormControl>
                  </div>
                



                  <div className="mt-2 mt-md-5">
                    {onWant==='sell'? <button
                      // type="submit"
                      onClick={handlePropertySubmit}
                      className="submit-button margintop"
                    >
                      Next
                    </button>: <button
                      // type="submit"
                      onClick={handlePropertySubmit2}
                      className="submit-button margintop"
                    >
                      Next
                    </button>}
                   
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal ?"" : <div className="d-block d-md-none"><BottomNavbar isPressed={true} whichOnePressed={2} /></div>}
      </>)}
    </>
  );
}

export default PropertyDetails;
