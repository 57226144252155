import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from '../asets/auth/—Pngtree—3d a business man stand_15446936.png'

import { Autoplay } from "swiper/modules";

function SlickSlider() {



    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        slidesToShow: 1,
    
        slidesToScroll: 1,
        arrows: false,
        pauseOnHover: true
    };

    return (

        <div className="slider-container slider-container-card" style={{ backgroundColor: 'transparent' }}>
            <Slider {...settings} >
                <div className="p-1">
                    <div className="flex justify-center items-start overflow-hidden bg-transparent ">
                        <div>
                            <img src={logo} className="slick-img" alt="" />
                        </div>
                        <div>
                            <p className="p-0 m-0 slickslider-card-p ">"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos fuga corporis adipiscitis vel? adipisicing elit. Eos fuga corporis adipiscitis"</p>
                            <div>
                                <p className="p-0 m-0 slickslider-name"><span className="slick-testimonial-name" >Captan Vipul</span> / <span className="slick-testimonial-company">CEO, Axiom Landbase</span></p>
                            </div>

                        </div>


                    </div>
                </div>
                <div className="p-1">
                    <div className="flex justify-center items-start overflow-hidden bg-transparent ">
                        <div>
                            <img src={logo} className="slick-img" alt="" />
                        </div>
                        <div>
                            <p className="p-0 m-0 slickslider-card-p ">"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos fuga corporis adipiscitis vel? adipisicing elit. Eos fuga corporis adipiscitis"</p>
                            <div>
                                <p className="p-0 m-0 slickslider-name"><span className="slick-testimonial-name" >Captan Vipul</span> / <span className="slick-testimonial-company">CEO, Axiom Landbase</span></p>
                            </div>

                        </div>


                    </div>
                </div>
                <div className="p-1">
                    <div className="flex justify-center items-start overflow-hidden bg-transparent ">
                        <div>
                            <img src={logo} className="slick-img" alt="" />
                        </div>
                        <div>
                            <p className="p-0 m-0 slickslider-card-p ">"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos fuga corporis adipiscitis vel? adipisicing elit. Eos fuga corporis adipiscitis"</p>
                            <div className="">
                                <p className="p-0  m-0 slickslider-name"><span className="slick-testimonial-name" >Captan Vipul</span> / <span className="slick-testimonial-company">CEO, Axiom Landbase</span></p>
                            </div>

                        </div>


                    </div>
                </div>
                <div className="p-1">
                    <div className="flex justify-center items-start overflow-hidden bg-transparent ">
                        <div>
                            <img src={logo} className="slick-img" alt="" />
                        </div>
                        <div>
                            <p className="p-0 m-0 slickslider-card-p ">"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos fuga corporis adipiscitis vel? adipisicing elit. Eos fuga corporis adipiscitis"</p>
                            <div>
                                <p className="p-0 m-0 slickslider-name"><span className="slick-testimonial-name" >Captan Vipul</span> / <span className="slick-testimonial-company">CEO, Axiom Landbase</span></p>
                            </div>

                        </div>


                    </div>
                </div>
            </Slider>
        </div>

    )
}

export default SlickSlider