import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Axios from '../Axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../SnackBarProvider';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';



function EnterNewPasswordPage({ emailData, token }) {
  const [showPasswordNew, setShowPasswordNew] = useState(false);

  const [buttonLoad, setButtonload] = useState(false);
 
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const passwordValidationSchema = Yup.object().shape({
   
    password: Yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
      .required('Password is required'),
  
  });

  const handleSetNewPassword = async (values) => {
    setButtonload(true);
    const body = { 
      password: values.password,
      
    };
    try {
      const res = await Axios.post("/reset-password", body, {headers:{Authorization:token}});
      if (res.status === 200 && res.data.valid) {
        showSnackbar(`Success: ${res?.data?.message}`, 'success');
        navigate("/login");
      }else{
        showSnackbar(`${res?.data?.message}`, 'error');
      }
    } catch (error) {
      console.log(error);
      showSnackbar(`Error: ${'Server Error'}`, 'error');
    } finally {
      setButtonload(false);
    }
  };

 

  return (
    <>
      <div className='mb-3'> 
        <h5 className='m-0 p-0'>Create New Password</h5>
      </div>
      <Formik
        initialValues={{ otp: '', password: '', confirmPassword: '' }}
        validationSchema={passwordValidationSchema}
        onSubmit={handleSetNewPassword}
      >
        {({ values, handleChange, handleBlur, touched, errors }) => (
          <Form className='  '>

            <div className='mb-2'>
              <FormControl sx={{  width: '100%' }} variant="standard">
                <InputLabel htmlFor="new-password-input" sx={{ color: '#62c', fontSize: '14px', '&.Mui-focused': { color: '#62c' } }}>Enter New Password</InputLabel>
                <Input
                  id="new-password-input"
                  name="password"
                  type={showPasswordNew ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fullWidth
                  sx={{ '&.Mui-focused:after': { borderBottomColor: '#62c' } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={touched.password && Boolean(errors.password)}
                />
                <FormHelperText>{touched.password && errors.password}</FormHelperText>
              </FormControl>
            </div>
            {/* <div className='mb-2'>
              <FormControl sx={{ width: '100%' }} variant="standard">
                <InputLabel htmlFor="confirm-password-input" sx={{ color: '#62c', fontSize: '14px', '&.Mui-focused': { color: '#62c' } }}>Confirm Password</InputLabel>
                <Input
                  id="confirm-password-input"
                  name="confirmPassword"
                  type={showPasswordMatch ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  fullWidth
                  sx={{ '&.Mui-focused:after': { borderBottomColor: '#62c' } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordMatch(!showPasswordMatch)}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPasswordMatch ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                />
                <FormHelperText>{touched.confirmPassword && errors.confirmPassword}</FormHelperText>
              </FormControl>
            </div> */}
          <div className='mt-5'>
          <button
              className="submit-button"
              type="submit"
              disabled={buttonLoad}
            >
              {buttonLoad ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
            </button>
          </div>
           
          </Form>
        )}
      </Formik>
    </>
  );
}


export default EnterNewPasswordPage;
