import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';


  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#6622cc', // Active step background color
      },
      secondary: {
        main: '#000', // Active step label color
      },
    },
  });

export default function WebStepper({steps, active}) {
  return (
    <Box sx={{ width: '100%', backgroundColor:'rgba(115, 115, 115, 0.03)', padding:'16px 0', margin:'0' }}>
       
      <Stepper orientation="horizontal"   activeStep={active}  alternativeLabel>
        {steps.map((step) => (
          <Step key={step.label}  className=''  sx={{'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text':{fill:'white'},'& .MuiStepLabel-root .Mui-active':{color:'rgba(102, 34, 204, 1)'}, '& .MuiStepLabel-root .Mui-completed':{color:'rgba(102, 34, 204, 1)'} }}>
            <StepLabel  >{}</StepLabel>
            <Typography sx={{textAlign:'center', fontSize:'16px', fontWeight:'700', color:step.description?'rgba(102, 34, 204, 1)':'rgba(123, 123, 123, 1)'}}>{step.label}</Typography>
            <Typography sx={{textAlign:'center', color:'rgba(103, 103, 103, 1)', fontSize:'12px'}}>{step.description}</Typography>
          </Step>
        ))}
      </Stepper>
    
    </Box>
  );
}
