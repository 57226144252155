import { useState, useEffect } from 'react';

import walletIcon from '../../asets/fbpagesicon/wallet.svg';

import { Download } from 'lucide-react';
// import rzpIcon from '../../asets/fbpagesicon/pngwing.com 1.svg';
// import transactionIcon from '../../asets/fbpagesicon/transaction (1) 1.svg'
import { IndianRupee } from 'lucide-react';


import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";


function formatNumber(input) {
  let number = parseFloat(input);

  // Check if the number is an integer
  if (number % 1 === 0) {
      return number.toString();
  } else {
      return number.toFixed(2);
  }
}



export function TransactionPageMobile({ transactionData, DownloadReceipt }) {
  const [transactionMobile, setTransactionMobile] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    if (transactionData?.length > 0) {
      setTransactionMobile(transactionData);
    }else{
      setTransactionMobile([]);
    }
  }, [transactionData]);


  const toggleDescription = (id) => {
    setExpandedDescriptions((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
    }));
};

// console.log("tran", transactionMobile)

const truncateDescription = (description, id) => {
    if (!description) {
        return ''; // Return an empty string if description is undefined or null
    }
    if (description.length <= 20 || expandedDescriptions[id]) {
        return description;
    }
    return `${description.slice(0, 20)}...`;
};


const DownloadReceiptFromMobile = (transaction)=>{
    DownloadReceipt(transaction)
}

  return (

    <>
   {/* <p>{transactionMobile>0?'hello':'none'}</p> */}
    <Accordion  collapsible className="w-full shadow-none">
          {transactionMobile?.map((transaction, idx) => (
            <>
             
            <AccordionItem key={transaction?.id} value={`item-${transaction?.id}`} className=" w-full">
              <AccordionTrigger className="py-1 no-underline hover:no-underline">
                <div className="p-0 m-0 flex flex-col items-start justify-center w-[95%] pe-1">
                  <div className="p-0 m-0 flex justify-between items-center w-full">
                    <p className="p-0 m-0 text-[14px] font-[500] capitalize" style={{ color: 'rgba(21, 28, 42, 0.8)' }}>
                      {transaction?.type}
                    </p>
                    <p className="p-0 m-0 text-[14px] font-[500]">INR {formatNumber(transaction?.totalAmount)}</p>
                  </div>
                  <div className="flex p-0 m-0 justify-between items-center w-full">
                    <p className="p-0 m-0 text-[10px] font-normal ps-1" style={{ color: 'rgba(121, 136, 163, 1)' }}>
                      {transaction?.date}
                    </p>
                    <p className="p-0 m-0 text-[10px] font-normal" style={{ color: 'rgba(121, 136, 163, 1)' }}>
                     {(Number(transaction?.from_wallet) || Number(transaction?.total_rzp_amount))?'From':null}  {  Number(transaction?.total_rzp_amount)?' Razpoy':null} {(Number(transaction?.from_wallet) && Number(transaction?.total_rzp_amount))?'&':null} {Number(transaction?.from_wallet)?'Wallet':null}
                    </p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex mb-2 justify-between items-start">
                     <div className='flex flex-col gap-2 flex-1'>
                     <div>
                    <p className="p-0 m-0 text-[10px] font-normal text-[#7988A3]">Order Id</p>
                    <p className="p-0 m-0 text-[12px] font-normal" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                    {transaction?.orderId?.length > 5 ? `${transaction?.orderId?.slice(0, 5)}...` : transaction?.orderId}
                    </p>
                  </div>
                  <div>
                    <p className="p-0 m-0 text-[10px] font-normal text-[#7988A3]">Description</p>
                    <p className=" p-0 m-0 text-[12px] font-normal capitalize" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                    {truncateDescription(transaction?.description, transaction?.id)}
                                                            {transaction?.description?.length > 20 && (
                                                                <span
                                                                    className="text-[#62c] text-[9px] cursor-pointer"
                                                                    onClick={() => toggleDescription(transaction?.id)}
                                                                >
                                                                    {expandedDescriptions[transaction?.id] ? ' Read Less' : ' Read More'}
                                                                </span>
                                                            )}
                    </p>
                  </div>
                     </div>
                
                     <div className='w-[80px]'>
                    <p className="p-0 m-0 text-[10px]  font-normal text-start text-[#7988A3]">Method</p>
                     <div className=' flex justify-start items-center'>
                     <IndianRupee className='w-[10.5px] h-[10.5px] mt-[2px]' style={{ color: 'rgba(0, 0, 0, 0.8)' }} />
                        {/* <img src={transactionIcon} width='14px' height={'14px'} alt="" /> */}
                     <p className="p-0 m-0 text-[12px] text-end font-normal" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                      {formatNumber(transaction?.total_rzp_amount)}
                    </p>
                     </div>
                      <div className='flex justify-start gap-2 items-center'>
                         <div className='flex justify-start items-center'>
                         <img src={walletIcon} width='14px' height={'14px'} alt="" />
                      <p className="p-0 m-0 text-[12px] text-end font-normal" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                      {formatNumber(transaction?.from_wallet)}
                    </p>
                         </div>

                         {/* <div className="download-icon">
                            <button onClick={()=>DownloadReceiptFromMobile(transaction)} className=''><Download className='w-[16px] h-[16px] text-[#62c]'/></button>
                         </div> */}
                        
                      </div>
                    
                  </div>
                </div>
               
               

               
              </AccordionContent>
            </AccordionItem>
            </>
          ))}
          
        </Accordion>
    
   
    </>
  );
}
