import React, { useContext, useEffect, useState } from 'react'
import '../web-leadmgn-page/webleadmgn.css'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom'
import Axios from '../../Axios'
import './webcampmgn.css'
import { AuthContext } from '../../AuthProvider'
import { Box, Button, Menu, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

function WebCampMgn() {
  const { userToken, allFbData, campaignInsitsLoad, campaignInsitsData } = useContext(AuthContext);
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActive, setFilterActive] = useState([])

  const [underRiview, setUnderRiview] = useState([])
  const [activeUnderReviewData, setActiveUnderReviewData] = useState([])
  const [allCampaign, setAllCampaign] = useState([])

  const open = Boolean(anchorEl);

  const formatName = (name) => {

    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');

    trimmedName = trimmedName.replace(/_/g, ' ').trim();

    return trimmedName.replace(/\s+/g, ' ');
  };

  const CampMgnFilterData = (data) => {

    let campigncard = [];
    if (data) {

      data?.forEach((val) => {
        const obj1 = {
          campaign_id: val?.id,
          name: val?.name ? val?.name : '',
          lifetime_budget: val?.lifetime_budget ? (Number(val?.lifetime_budget)) : 0,
          status: val?.status,
          impressions: 0,
          spend: 0,
          leads_count: 0,
          _id: val?.dbId,
          discount: val?.discount,
          date: val?.createdAt,
          cpm: 0,
          cpc: 0,
          ctr: 0
        };


        val?.insights?.data?.forEach((val) => {

          obj1.leads_count =
            obj1?.leads_count +
            (parseInt(val?.actions?.length ? val?.actions?.find((action) => action?.action_type === 'lead')?.value || 0 : 0));

          obj1.impressions = obj1.impressions + (val?.impressions ? Number(val?.impressions) : 0);
          //spent
          obj1.spend += parseFloat(val?.spend || 0);
          //spent
          // console.log("spend",(Number(val?.spend) || 0))


          //cpm
          obj1.cpm = obj1.cpm + (val?.cpm ? Number(val?.cpm) : 0)
          //cpc
          obj1.cpc = obj1.cpc + (val?.cpc ? Number(val?.cpc) : 0)
          //ctr
          obj1.ctr = obj1.ctr + (val?.ctr ? Number(val?.ctr) : 0)

        });

        val?.ads?.data?.forEach((val) => {
          //   console.log("lead", val)

          obj1.leads_count = obj1.leads_count + (val?.leads?.data?.length ? val?.leads?.data?.length : 0);
        });


        if (obj1?.name) {

          campigncard?.push(obj1);
        }
      })
    }

    if (campigncard?.length) {

      const filterdate = [...campigncard].sort((a, b) => new Date(b?.date) - new Date(a?.date))
      if (filterdate) {
        setAllCampaign(filterdate)

        const flterCompleted2 = filterdate.filter((val) => {
          return val.status === "ACTIVE" || val.status === "PAUSED" || val.status === "STOPPED" || val.status === "COMPLETED" || val.status === "UNDER REVIEW"
        })
        if (flterCompleted2) {
          setFilterActive(flterCompleted2)
        }
      }


    }

  }


  // useEffect(()=>{
  //   CampMgnFilterData(allFbData)
  // },[ allFbData?.length])



  useEffect(() => {
    console.log("campaigndata", campaignInsitsData)
    const filterdate = [...campaignInsitsData].sort((a, b) => new Date(b?.date) - new Date(a?.date))
    if (filterdate) {
      setAllCampaign(filterdate)

      const flterCompleted2 = filterdate.filter((val) => {
        return val.status === "ACTIVE" || val.status === "PAUSED" || val.status === "STOPPED" || val.status === "COMPLETED" || val.status === "UNDER REVIEW"
      })
      if (flterCompleted2) {
        setFilterActive(flterCompleted2)
      }
    }

  }, [campaignInsitsData])

  //for draft
  // const draftUserDataApi = async () => {
  //   Axios.get("/get-properties", {
  //     headers: {
  //       Authorization: userToken,
  //     },
  //   })
  //     .then((r) => {
  //       if (!r.data.valid) {
  //        // console.log("api valid field is false");
  //       } else {
  //         const data = r.data.message;
  //        // console.log(data);
  //         setDraftUserData(data);
  //       }
  //     })
  //     .catch((err) => {
  //      // console.log(err.message);
  //     });
  // };

  // useEffect(() => {
  //   draftUserDataApi();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);



  // const handleUnderRiview = async () =>{
  //       try {
  //          const res = await Axios.get("/get-under-review", {
  //            headers:{
  //              Authorization:userToken
  //            }
  //          })
  //           if(res.status===200){
  //                   let underreview = []
  //               const data = res?.data?.message

  //                  data.forEach((val)=>{

  //                   const obj1 = {
  //                     campaign_id: val?.campaign_id,
  //                     name: val?.name,
  //                     lifetime_budget:Number(val?.lifetime_budget),
  //                     status: val?.status,
  //                     impressions: 0,
  //                     spend: 0,
  //                     leads_count: 0,
  //                     _id:val?._id,
  //                     discount:Number(val?.discount)?Number(val?.discount):30

  //                   };
  //                   if(obj1){
  //                      underreview.unshift(obj1)
  //                   }
  //                  })
  //                  if(underreview.length>0){
  //                       setUnderRiview(underreview)
  //                  }



  //             //  setUnderRiview(data)
  //            //   console.log("underreview", data)
  //           }
  //       } catch (error) {

  //       }
  // }

  // useEffect(()=>{
  //   handleUnderRiview()
  // },[])




  useEffect(() => {
    const activeUnderReviewcamp = filterActive.concat(underRiview)
    if (activeUnderReviewcamp) {
      setActiveUnderReviewData(activeUnderReviewcamp)
    }

  }, [filterActive?.length, underRiview?.length])

  const SearchfilterData = () => {
    return filterActive.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };






  // sorting by leads , date, spent
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onFilterByLead = () => {
    //active
    const sortedData = [...allCampaign].sort((a, b) => b.leads_count - a.leads_count);
    if (sortedData) {
      //  console.log("filterlead", sortedData)
      setAllCampaign(sortedData)
    }



    setAnchorEl(null);
  }
  const onFilterBySpent = () => {
    const sortedData = [...allCampaign].sort((a, b) => b.spend - a.spend);
    if (sortedData) {
      //   console.log("filterlead", sortedData)
      setAllCampaign(sortedData)
    }


    setAnchorEl(null);
  }
  const onFilterByDate = () => {
    const filterdate = [...allCampaign].sort((a, b) => new Date(b?.date) - new Date(a?.date))
    if (filterdate) {
      setAllCampaign(filterdate)
    }


    setAnchorEl(null);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }


  //for csv
  const handleDownloadCSV = async (id) => {


    const leadpagedata = allFbData.filter((val) => val?.id === id)
    // console.log("leadpage", leadpagedata)
    if (leadpagedata) {
      //  setCampName(leadpagedata[0]?.name)

      const leadvalue = leadpagedata[0]?.ads?.data
      if (leadvalue) {
        const allLeads = leadvalue.reduce((accumulatedLeads, ad) => {
          const leadsData = ad.leads?.data || [];
          return accumulatedLeads.concat(leadsData);
        }, []);
        // console.log("allead", allLeads)
        //  const allLeadsValue = allLeads.map((val)=>val?.field_data)?.flat(2)
        //  if(allLeadsValue?.length){
        //     console.log("allLeadsValue", allLeadsValue)
        //  }
        let leadsResult = [];

        allLeads.forEach((val) => {
          let innerlead = {}

          val.field_data.forEach((data) => {
            innerlead[data?.name] = data?.values[0];
            innerlead['created_time'] = val?.created_time;
            innerlead['id'] = val?.id;
            // const obj = {
            //     [data.name]: data?.values[0]
            // };
            // innerlead.push(obj);
          });
          if (Object.keys(innerlead)?.length) {
            leadsResult.push(innerlead)
          }
        });
        // if(leadsResult?.length){

        //         // Get a set of all unique keys in the array
        //         const allKeys = Array.from(new Set(leadsResult?.flatMap(obj => Object.keys(obj))));

        //         // Update each object to include all keys with values or null
        //         const updatedArray = leadsResult?.map(obj => {
        //           const updatedObj = { ...obj };
        //           allKeys.forEach(key => {
        //             if (!obj.hasOwnProperty(key)) {
        //               updatedObj[key] = null;
        //             }
        //           });
        //           return updatedObj;
        //         });

        //         console.log("updated arr",updatedArray);



        //   // console.log("dsf", leadsResult)
        // }
        const transformedLeads = allLeads.map(lead => ({

          time: lead.created_time,
          full_name: getFieldDataValue(lead, "full_name"),
          question1: getFieldDataValue(lead, "question1"),
          phone_number: getFieldDataValue(lead, "phone_number"),
          question3: getFieldDataValue(lead, "question3"),
          email: getFieldDataValue(lead, "email"),
          question2: getFieldDataValue(lead, 'question2'),
          what_are_you_looking_for: getFieldDataValue(lead, "what_are_you_looking_for?"),

          bwhat_is_your_budget: getFieldDataValue(lead, "what_is_your_budget?"),
          what_is_your_budget_for_renting_the_studio_apartment: getFieldDataValue(lead, "what_is_your_budget_for_renting_the_studio_apartment?"),
          // Add more properties as needed
        }));

        if (leadsResult?.length) {

          // Get a set of all unique keys in the array
          const allKeys = Array.from(new Set(leadsResult?.flatMap(obj => Object.keys(obj))));

          // Update each object to include all keys with values or null
          const updatedArray = leadsResult?.map(obj => {
            const updatedObj = { ...obj };
            allKeys.forEach(key => {
              if (!obj.hasOwnProperty(key)) {
                updatedObj[key] = null;
              }
            });
            return updatedObj;
          });
          if (updatedArray?.length) {
            // console.log("transformedLeads", updatedArray)
            downloadCsvByPap(updatedArray, id)
            // downloadCsv(transformedLeads)
            //setFilterLeads(transformedLeads)
          }




        }
      }
    }

    function getFieldDataValue(lead, fieldName) {
      //  console.log("leadddd", lead)
      const field = lead?.field_data?.find(data => data.name === fieldName);
      return field ? field.values[0] : null;
    }

  };


  const downloadCsvByPap = (data, id) => {

    const csv = Papa.unparse(data);
    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `#${id}.csv`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = (id) => {
    handleDownloadCSV(id);
  };






  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-11">
            {/* searchBox, fiter-sort btns */}
            <Box
              component={"div"}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <div className="searchBox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M8.48307 9.85618C8.43311 9.80622 8.34711 9.79937 8.28574 9.84431C8.28574 9.84431 8.29167 9.84089 8.24011 9.87716C7.3892 10.4755 6.35211 10.8269 5.2329 10.8269C2.34296 10.8269 0 8.48423 0 5.59423C0 2.70422 2.3429 0.361328 5.2329 0.361328C8.1229 0.361328 10.4656 2.70422 10.4656 5.59423C10.4656 6.70794 10.1177 7.74072 9.52431 8.58912C9.48552 8.64478 9.48187 8.64866 9.48187 8.64866C9.43898 8.70683 9.44651 8.79603 9.49488 8.8444L9.80787 9.15739C9.85783 9.20736 9.95798 9.23359 10.0232 9.21899C10.0232 9.21899 10.0166 9.21831 10.0764 9.20895C10.5242 9.13983 10.9978 9.27785 11.3428 9.62278L12.978 11.258C13.5529 11.8327 13.5529 12.7646 12.978 13.3393C12.4033 13.9142 11.4714 13.9142 10.8968 13.3393L9.26151 11.704C8.91817 11.3609 8.77992 10.89 8.84676 10.4443C8.85612 10.3823 8.8568 10.3893 8.8568 10.3893C8.87323 10.3177 8.84448 10.2176 8.79612 10.1692L8.48307 9.85618ZM5.23291 8.86469C7.03904 8.86469 8.5034 7.40033 8.5034 5.5942C8.5034 3.78784 7.03904 2.32372 5.23291 2.32372C3.42655 2.32372 1.96242 3.78784 1.96242 5.5942C1.96242 7.40033 3.42655 8.86469 5.23291 8.86469Z"
                    fill="#949494"
                  />
                </svg>
                <input type="text" placeholder="Search Campaign" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

              </div>

              {/* sorting by leads, date, spent */}
              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ margin: '0px', padding: '0px' }}
                  className="p-0 m-0"
                >
                  <SortIcon sx={{ color: '#6622cc', margin: '0', padding: '0' }} />
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={onFilterByDate}>By date</MenuItem>
                  <MenuItem onClick={onFilterByLead}>By leads</MenuItem>
                  <MenuItem onClick={onFilterBySpent}>By spend</MenuItem>
                </Menu>
              </div>


            </Box>


            <div className="leadtabl" style={{ maxWidth: '100%' }}>
              <table className='table-web' style={{ width: '100%', }} >
                <thead className=' table-fixed ' >
                  <tr className="table-bg p-2" style={{}}>
                    <th> S. No.  </th>
                    <th className='tablewidth'> Campaign Name </th>
                    <th> Status  </th>
                    <th> Leads  </th>

                    {/* <th> Message </th> */}
                    <th> Budgets  </th>
                    <th> Spent  </th>
                    <th>CPM</th>
                    <th>CTR <br/><small className='p-0 m-0' style={{fontSize:'10px'}}>(Link Clicks)</small>
                   
                    </th>
                    <th>CPL</th>
                    <th>CPC <br/><small className='p-0 m-0' style={{fontSize:'10px'}}>(Link Clicks)</small></th>
                    {/* <th> Status  </th> */}
                    {/* <th style={{minWidth:'110px'}} className=''>&nbsp; Action  </th> */}
                  </tr>
                </thead>
                <tbody className='' style={{}}>


                  {(searchTerm ? SearchfilterData() : allCampaign)?.map((val, idx) => (

                    <>

                      <tr style={{ cursor: 'pointer' }} onClick={() => { navigate("/campaign-overview", { state: { campaign_id: val?._id, cmpid: val?.campaign_id }, }) }} key={idx} className={idx % 2 !== 0 ? 'table-bg table-hover-web' : 'table-hover-web'} >
                        <td className='serailnum-width ps-2 table-color table-td-padding'   >{idx + 1}</td>
                        <td className='table-color table-td-padding tablewidth' style={{ cursor: 'pointer' }}   >
                          {val?.name?.length > 26 ? val?.name.slice(0, 24) + '..' : val?.name}
                          {/* <div className='d-flex justify-content-start gap-1 align-items-center'>
                                  <p className='p-0 m-0' style={{width:'6px', height:'6px', borderRadius:'50%', backgroundColor:val?.status==='ACTIVE'?'#0EAA00':val?.status==='PAUSED'?'#F07A4C':'#6622CC'}}></p>
                                  <p className='p-0 m-0'>{val?.name?.length>26?val?.name.slice(0, 24)+'..':val?.name}</p>
                                 </div>  */}
                          {/* <span className='position-absolute' style={{color:val?.status==='ACTIVE'?'#0EAA00':val?.status==='PAUSED'?'#F07A4C':'#6622CC', fontSize:'9px', fontWeight:'600', left:'8px', bottom:'0px'}}>{val.status}</span> */}
                        </td>
                        <td className='table-td-padding' style={{ color: val?.status === 'ACTIVE' ? '#0EAA00' : val?.status === 'PAUSED' ? '#F07A4C' : '#6622CC', fontSize: '10px', fontWeight: '700' }}>{val.status}</td>
                        <td style={{ cursor: 'pointer', fontSize: '13px' }} className='table-color-bold position-relative table-td-padding'><span>{val?.leads_count}</span>
                          {/* <td onClick={()=>{val?.leads_count?navigate(`/leads`, {state:val?.campaign_id }):navigate("/campaign-management")}} style={{cursor:'pointer', fontSize:'13px'}} className='table-color-bold position-relative'><span>{val?.leads_count}</span> */}
                          {/* <span className='position-absolute' style={{color:'#0EAA00', fontSize:'12px', bottom:'22px', fontWeight:'700'}}>+0</span> */}
                        </td>
                        {/* <td className='table-color-bold position-relative'><span>0</span>
                                <span className='position-absolute' style={{color:'#0EAA00', fontSize:'12px', bottom:'22px', fontWeight:'700'}}>+0</span>
                                </td> */}
                        <td className="table-color table-td-padding">< CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px' }, marginBottom: '2px' }} />{formatIndianNumber(Math.round(Number(val?.lifetime_budget)))}</td>
                        <td className="table-color table-td-padding">< CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px' }, marginBottom: '2px' }} />{val?.spend ? formatIndianNumber(Math.round(Number(val?.spend))) : 0}</td>


                        <td className="table-color table-td-padding">< CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px' }, marginBottom: '2px' }} />{val?.cpm ? formatIndianNumber(Math.round(Number(val?.cpm))) : 0}</td>
                        <td className="table-color table-td-padding">{val?.ctr ? formatIndianNumber(Number(val?.ctr).toFixed(2)) + '%' : 0 + '%'}</td>
                        <td className="table-color table-td-padding">< CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px' }, marginBottom: '2px' }} />{val?.leads_count ? formatIndianNumber(Math.round(Number(val?.spend) / Number(val?.leads_count))) : 0}</td>
                        <td className="table-color table-td-padding">< CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px' }, marginBottom: '2px' }} />{val?.cpc ? formatIndianNumber(Math.round(Number(val?.cpc))) : 0}</td>








                        {/* <td className='table-td-padding' style={{color:val?.status==='ACTIVE'?'#0EAA00':val?.status==='PAUSED'?'#F07A4C':'#6622CC', fontSize:'13px', fontWeight:'700'}}>{val.status}</td> */}
                        {/* <td style={{minWidth:'110px'}} className='d-flex justify-content-start align-items-center'>
                                  
                                {val?.leads_count?(<>
                                  <Tooltip title="View Leads" placement='top' arrow>
                                  <IconButton onClick={()=>{navigate('/leads', {state:val?.campaign_id })}} sx={{color:'#6622CC'}} aria-label="visibility">
                                <VisibilityIcon/>
                                 </IconButton>
                                  </Tooltip>
                               
                                &nbsp;
                                <Tooltip title="Download Leads" placement='top' sx={{padding:'0'}}  arrow>
                                <IconButton  onClick={() => handleDownload(val?.campaign_id)} sx={{color:'#6622CC'}} aria-label="visibility">
                                 <DownloadIcon/>
                                 </IconButton>
                                  </Tooltip>
                                 
                                 
                                  </>):(<>
                                  <>
                                  
                                  <IconButton sx={{color:'#6622CC'}} disabled aria-label="visibility">
                                <VisibilityIcon/>
                                  </IconButton>
                                  
                                  
                                  &nbsp; 
                                 <IconButton disabled onClick={() => handleDownload(val?.campaign_id)} sx={{color:'#6622CC'}} aria-label="visibility">
                                 <DownloadIcon/>
                                 </IconButton>
                                 
                                    </>
                                </>)}                                 
                                  
                            </td> */}
                      </tr>
                    </>
                  ))}
                </tbody>

              </table>
            </div>
          </div>
          <div className="col-12 col-xl-1">
            {/* <div className="row">
                  {draftUserData?.map((val, idx)=>(
                    <div key={idx+1} className="col-12 mb-3">
                <DraftCard data={val}  handleDeleteDraft={ handleDeleteDraft}/>
                </div>
                  ))}
                
               </div>
              */}
          </div>
        </div>
      </div>

    </>
  )
}

export default WebCampMgn