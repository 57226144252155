import React, { useState, useEffect, useContext } from 'react';
import { Table, TableBody,TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import Axios from '../../Axios';
import { Card } from '../../components/ui/card';
import "./paymentHistoryPage.css";
import { AuthContext } from '../../AuthProvider';
import { Input } from '../../components/ui/input';
import { TransactionPageMobile } from './TransactionPageMobile';
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
import Navbar2 from '../../navbar/Navbar2';
import walletIcon from '../../asets/fbpagesicon/wallet.svg';
import '../../homepage/home.css'
import '../../adesktop-webpage/web-homepage/webhome.css'
import { IndianRupee } from 'lucide-react';
import jsPDF from 'jspdf'; // Import jsPDF
import WalletCardForTransactionPage from './WalletCardForTransactionPage';

function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[inputDate.getUTCMonth()];
    const day = inputDate.getUTCDate();
    const year = inputDate.getUTCFullYear();
    return `${month} ${day}, ${year}`;
}


function formatNumber(input) {
    let number = parseFloat(input);

    // Check if the number is an integer
    if (number % 1 === 0) {
        return number.toString();
    } else {
        return number.toFixed(2);
    }
}


function TransactionPage() {
    const { userToken } = useContext(AuthContext);
    const [transactionData, setTransactionData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const getPaymentHistory = async () => {
        try {
            const res = await Axios.get("/get-transactions", {
                headers: {
                    Authorization: userToken
                }
            });
            if (res.status === 200) {
                const data = res?.data?.message;
                const historyArr = [];
                if (data) {
                    data.forEach((val) => {
                        const historydata = {
                            id: val?._id,
                            orderId: val?._id,
                            date: val?.date ? formatDate(val?.date) : 'NA',
                            walletBalance: val?.closing ? val?.closing < 0 ? 0 : (Number(val?.closing)).toFixed(2) : 0,
                            totalAmount: val?.total_amount ? val?.total_amount : val.paid_amount,
                            transactionAmount: val?.paid_amount ? Math.floor(Number(val?.paid_amount)) : 0,
                            description: val?.description,
                            dbcampid: val?.db_campaign,
                            status: val.status === ('fullfilled' || 'fulfilled') ? 'success' : val.status,
                            total_rzp_amount: val?.total_rzp_amount || 0,
                            from_wallet: val?.amount || 0,
                            item_name: val?.item_name || '',
                            type:val?.type || ''
                        };
                        historyArr.push(historydata);
                    });
                }
                if (historyArr?.length) {
                    setTransactionData(historyArr);
                    setFilteredData(historyArr?.reverse());
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPaymentHistory();
    }, []);

    useEffect(() => {
        const searchFilteredData = transactionData?.filter(transaction =>
            transaction?.item_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
         if(searchFilteredData?.length>0){
            setFilteredData(searchFilteredData);
         }
        
    }, [searchTerm, transactionData]);

    const toggleDescription = (id) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const truncateDescription = (description, id) => {
        if (!description) {
            return ''; // Return an empty string if description is undefined or null
        }
        if (description.length <= 20 || expandedDescriptions[id]) {
            return description;
        }
        return `${description.slice(0, 20)}...`;
    };

    const DownloadReceipt = (transaction) => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text('Transaction Receipt', 20, 20);

        // Add a horizontal line
        doc.setLineWidth(0.5);
        doc.line(20, 25, 190, 25);

        // Add transaction details section
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');

        doc.text('Date:', 20, 35);
        doc.text(transaction.date, 70, 35);

        doc.text('Order ID:', 20, 45);
        doc.text(transaction.orderId, 70, 45);

        doc.text('Item:', 20, 55);
        doc.text(transaction.item_name, 70, 55);

        doc.text('Amount:', 20, 65);
        doc.text(`INR ${transaction.totalAmount}`, 70, 65); // Changed to Rupee symbol

        doc.text('Description:', 20, 75);

        // Handle long description text
        const descriptionLines = doc.splitTextToSize(transaction.description || 'N/A', 120);
        doc.text(descriptionLines, 70, 75);

        // Add payment method section
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text('Payment Method:', 20, 90);

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');

        doc.text(`Razorpay: INR ${transaction.total_rzp_amount}`, 20, 100); // Changed to Rupee symbol
        doc.text(`Wallet: INR ${transaction.from_wallet}`, 20, 110); // Changed to Rupee symbol

        // Add footer
        doc.setFontSize(10);
        doc.setFont('helvetica', 'italic');
        doc.text('Thank you for your transaction!', 20, 140);

        // Save the PDF
        doc.save(`Receipt_${transaction.orderId}.pdf`);
    };


    return (
        <>
            <div className="d-md-none"><Navbar2 menu={true} /></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mt-3  mb-1 mb-md-0 mt-md-3 mt-lg-3 mt-xl-3 d-flex justify-start px-2 items-start col-md-12 col-xl-4 p-0 m-0 wallet-card-containe">
                        {/* <Card className="shadow-sm w-[97%]  mx-auto px-2 pb-3 "> */}
                        <WalletCardForTransactionPage />
                        {/* </Card> */}

                    </div>
                    <div className="col-12">
                        <div className='my-3'>
                            <p className="m-0 p-0 text-start text-[18px] font-bold leading-[31.25px] md:text-[24px]">
                                Transactions History
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="transaction-container w-full">
                                    <Card className="p-2 shadow-sm ">
                                        {/* <div className='py-2 py-md-3'>
                                            <Input
                                                type="text"
                                                className="max-w-[240px] md:w-[336px]"
                                                placeholder="Search by campaign name..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </div> */}

                                        <Table className="shadow-none mt-3 sm:overflow-x-scroll md:overflow-x-auto d-none d-md-block w-full">
                                            {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
                                            <TableHeader className=" w-full">
                                                <TableRow>
                                                    <TableHead className="min-w-[140px] text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}>Date</TableHead>
                                                    <TableHead className="min-w-[100px] text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}>Id</TableHead>
                                                    <TableHead className="min-w-[160px] text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}>Item</TableHead>
                                                    <TableHead className="min-w-[120px]  text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}>Amount</TableHead>
                                                    <TableHead className="min-w-[150px] text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}>Method</TableHead>
                                                    <TableHead className="text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}>Description</TableHead>
                                                    {/* <TableHead className="text-[14px] font-normal text-start" style={{ color: 'rgba(3, 2, 41, 1)' }}></TableHead> Added Actions header */}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {filteredData?.map((transaction) => (
                                                    <TableRow key={transaction.id}>
                                                        <TableCell className="text-[14px] font-normal text-start">
                                                            <div className='flex items-center gap-1 justify-start'>
                                                                {/* <Calendar className='text-[#62c] h-[16px] w-[16px]' /> */}
                                                                <p className='p-0 m-0'>{transaction?.date}</p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="text-[14px] font-normal text-start">
                                                            {transaction?.orderId?.length > 10 ? `${transaction?.orderId?.slice(0, 10)}...` : transaction?.orderId}
                                                        </TableCell>
                                                        <TableCell className="text-[14px] capitalize font-normal text-start">
                                                            {transaction?.type}
                                                        </TableCell>
                                                        <TableCell className="text-[14px] font-normal text-start">
                                                           INR {formatNumber(transaction?.totalAmount)}
                                                        </TableCell>
                                                        <TableCell className="min-w-[150px] text-[14px] font-normal text-start">
                                                            <div>
                                                                <div className='flex justify-start items-center'>
                                                                    <div>
                                                                        <IndianRupee className='w-[13px] h-[13px] mt-[2px]' style={{ color: 'rgba(0, 0, 0, 0.8)' }} />
                                                                        {/* <img src={rupeeicon} width="17px" height="17px" alt="" /> */}
                                                                    </div>
                                                                    <p className='p-0 m-0 text-[14px] font-normal' style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                                                                        {formatNumber(transaction?.total_rzp_amount?transaction?.total_rzp_amount:0)}
                                                                    </p>
                                                                </div>
                                                                <div className='flex justify-start items-center'>
                                                                    <div>
                                                                        <img width="17px" height="17px" src={walletIcon} alt="" />
                                                                    </div>
                                                                    <p className='p-0 m-0 text-[14px] font-normal' style={{ color:(transaction?.type==='Refund' || transaction?.type==='Voucher' || transaction?.type==='Credit')?'#62c': 'rgba(0, 0, 0, 0.8)' }}>
                                                                        {formatNumber(transaction?.from_wallet?transaction?.from_wallet:0)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="text-[14px] capitalize font-normal text-start">
                                                            {truncateDescription(transaction?.description, transaction.id)}
                                                            {transaction?.description?.length > 0 && (
                                                                <span
                                                                    className="text-[#62c] text-[9px] cursor-pointer"
                                                                    onClick={() => toggleDescription(transaction.id)}
                                                                >
                                                                    {expandedDescriptions[transaction.id] ? ' Read Less' : ' Read More'}
                                                                </span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="text-[14px] font-normal text-start">
                                                            {Number(transaction?.total_rzp_amount) ? <button onClick={() => DownloadReceipt(transaction)} className='w-[16px] h-[16px] text-[#62c]'>
                                                                ...
                                                            </button> : null}

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                        {/* mobile */}

                                        <div className={`d-block ${filteredData?.length > 2 ? 'mb-[80px]' : 'mb-[0px]'} md:mb-0 d-md-none`}>
                                        <TransactionPageMobile transactionData={filteredData?.length > 0 ? filteredData : []} DownloadReceipt={DownloadReceipt} />

                                        </div>


                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* for mobile */}
                    {/* <div className="col-12 d-block d-md-none mb-14 ">
                         
                         <TransactionPageMobile transactionData={filteredData} DownloadReceipt={DownloadReceipt} />
                         
                    </div> */}
                </div>
            </div>

            <div className="d-md-none"><BottomNavbar /></div>
        </>
    );
}

export default TransactionPage;
