import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import 'react-datepicker/dist/react-datepicker.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import 'react-alice-carousel/lib/alice-carousel.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { PrimeReactProvider } from "primereact/api";
import { SnackbarProvider } from './SnackBarProvider';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import AuthProvider from "./AuthProvider";
import HomePageProvider from "./HomepageProvider";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from "react-hot-toast";
import { IntercomProvider } from 'react-use-intercom';
import FacebookPixel from "./FacebookPixelCode";
import ProfilePageProvider from "./context/ProfilePageContext";
import AddBudgetProvider from "./context/AddBudgetContext";
// import FreshsalesScript from "./freshsales/FreshsalesGlobal";

     const INTERCOM_APP_ID = 'jc6y4rej'; //leapx

     const clientid = "536872305627-vr20cf0h5lf74i8ftok7grg4e9rng65r.apps.googleusercontent.com"// ads@leapx.ai google oauth for live

    //  const INTERCOM_APP_ID = 'mj9wbeul'; // dipak
//  const clientid = "592550496770-e7kd0akei7tpvq2rr7g9v1p8pn8i3dr8.apps.googleusercontent.com"//dipak
//  const clientid = "354284486392-8maosjofho7dnob1odfsmqpkqrm9atls.apps.googleusercontent.com" // ads@leapx.ai for demo

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <GoogleOAuthProvider clientId={clientid}>
      <SnackbarProvider>
      <IntercomProvider appId={INTERCOM_APP_ID } autoBoot={true} >
   <AuthProvider>
     <ProfilePageProvider>
      <AddBudgetProvider>
    <HomePageProvider>
    <PrimeReactProvider>
    <Toaster
  position="top-right"
  toastOptions={{
    style: {
      zIndex: 9999999, // Set your desired z-index value here
    },
  }}
  />
  <FacebookPixel/>
  {/* <FreshsalesScript /> */}
 <App />
    
    
    </PrimeReactProvider>
    </HomePageProvider>
    </AddBudgetProvider>
    </ProfilePageProvider>
    </AuthProvider>
    </IntercomProvider>
    </SnackbarProvider>
    </GoogleOAuthProvider>
  
  
);
