
import React, { useState, useEffect } from "react";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Card, CardContent } from "../../../components/ui/card"
import IconButton from '@mui/material/IconButton';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "../../../components/ui/carousel";
import AdPreviewSkeleton from "./AdPreviewSkeleton";




export function AdPreviewSliderCard({value, previewLoading, getInstagramPreview, campstatus}) {
  const [api, setApi] = React.useState()
  const [current, setCurrent] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [instaFbPreview, setInstaFbPreview] = useState([])
  const [activePreviewIcon, setActivePreviewIcon] = useState('fb')

  useEffect(() => {
    if (!api) {
      return
    }

    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 1)

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1)
    })
  }, [api])

  const skeltonarr = [1, 2, 3]
//   const skeltonitem = skeltonarr.map((val)=>{
//         return <>
//           <div key={val} className="skeleton-container">
//           <AdPreviewSkeleton/>
//           </div>
//         </>
//   })

const HandleActiveIcon = (data)=>{
  setActivePreviewIcon(data)
}

const showInstaCreative = (creative)=>{
  setInstaFbPreview(creative)
}

const showFbCreative = (creative)=>{
  setInstaFbPreview(creative)
}





  return (
    <div className=" mt-4 me-2 ">
          

      <Carousel  setApi={setApi} className="w-full shadow-none border-none">
          <div className="flex p-2 border-t border-r border-l shadow-sm rounded-tl rounded-tr  justify-between" style={{alignItems:'center'}}>
          
<h5 className="  text-start text-md-start  m-0 p-0 ps-2 ps-md-0  adpreview-heading">Ad Preview</h5>


<div className="d-flex justify-content-center gap-1">
 <button onClick={()=>{showFbCreative(value); HandleActiveIcon('fb')}}> <IconButton sx={{opacity:activePreviewIcon==='fb'?'1':'0.5', '&:hover': {opacity:'1'}}} >
  <FacebookRoundedIcon sx={{color:'#1877F2'}} />
</IconButton></button>
 {campstatus=='ACTIVE'?<button onClick={()=>{showInstaCreative(getInstagramPreview); HandleActiveIcon('insta')}}><IconButton sx={{opacity:activePreviewIcon==='insta'?'1':'0.5',  '&:hover': {opacity:'1'}}}  aria-label="delete">
  <img src="socialIcons/instagram.png" width={18} height={18} className="img-fluid" alt="" />
  {/* <InstagramIcon  sx={{color:'#C13584'}} /> */}
</IconButton></button>:null} 
 
 </div>
          </div>
     
        <CarouselContent>

            {previewLoading?<>
                { skeltonarr.map((val)=>(
                    <>
                      <CarouselItem key={val}>

                      <div key={val} className="skeleton-container">
                    <AdPreviewSkeleton/>
                    </div>

                      </CarouselItem>
                    
                    
                    </>
                    
                ))}

            </>:<>
            {value?.length>0?(<>
            
              {(instaFbPreview?.length>0?instaFbPreview:value)?.map((item, index)=>(
                <>
                
              
                 <CarouselItem key={index}>
              <Card className="shadow-none rounded-bl-xl rounded-br-xl rounded-tr-none rounded-tl-none ">
              

              <CardContent  className="flex aspect-square items-center justify-center p-2">
              <div key={index+1}  className="">
      
      <div key={item} className=" flex justify-center align-middle overflow-hidden pb-3">
            <div className=" flex justify-center" style={{  height: '380px' }} dangerouslySetInnerHTML={{ __html:item?.body }} />
         
           </div>
      
      </div>
                 </CardContent>

             
               
              </Card>
            </CarouselItem>


                
                </>
            ))}
            
            </>):(<>
            
            
              <Card style={{width:'100%'}}>
                <p className="text-center">Ad Preview is not created</p>
              </Card>
            
            </>)}
           
            
            </>}
            
           
         
        </CarouselContent>
        
     {value?.length>0 && <CarouselPrevious className="left-1  bg-[rgba(102,35,204,1)] text-white  hover:bg-[rgba(102,35,204,0.9)]" />} 
     {value?.length>0 && <CarouselNext  className="right-1  bg-[rgba(102,35,204,1)] text-white hover:bg-[rgba(102,35,204,0.9)]" />} 
      
      </Carousel>
     
      <div className="py-2 flex justify-center align-middle gap-1 text-center text-sm text-muted-foreground">
        {previewLoading?'':<>
        {(instaFbPreview?.length>0?instaFbPreview:value).map((val, idx)=>(
             <>
              <p key={idx+1} className=" w-1 h-1 rounded bg-slate-400"></p>
             
             </>
            

        ))}
        
        </>}
      </div>
    </div>
  )
}
