import React from 'react'
import "./landingpage-component.css"
import fretrialicon from '../../asets/asetes/Group 1171277388.png'
import freetrialimg from '../../asets/asetes/startfreetrial img.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ArrowUpRight } from 'lucide-react';


function StartFreeTrial() {

   const openSignupInNewTab = () => {
    window.open("/signup", "_blank");
  };
  return (
      <>
      <div className="container-fluid d-md-none" style={{background:'black', padding:'2rem 0px'}}>
        <div className="container">
            <div className="row rounded py-4" style={{backgroundColor:'rgba(102, 34, 204, 1)'}}>
                 <div className="col-12">
                 <p className='sftc-left-h text-center text-md-start'>Start for free today</p>
                 </div>
                <div className="col-12">
                <div className='text-center d-block d-md-none pb-2'>
                  <img src={fretrialicon} style={{width:'100%', height:'auto', objectFit:'contain'}} className='' alt="" />
                </div>  
                </div>
                <div className="col-12">
                <p className='sftc-left-p text-center text-md-start'> Unlock the power of advanced AI and transform your real estate advertising. Enjoy a one-year free subscription as an early user. hurry, spots are limited! </p>
                <div className='text-center text-md-start'>
                <button className='try-for-free-btn2 flex items-center justify-center' onClick={openSignupInNewTab} style={{backgroundColor:'transparent'}}><span>Try for free</span> &nbsp; <span><ArrowUpRight className=' h-5'/></span></button>

                 </div>
                </div>
                <div className="col-12">
                <div className='sftc-left-point d-flex flex-wrap justify-content-center align-items-start mt-3'>
                           
                               <ul className='custom-list'>
                                  <li className='d-flex align-items-center mb-1 flex-start'>
                                    <CheckCircleIcon sx={{fontSize:'13px'}}/> &nbsp;
                                  <div className=' d-flex align-items-start justify-content-start'>
                                {/* <p className='sftc-point mt-2'> </p>&nbsp; */}
                                <p className='sftc-text p-0 m-0'>No prior experience required</p>
                            </div>
                                  </li>
                                  <li className='d-flex align-items-center mb-1 flex-start'>
                                  <CheckCircleIcon sx={{fontSize:'13px'}}/> &nbsp;
                                  <div className=' d-flex align-items-center justify-content-start'>
                                {/* <p className='sftc-point mt-2'> </p>&nbsp; */}

                                <p className='sftc-text p-0 m-0'>90 seconds & 3 simple steps</p>
                            </div>
                                  </li>
                                  <li className='d-flex align-items-center mb-1 flex-start'>
                                  <CheckCircleIcon sx={{fontSize:'13px'}}/>&nbsp;
                                  
                                  <div className='sftc-left-poin d-flex align-items-center justify-content-start'>
                                {/* <p className='sftc-point mt-2'> </p>&nbsp; */}
                                <p className='sftc-text p-0 m-0'>Lower Cost, Higher Quality Results</p>
                            </div>
                                  </li>
                               </ul>
                           
                            {/* <div className=' d-flex align-items-start justify-content-start'>
                                <p className='sftc-point mt-2'> </p>&nbsp;
                                <p className='sftc-text'>No prior experience required</p>
                            </div>
                            <div className=' d-flex align-items-center justify-content-start'>
                                <p className='sftc-point mt-2'> </p>&nbsp;

                                <p className='sftc-text'>90 seconds & 3 simple steps</p>
                            </div>
                            <div className='sftc-left-poin d-flex align-items-center justify-content-start'>
                                <p className='sftc-point mt-2'> </p>&nbsp;
                                <p className='sftc-text'>Lower Cost, Higher Quality Results</p>
                            </div> */}
                        </div>
                </div>
            </div>
        </div>
      </div>
        {/* <div className='start-free-trial-container d-block d-md-none'>
            <div className='start-free-trial-inner-container justify-content-center d-flex flex-column-reverse flex-md-row'>
                   <div className='d-flex  justify-content-center start-free-trial-right1  align-items-center'>

                   <div className="sftc-left">
                        <p className='sftc-left-h text-center text-md-start'>Start for free today</p>
                        <div className='text-center d-block d-md-none pb-2'>
                  <img src={fretrialicon} className='freetrialimg' alt="" />
                  </div>
                        <p className='sftc-left-p text-center text-md-start'> Unlock the power of advanced AI and transform your real estate advertising. Enjoy a one-year free subscription as an early user. hurry, spots are limited! </p>
                         <div className='text-center text-md-start'>
                         <button className='try-for-free-btn2 ' style={{backgroundColor:'transparent'}}>Try for free</button>
                         </div>
                       
                        <div className='sftc-left-point d-flex justify-content-between align-items-start mt-3'>
                            <div className='sftc-left-point1 d-flex align-items-start justify-content-center'>
                                <p className='sftc-point mt-2'> </p>&nbsp;
                                <p className='sftc-text'>No prior experience required</p>
                            </div>
                            <div className='sftc-left-point1 d-flex align-items-center justify-content-center'>
                                <p className='sftc-point mt-2'> </p>&nbsp;

                                <p className='sftc-text'>90 seconds & 3 simple steps</p>
                            </div>
                            <div className='sftc-left-point1 d-flex align-items-center justify-content-center'>
                                <p className='sftc-point mt-2'> </p>&nbsp;
                                <p className='sftc-text'>Lower Cost, Higher Quality Results</p>
                            </div>
                        </div>
                  </div>
                   </div>

                  
                  <div className='d-none d-md-block freetrialimg-container'>
                  <img src={fretrialicon} className='freetrialimg' alt="" />
                  </div>
                 
            </div>
        </div> */}

        <div className="container-fluid d-none d-md-block start-free-trial-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                       <div className=' startfreetrial-container'>

                       <div className="sftc-left position-absolute" style={{top:'8%', width:'40%', left:'33px'}}>
                        <p className='sftc-left-h text-center text-md-start'>Start for free today</p>
                        <div className='text-center d-block d-md-none pb-2'>
                  <img src={fretrialicon} className='freetrialimg' alt="" />
                  </div>
                        <p className='sftc-left-p text-center text-md-start'> Unlock the power of advanced AI and transform your real estate advertising. Enjoy a one-year free subscription as an early user. hurry, spots are limited! </p>
                         <div className='text-center text-md-start'>
                         <button className='try-for-free-btn2 flex items-center justify-center' onClick={openSignupInNewTab} style={{backgroundColor:'transparent'}}><span>Try for free</span> &nbsp; <span><ArrowUpRight className=' h-5'/></span></button>

                         {/* <button className='tryfree-btn-black'>Try for free</button> */}
                         </div>
                       
                        <div className='sftc-left-point py-3'>
                            <div className='sftc-left-point1 mb-1 d-flex align-items-start justify-content-center'>
                            <CheckCircleIcon sx={{fontSize:'15px', marginTop:'2px', color:'white'}}/> &nbsp;
                                <p className='sftc-text p-0 m-0'>No prior experience required</p>
                            </div>
                            <div className='sftc-left-point1 mb-1 d-flex align-items-start justify-content-center'>
                                {/* <p className='sftc-point mt-1'> </p>&nbsp; */}
                                <CheckCircleIcon sx={{fontSize:'15px', marginTop:'2px', color:'white'}}/> &nbsp;
                                <p className='sftc-text p-0 m-0'>90 seconds & 3 simple steps</p>
                            </div>
                            <div className='sftc-left-point1 mb-1 d-flex align-items-start justify-content-center'>
                                {/* <p className='sftc-point mt-1'> </p>&nbsp; */}
                                <CheckCircleIcon sx={{fontSize:'15px', marginTop:'2px', color:'white'}}/> &nbsp;
                                <p className='sftc-text p-0 m-0'>Lower Cost, Higher Quality Results</p>
                            </div>
                        </div>
                  </div>

                          
                          <img src={freetrialimg} className='startfreetrial-img' alt="" />
                       </div>
                    </div>
                </div>
            </div>
        </div>
        
      </>
  )
}

export default StartFreeTrial