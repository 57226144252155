import React, { useEffect, useState, useContext } from "react";
import { Box, CircularProgress, TextField } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import Axios from "../../../../Axios";
import * as yup from 'yup';
import { useFormik } from "formik";
import brandlogo from '../../../../asets/logo.png';
import '../../registration/registration.css';
import Cookies from "js-cookie";
import { AuthContext } from "../../../../AuthProvider";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(/^(?:[56789]\d{9}|(?!.\.{2})(?!.[.!#$%&'*+=?^_{|}~-]{2})[a-zA-Z0-9][a-zA-Z0-9.!#$%&'*+=?^_{|}~-]*[a-zA-Z0-9]+@[a-zA-Z0-9]+(?:\.[a-zA-Z]{2,}))+$/, 'Please Enter Valid Mobile Number')
    .required('Mobile Number'),
});

function LoginWithMobileOrEmail() {
  const { setUserToken, setFbToken } = useContext(AuthContext);
  const [otp, setOtp] = useState('');
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [timerStarted, setTimerStarted] = useState(false);
  const [showResendBtn, setShowResendBtn] = useState(false);
  const [verifyError, setVerifyError] = useState(null);
  const [isOtpVerify, setIsOtpVerify] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const navigate = useNavigate();

  const query = useQuery();
  const token = query?.get('token');

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSendOTP(values);
    },
  });

  useEffect(() => {
    let intervalId;
    if (timerStarted && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      setTimerStarted(false);
      setShowResendBtn(true);
    }
    return () => clearInterval(intervalId);
  }, [timerStarted, seconds]);

  const formatTime = () => {
    return seconds % 360;
  };

  const onSendOTP = async (mobile) => {
    setIsLoading(true);
    try {
      const res = await Axios.post("/login-otp", { username: mobile.phone });
      if (res.status === 200 && res.data.valid) {
        setIsOtpSend(true);
        setTimerStarted(true);
        setShowResendBtn(false);
        hotTost.success(res.data.message);
      } else {
        hotTost.error(res.data.message);
        setApiError(res.data.message);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onVerifyOtp = async (otp) => {
    setIsLoading(true);
    try {
      const res = await Axios.post("/verify-login", { username: formik.values.phone, code: otp });
      if (res.status === 200 && res.data.valid) {
        setUserToken(res?.data?.token);
        setFbToken(res?.data?.fbToken);
        const Token = res?.data?.token;
        const fbToken = res?.data?.fbToken;
        Cookies.set("userToken", Token, { expires: 14 });
        Cookies.set("fbToken", fbToken, { expires: 14 });
        hotTost.success(res.data.message);
        if (token) {
          navigate(`/?token=${token}`);
        } else {
          navigate("/");
        }
      } else {
        hotTost.error(res.data.message);
        setVerifyError(res.data.message);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setVerifyError("")
    if (otp.length === 6) {
      onVerifyOtp(otp);
    }
  }, [otp]);

  const handleSendOTP = () => {
    setVerifyError("")
    formik.handleSubmit();
  };


useEffect(()=>{
      if(formik.values.phone?.length===10){
        handleSendOTP()
      }
},[formik.values.phone])




  const handlePhoneChange = (e) => {
    formik.handleChange(e);
    setApiError(null);
    setOtp('');
    setIsOtpSend(false);
    setSeconds(60);
    setTimerStarted(false);
    setVerifyError("")
  };

  return (
    <>
      <Toaster position="top-right" />
      <div className="container-fluid">
        <div className="container p-0">
          <div className="row p-0">
            <div className="col-12 p-0">
              <div className="form-card-login">
                <div className="registration-container px-1 py-2 px-md-2">
                  <Box sx={{ width: "100%" }} noValidate autoComplete="off">
                    <div className="mb-3 d-none d-md-block position-relative">
                      <TextField
                        id="phone"
                        name="phone"
                        type="text"
                        label="Mobile No."
                        variant="standard"
                        placeholder="Mobile Number"
                        value={formik.values.phone}
                        size="medium"
                        onChange={handlePhoneChange}
                        onBlur={formik.handleBlur}
                        error={apiError || (formik.touched.phone && Boolean(formik.errors.phone))}
                        helperText={formik.touched.phone && formik.errors.phone}
                        sx={{ width: "100%", marginBottom: '4px', padding: '0' }}
                        color="secondary"
                        required
                      />
                      {formik.touched.phone && <p className="text-end text-danger position-absolute" style={{ top: '64px', right: '0px', fontSize: '0.73rem', textTransform: 'initial' }}>{apiError}</p>}
                    </div>

                    <div className="mb-3 d-block d-md-none position-relative">
                      <TextField
                        id="phone"
                        name="phone"
                        type="text"
                        label="Mobile"
                        variant="standard"
                        placeholder="Phone Number / Email"
                        value={formik.values.phone}
                        size="small"
                        onChange={handlePhoneChange}
                        onBlur={formik.handleBlur}
                        error={apiError || (formik.touched.phone && Boolean(formik.errors.phone))}
                        helperText={formik.touched.phone && formik.errors.phone}
                        sx={{ width: "100%", marginBottom: '4px', padding: '0' }}
                        color="secondary"
                        required
                      />
                      {formik.touched.phone && <p className="text-end text-danger position-absolute" style={{ top: '64px', right: '0px', fontSize: '0.73rem', textTransform: 'initial' }}>{apiError}</p>}
                    </div>
                    {isOtpSend && isOtpVerify && (
                      <div className="mb-3 p-0">
                        <p className="text-start pb-0 mb-0" style={{ width: '100%', color: '#B2B6BE', fontSize: '12px' }}>Enter OTP</p>
                        <div className="m-0 p-0 otp-filds d-flex justify-content-between align-items-center py-2">
                          <OtpInput
                            value={otp}
                            containerStyle={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '0',
                              margin: '0',
                              width: '100%'
                            }}
                            onChange={setOtp}
                            numInputs={6}
                            inputStyle={{
                              width: '38px',
                              borderTop: 'none',
                              borderRight: 'none',
                              borderLeft: 'none',
                              borderBottom: '2px solid gray',
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <div className="text-end position-relative p-0 m-0">
                          {formatTime() ? <span className="" style={{ fontSize: '0.895rem', color: '#B2B6BE' }}>Resend OTP In&nbsp; <span style={{ color: '#F07A4C' }}>{formatTime()}&nbsp;sec</span> </span> : null}
                          <span className="text-start p-0 m-0 text-danger position-absolute" style={{ top: '-5px', left: '0px', fontSize: '0.73rem', textTransform: 'initial' }}>{verifyError}</span>
                        </div>
                      </div>
                    )}
                    <div className="mb-2" style={{ marginTop: "14%" }}>
                      <button disabled={isLoading || (isOtpSend && timerStarted)} className="submit-button " style={{ borderRadius: '999px' }} onClick={handleSendOTP}>
                        {isLoading ? <CircularProgress size={20} color="inherit" /> : (isOtpSend && timerStarted) ? 'Verify OTP' : (formatTime() === 0) ? 'Resend OTP' : 'Send OTP'}
                      </button>
                    </div>
                    <div className="mt-2">
                      <p className="text-center">
                        Don't have an account?{" "}
                        <NavLink to="/signup" className="text-primary-imp">
                          Sign Up
                        </NavLink>
                      </p>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginWithMobileOrEmail;
