import axios from 'axios';




export default  axios.create({

      // baseURL: "https://demo.leapxads.com/api",
     baseURL: "https://leapxads.com/api",
})

