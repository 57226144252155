import React, { useContext, useEffect, useState } from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton } from '@mui/material';
import { Step, StepLabel, Stepper } from "@mui/material";
// import ConnectToFbAndSelectPages from './ConnectToFbAndSelectPages';
//import facebook from "../../asets/facebook.png";
import facebook from "../../../asets/facebook.png";

import Person2Icon from "@mui/icons-material/Person2";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Dropdown } from 'primereact/dropdown';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import Axios from '../../Axios';
import {Accordion,AccordionDetails, AccordionSummary, Typography} from "@mui/material";
// import { AuthContext } from '../../AuthProvider';
import { Slider } from 'primereact/slider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Payment from '../../paymentmgn/payment-gateway/PaymentNow';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from '../../../Axios';
import Navbar2 from '../../../navbar/Navbar2';
import WebStepper from '../../../adesktop-webpage/web-component/webstepper/WebStepper';
import ConnectToFbAndSelectPages from '../../../pages/review-details/ConnectToFbAndSelectPages';
import { AuthContext } from '../../../AuthProvider';
import Payment from '../../../paymentmgn/payment-gateway/PaymentNow';
// import ManageAdPreview from '../../../adesktop-webpage/web-campaign-creation/ManageAdPreview';
// import WebStepper from '../../adesktop-webpage/web-component/webstepper/WebStepper';
// import Navbar2 from '../../navbar/Navbar2';


const steps = ["Provide details", "Review details", "Confirm & Pay"];
// const initialContent =
// "Hi Vishal, promote M3M Crowwn an ultra-luxury property in Gurgaon's Sector 112, to affluent digitally savvy indivisuals adged 30-55, passionate about green spaces, generaal leads for self-use or investment.";

function ConfirmAndPayForLease() {
    const [userDetail, setUserDetail] = useState({});
    const { state } = useLocation();
    const [duration, setDuration] = useState(30);
    const [isLoading, setIsLoading] = useState(false);
    const [modifiedObject, setModifiedObject] = useState("")
    const {userToken, fbToken} = useContext(AuthContext)
    const payWithRazorpay = Payment()
    const [isUserDetailsLoad, setIsUserDetailLoad] = useState(false)
    const [amenitiesArray, SetAmenitiesArray] = useState([])
    const navigate = useNavigate()
    const [sliderValue, setSliderValue] = useState(50000); // Initial value
    const [lifeTimeBudget, setLifeTimeBudget] = useState(50000);
    const [isWallateAndBudgetEqual, setIsWallateAndBudgetEqual,] = useState(false)
    const [content, setContent] = useState("");
    const [isConfirm, setIsConfirm] = useState(false)
    const [usedBalance, setUsedBalance] = useState(0)
    const [amountPayble, setAmountPayble] = useState(0)
    const [remainingWalletBalance, setRemainingWalletBalance] = useState(0)
     const [privacypolicyCheck, setPrivacypolicyChecked] = useState(false)
    const [expandedAccordion, setExpandedAccordion] = useState(false)
      const initialState = {
        Name:"",
        Address:"",
        Property:"",
        Size:"",
        Price:"",
        Status:"",
        // Designed: "",
        Budget: 19900,
        Day: 30,
        Flat:"",
      };

 const [selectFacebookPage, setSelectFacebookPage] = useState(null);
    const [value, setValue] = useState(initialState);
    const [createProMessage, setCreateProMessage] = useState("")
    const [createProCampaign, setCreateProCampaign] = useState("")
   
    
    function removeSpacesFromKeys(obj) {
        let newObj = {};
        Object?.keys(obj)?.forEach((key) => {
          let newKey = key.replace(/\s+/g, ""); // Remove spaces using a regular expression
          newObj[newKey] = obj[key];
        });
        return newObj;
      }
     
 //console.log("statesum", state)


      useEffect(()=>{
            setCreateProCampaign(state?.campaign)
            setCreateProMessage(state?.message)
            setContent(state?.message?.summary)
            // setSliderValue(state?.message?.starting_price?Number(state?.message?.starting_price):50000)
           // console.log("intprice", state?.message?.int_price)
      },[state?.message || state?.campaign])
    
//console.log("message", state?.message)
//console.log("campaign", state?.campaign)


      useEffect(()=>{
           if(state?.address){
            let modifiedObject2 = removeSpacesFromKeys(state?.address)
            let amenitiesArray2 = modifiedObject?.Amenities
              ? modifiedObject?.Amenities.split(", ")
              : [];
              if(modifiedObject2){
               // console.log("modify", modifiedObject2)
                setModifiedObject(modifiedObject2)
              }
              if(amenitiesArray2){
                SetAmenitiesArray(amenitiesArray2)
              }
           }
       
      },[])
    const handleUser =  () => {
      setIsUserDetailLoad(true)
        //   let stringWithoutDoubleQuotes = tokenLogin.replace(/"/g, "");
      
        Axios.get("/user", {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            const data = response?.data?.user;
           //  console.log("==============================>", data);
            setUserDetail(data);
            
              setIsUserDetailLoad(false)
           
          })
          .catch((error) => {
            console.log("Error setting up request:");
            setIsUserDetailLoad(false)
          });
      };

      useEffect(()=>{
        handleUser()
      },[])

//edit property
const handleEditPrperty = async () => {
  setIsConfirm(true)
    const body = {
      property_id:state?.propertyId?state?.propertyId:createProCampaign?.property_id,
      leads: Math.round(Number(lifeTimeBudget) / Number( createProMessage?.cost_per_lead) ),
      page_id: selectFacebookPage.id,
      days: Number(duration),
      summary: content,
      lifetime_budget: Number(lifeTimeBudget),
      campaign_id:state?.campaignDfId?state?.campaignDfId:createProCampaign?._id
    };

    const formData = new FormData()
      formData.append("property_id", body.property_id)
      formData.append("leads", body.leads)
      formData.append("page_id", body.page_id)
      formData.append("days", body.days)
      formData.append("summary", body.summary)
      formData.append("lifetime_budget", body.lifetime_budget)
      formData.append("campaign_id", body.campaign_id)
      formData.append("amount", amountPayble)
    setIsLoading(true);
    try {
      const res = await Axios.post("/edit-property", formData, {
        headers: {
          Authorization: userToken,
        },
      });
      if (res.status === 200) {
        const data = res?.data;
       // console.log("editproperty", data);
         //Payment gateway
         
         const adcampaignBody = {
          page_id: selectFacebookPage.id,
          // access_token: fbToken,
          campaign_id: body.campaign_id,
          used_wallet_spend:usedBalance,
          payable_amount:amountPayble
          // payable_amount:2
      
        };


         if(adcampaignBody.campaign_id){
              if(isWallateAndBudgetEqual){            
                const prefilledDetails = {name:userDetail?.name, email:userDetail?.email, number:userDetail?.mobile}
               //  payWithRazorpay(amountPayble, adcampaignBody, isWallateAndBudgetEqual )
               
                 payWithRazorpay(amountPayble, adcampaignBody, isWallateAndBudgetEqual,  1 , prefilledDetails )
                 setTimeout(()=>{
                  setIsConfirm(false)
                 },3000)
                
              }else{
                createOrder(amountPayble, adcampaignBody, isWallateAndBudgetEqual)
              }
          
          // payWithRazorpay(amountPayble, adcampaignBody, isWallateAndBudgetEqual )
          

         }
   

     //   handleCampaign();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
      setIsConfirm(false)
    }
  };



// create order
const createOrder = async (amountPayble, adcampaignBody, isWallateAndBudgetEqual)=>{
  setIsConfirm(true)
  const body = {
    
      amount: amountPayble*100, //in smallest currendcy unit. i.e in paisa
      currency: "INR",
      receipt: "Anurag#955",
      notes: {
          description: "testing razorpay",
          campaign: adcampaignBody.campaign_id,   
  }
  }
   try {
      const res = await Axios.post("/create-order", body, {
          headers:{
            Authorization:userToken
          }
      })
      if(res.status===200){
        
           const data = res?.data?.message
            const orderid = data?.id
      //     console.log("datdsda", orderid)
         const prefilledDetails = {name:userDetail?.name, email:userDetail?.email, number:userDetail?.mobile}
           //console.log("prefilledDetails", prefilledDetails)
             if(data){
              payWithRazorpay(amountPayble, adcampaignBody, isWallateAndBudgetEqual,  orderid , prefilledDetails )
               setTimeout(()=>{
                setIsConfirm(false)
               },2000)
             }
      }
   } catch (error) {
     console.log(error)
     setIsConfirm(false)
   }
}


// confirm and launch
      const OnConfirmAndLaunch = () =>{
        if (!selectFacebookPage) {
          alert("Please Select Facebook Page");
          return;
        }
        handleEditPrperty() 
       }

      const itemTemplate = (option) => {
        return (
          <div className="p-flex p-align-center">
            <img src={facebook} alt={option.name} className="city-image" />
            <span style={{ marginLeft: 10 }}>{option.name}</span>
          </div>
        );
      };
    
      const handleDays = (event) => {
        const day = event.target.value ? event.target.value : 30;
        setDuration(day);
        setValue({
          ...value,
          Day: event.target.value ? event.target.value : 30,
        });
      };
      const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

     // const cpl = Math.max((Number(createProMessage?.int_price)/1000000)*(Number( createProMessage?.cost_per_10L)), 50)
        const intprice = (Math.max(Number(createProMessage?.int_price), 5000000) - 5000000)/1000000;

       const cpl = 100 + (intprice*Number(createProMessage?.cost_per_10L))
      //const cpl = 100+((Math.max(createProMessage?.int_price, 5000000) - 5000000)/1000000)*createProMessage?.cost_per_10L
 
useEffect(()=>{
// wallet balance
const wallatetResult = Math.round(Number(userDetail?.wallet))
if( wallatetResult>(Number(lifeTimeBudget ? lifeTimeBudget : 50000))){
        
  const balance =  wallatetResult - Number(lifeTimeBudget ? lifeTimeBudget : 50000) 
    const usedbalance = Number(lifeTimeBudget ? lifeTimeBudget : 50000)
    setUsedBalance(usedbalance) 
 // console.log("usedbalance",usedbalance )
  setIsWallateAndBudgetEqual(true)  
  setRemainingWalletBalance(balance)
  setAmountPayble(0)           
}else if(wallatetResult===(Number(lifeTimeBudget ? lifeTimeBudget : 50000))){
  setAmountPayble(0)  
  setUsedBalance(wallatetResult )   
  setIsWallateAndBudgetEqual(true)
  setRemainingWalletBalance(0)
}else{
const balance =  Number(lifeTimeBudget ? lifeTimeBudget : 50000) - wallatetResult
  setAmountPayble(balance)    
  setUsedBalance( wallatetResult )   
  setRemainingWalletBalance(0)
  setIsWallateAndBudgetEqual(false)  
}
},[userDetail?.wallet, lifeTimeBudget])

const theme = createTheme({
  palette: {
    primary: {
      main: '#6622cc', // Active step background color
    },
    secondary: {
      main: '#000', // Active step label color
    },
  },
});

const stepweb = [
  {
    label: 'Provide details',
    description: `Please upload official website link of the project, brochures and other pdfs related to the property you want to sell`,
  },
  {
    label: 'Review details',
    description:"We have pulled and pre-filled the details of the property for your convenience kindly review/ edit/ add details in the form below."
  },
  {
    label: 'Confirm & Pay',
    description: ``,
  },
];


// const handleBudgetChange = (e)=>{
//         console.log("e", e.target.value)
//   setSliderValue(Number(e.taget.value));
//   setLifeTimeBudget(Number(e.target.value));
// }
const handleBudgetChange = (e) => {
  const inputVal = e.target.value;

  const isValidInput = inputVal === '' || /^[0-9]+$/.test(inputVal);
  if (!isValidInput) return;

  setLifeTimeBudget(inputVal);

  if (inputVal !== '') {
    const numericValue = Math.max(10000, Math.min(200000, parseInt(inputVal, 10)));
    setSliderValue(numericValue);
  }
};

// const handleSliderChange = (value) => {
//   setSliderValue(value);
//   setLifeTimeBudget(value);
// };
const handleSliderChange = (value) => {
  const roundedValue = Math.round(value / 1000) * 1000; // Round to the nearest thousand
  setSliderValue(roundedValue);
  setLifeTimeBudget(roundedValue);
};
//console.log("CreateProMessage", createProMessage)
     // console.log("user", userDetail)
  return (
      <>
          <>
          <div className=" d-md-none">
     <Navbar2 menu={true} />
     </div>
              <div className="contaoner-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                    <div className="d-none d-md-block">
              <WebStepper steps={stepweb} active={2}/>
             </div> 
                    <div
                            className="mt-4"
                            style={{ paddingTop: 20, paddingBottom: 10 }}
                          >
                            <Box sx={{ width: "100%", }} className="mt-1 d-md-none ">

                            <ThemeProvider theme={theme}>
                            <Stepper  activeStep={2} alternativeLabel>
                              {steps.map((label) => (
                                <Step key={label} sx={{'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text':{fill:'white'},'& .MuiStepLabel-root .Mui-active':{color:'rgba(102, 34, 204, 1)'}, '& .MuiStepLabel-root .Mui-completed':{color:'rgba(102, 34, 204, 1)'} }}>
                                  <StepLabel sx={{
                                          '& .Mui-active': {
                                            color: 'secondary.main',
                                          },
                                          '& .Mui-completed': {
                                            color: 'secondary.main', // Active label color
                                          },
                                          
                                        }} style={{ fontSize: 10 }}>
                                    {label}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                            </ThemeProvider>
                                </Box>
                          </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                      <div className="form-card rounded">
                     
                        <form>
                          
                        {setContent?.length>0?<Accordion onClick={()=>setExpandedAccordion(!expandedAccordion)}>
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                         <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                         <Typography>Summary</Typography>
                        <Typography sx={{textAlign:'end', fontSize:'10px', marginRight:'2px'}}>{expandedAccordion?'click to collapse':'click to expand'}</Typography>
                         </Box>
                        
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography sx={{paddingLeft:'0.6rem'}}>
                          <p
                          dangerouslySetInnerHTML={{ __html:content }}
                            style={{ textAlign: "justify", color:'#585858', fontSize:'14px' }}
                          
                          />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>:null}
                          {/* <div className="mb-1 ps-2">
                            <p className="heading-Property">
                            Ad campaign summary
                            </p>

                            <p
                            dangerouslySetInnerHTML={{ __html:isExpanded?displayedText:displayedText?.slice(0, 200) }}
                              style={{ textAlign: "justify", color:'#585858', fontSize:'.90rem' }}
                              className="heading-Property1 d-none d-md-block"
                            />
                             <p
                            dangerouslySetInnerHTML={{ __html:isExpanded?displayedText:displayedText?.slice(0, 100) }}
                              style={{ textAlign: "justify", color:'#585858', fontSize:'.90rem' }}
                              className="heading-Property1 d-block d-md-none"
                            />
                            

                                <div className='text-end m-0'>
                                <span
                                className=""
                                style={{color:'#F07A4C', fontSize:'.90rem', cursor:'pointer'}}
                                onClick={toggleText}
                              >
                               {isExpanded ? 'Show less' : 'Show more'}
                              </span>
                                </div>
                             
                            
                          </div> */}
                         
                         {isUserDetailsLoad ? (<>

                          <div className=' mt-2 text-start'>
                            <b className='m-0 p-0'>Facebook page</b>
                            <Typography sx={{padding:'0px', margin:'0px'}} variant='h2'> <Skeleton /></Typography>
                          </div>
                         
                         </>):(<>
                         
                          {userDetail?.page_id?.length > 0 ? (
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "flex-start",
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "2%",
                                paddingBottom: "2%",
                              }}
                            >
                
                              <label
                                htmlFor="weburl"
                                className="d-block text-start mb-md-2 mt-2"
                              >
                                <b> Facebook page*</b>
                              </label>
                              <Dropdown
                                value={selectFacebookPage}
                                onChange={(e) => setSelectFacebookPage(e.value)}
                                options={userDetail?.page_id}
                                optionLabel="name"
                                placeholder="Select a Facebook page"
                                style={{ width: "100%", textAlign: "left" }}
                                itemTemplate={itemTemplate}
                              />
                            </div>
                          ) : (
                            <>
                          
                              <ConnectToFbAndSelectPages forAccountInfo={2}  handleUser={handleUser} forsubmit={2}/>
                              <div
                              className='mt-0'
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              
                                marginBottom: "5%",
                              }}
                            >
                            
                            </div>
                            </>
                          )}
                         
                         </>)}
                         
                          <div className="mb-3 text-start mt-1 mt-md-3">
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                <b style={{ color: "black" }}>
                                  Campaign duration
                                </b>
                              </FormLabel>
                              <RadioGroup
                                defaultValue="30"
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value?.Day}
                                onChange={handleDays}
                              >
                                <div
                                  className={
                                    value?.Day === "15"
                                      ? "checkbox"
                                      : "checkbox1"
                                  }
                                >
                                  <FormControlLabel
                                    value="15"
                                    control={
                                      <Radio style={{ color: " #6622CC" }} />
                                    }
                                    label="15 Days"
                                  />
                                </div>
                                <div
                                  className={
                                    value?.Day === "30"
                                      ? "checkbox"
                                      : "checkbox1"
                                  }
                                >
                                  <FormControlLabel
                                    value="30"
                                    control={
                                      <Radio style={{ color: " #6622CC" }} />
                                    }
                                    label="30 Days"
                                  />
                                </div>
                                <div
                                  className={
                                    value?.Day === "60"
                                      ? "checkbox"
                                      : "checkbox1"
                                  }
                                >
                                  <FormControlLabel
                                    value="60"
                                    control={
                                      <Radio style={{ color: " #6622CC" }} />
                                    }
                                    label="60 Days"
                                  />
                                </div>
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="budget-slider-container rounded">
                            <div>
                              <div>
                                <label
                                  htmlFor="weburl"
                                  className="d-block text-start "
                                >
                                  <b>Lock your budget</b>
                                </label>
                                <div
                                  style={{
                                    color: "#262626",
                                    fontSize: 25,
                                    fontWeight: "700",
                                    textAlign: "left",
                                  }}
                                  className='mb-1'
                                >
                                  {/* <CurrencyRupeeIcon
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "700",
                                    }}
                                  /> */}
                                   <div className='py-4'>
                                   <span className="">
                                    {/* {lifeTimeBudget ? lifeTimeBudget : 50000} */}
                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                  <span style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)', color: '#000' }}><CurrencyRupeeIcon
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "700",
                                    }}
                                  /></span>
                                  <input
                                    type="text"
                                    value={lifeTimeBudget}
                                    onChange={handleBudgetChange}
                                    style={{
                                      fontSize: '25px',
                                      fontWeight: '700',
                                      width: '150px',
                                      outline: 'none',
                                      textAlign: 'center',
                                      borderTop: 'none',
                                      borderLeft: 'none',
                                      borderRight: 'none',
                                      paddingLeft: '25px', // Ensure the text doesn't overlap the rupee symbol
                                    }}
                                  />
                                </div>
                                    {/* <input
                                      type="text"
                                          value={`${lifeTimeBudget}`}
                                      onChange={handleBudgetChange}
                                      style={{ fontSize: "25px", fontWeight: "700", width: '150px', textAlign: 'center' }}
                                    /> */}
                                  </span>
                                   </div>
                                 
                                  <p className='' style={{fontSize:'10px', fontWeight:'600'}}>(Inclusive of GST)</p>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 mt-3 px-2 px-md-2 text-center ">
                              <Slider
                                value={sliderValue}
                                onChange={(e) => {
                                  handleSliderChange(e.value)
                                  // setSliderValue(Number(e.value));
                                  // setLifeTimeBudget(e.value);
                                }}
                                min={10000}
                                max={200000}
                                step={2000}
                                // className="w-14rem"
                              />
                              <div className="min-max mb-2 mt-1">
                                <div>
                                  <p>Min</p>
                                  <p>10,000</p>
                                </div>
                                <div>
                                  <h6>Max</h6>
                                  <h6>2,00,000</h6>
                                </div>
                              </div>
                              {/* <div>
                                <p className="p-0 mb-4  text-start" style={{fontSize:'12px'}}>GST additional as applicable</p>
                                </div> */}
                              <div className=''> 
                               {lifeTimeBudget >= 10000 && <span
                                  style={{
                                    fontSize: "52px",
                                    fontWeight: "800",
                                    margin: 0,
                                  }}
                                >
                                  ~{createProMessage?.int_price
                                    ? (
                                    //  leadsresult
                                   Math.round( Number(lifeTimeBudget)/createProMessage?.cpl)
                                  
                                    )
                                    : 0}
                                  {/* {Math.round(Number(lifeTimeBudget)/createPropertyData2?.cost_per_lead)} */}
                                </span>}
                                
                                <p className='text-center' style={{ margin: 0 }}>
                                  <Person2Icon style={{ color: "#000000" }} />
                                  {lifeTimeBudget >= 10000 ? 'Estimated Leads':<span className='text-danger'>Minimum budget is 10000</span>}
                                </p>
                              </div>
                            </div>
              {/* leapX wallet balance */}
                 <div className="container">

                 <div className="row p-0 mb-3">
                  <div className="col-8 p-0 text-start" style={{fontSize:'14px', fontWeight:'600'}}>
                  <span>Wallet Balance :</span> 
                  </div>
                  <div className="col-4 p-0 text-end">
                  <b> <CurrencyRupeeIcon
                    style={{
                      fontSize: "14px",
                      marginBottom:'2px',
                      fontWeight: "700",
                    }}
                  /> {Math.round(userDetail?.wallet)}</b>
                  </div>
                  <div className="col-8 p-0 text-start" style={{fontSize:'14px', fontWeight:'600'}}>
                  <span>Amount Payable :</span> 
                  </div>
                  <div className="col-4 p-0 text-end">
                  <b> 
                      <CurrencyRupeeIcon
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          marginBottom:'2px',
                        }}
                      />{amountPayble} 
                      {
                      
                      }
                      </b>
                  </div>
                  <div className="col-8 p-0 text-start" style={{fontSize:'14px', fontWeight:'600'}}>
                  <span>Remaining Wallet Balance :</span> 
                  </div>
                  <div className="col-4 p-0 text-end">
                  <b> 
                                  <CurrencyRupeeIcon
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      marginBottom:'2px',
                                    }}
                                  />{remainingWalletBalance} 
                                  {
                                   
                                  }
                                  </b>
                              </div>
                            
                            </div>
                            </div>

                              <div className='d-flex flex-start justify-content-start align-items-start'>
                              <Checkbox {...label} 
                              checked={privacypolicyCheck}
                              onChange={(e)=>setPrivacypolicyChecked(e.target.checked)}
                                sx={{
                                  padding:'0px',
                                  margin:'0px',
                                  color: 'red',
                                  '&.Mui-checked': {
                                    color:'#6622cc',
                                  },
                                }}
                              />
                                 <small style={{fontSize:'12px', fontWeight:'400', textAlign:'start' }}>By checking this box, you agree to our <a href="https://www.leapx.ai/terms-of-use" target='_blank' style={{color:'#6622cc'}}>Terms of Use</a> & <a href="https://www.leapx.ai/privacy-policy" target='_blank' style={{color:'#6622cc'}}>Privacy Policy</a></small>
                              </div>
                            <div className="my-4 d-flex  flex-column flex-md-row-reverse flex-md-wrap align-items-md-start align-items-center justify-content-md-end">
                              {
                               (lifeTimeBudget >= 10000 && privacypolicyCheck)? (<>
                                <button
                                className="submit-button5 ms-2 btn-confirm-pay"
                                style={{backgroundColor:'#62c', cursor:isConfirm?'not-allowed':'pointer'}}
                                type="button"
                                 disabled={isConfirm}
                                onClick={() => OnConfirmAndLaunch()}
                              >
                                Confirm & Pay
                              </button>
                                </>):(<>
                                  <button
                                className="submit-button5 ms-2 border btn-confirm-pay"
                                style={{backgroundColor:'#a799be'}}
                                type="button"
                               
                              >
                                Confirm & Pay
                              </button>
                                </>)
                              }
                              
                              <div className="text-center p-0 "  >
                                <div className='' style={{width:'100%'}}>

                                <Button variant='outlined' className='d-md-inline d-none' onClick={() =>navigate("/review-details-lease", {
                                    state: {
                                      status:0,
                                      propertyId:state.propertyId,
                                      campaignDfId:state?.campaignDfId
                                     
                                    },
                                  })} sx={{border:'1px solid #6622CC', color:'#6622CC', padding:'10px 20px', width:'100%', }} startIcon={ <ArrowBackIcon />}>Back</Button>
                                </div>
                              
                                <span
                                className='d-block d-md-none '
                                  onClick={() =>navigate("/review-details-lease", {
                                    state: {
                                      status:0,
                                      propertyId:state.propertyId,
                                      campaignDfId:state?.campaignDfId
                                     
                                    },
                                  })}
                                  style={{
                                    color: "black",

                                    marginTop: "8px",
                                    display: "",

                                    fontWeight: "700",
                                    cursor: "pointer",
                                    fontSize: 16,
                                  }}
                                >
                                  <ArrowBackIcon />
                                  Go back
                                </span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
      </>
  )
}

export default ConfirmAndPayForLease