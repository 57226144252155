import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { Toast } from "primereact/toast";
import Cookies from "js-cookie";
import IntercomProviderPage from "./intercom-chat/IntercomProviderPage";


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


export default function PrivateRoute(props) {
  const navigate = useNavigate()
  const {userToken} = useContext(AuthContext)
  const toast = useRef(null)
  const {Component} = props;

  const query = useQuery();
  const token = query.get('token');
  
  useEffect(()=>{
    const getToken = Cookies.get('userToken');
    if(!getToken){
        if(token){
          navigate(`/login?token=${token}`)
        }else{
          navigate("/login")
        }
        
    }
  },[])
     
  return(
    <>
       <Toast ref={toast} />
    {userToken?<><Component/></>:null}
     
      {/*  */}
    </>
  )
}
