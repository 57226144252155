// import { East } from "@mui/icons-material";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link, useNavigate } from "react-router-dom";
import MessageIcon from '@mui/icons-material/Message';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HomepageCarouselCard = ({ data }) => {
  //   console.log("datacard", data)
  var settings = {
   
  };
 
  //console.log(data.campaign_id, typeof data.campaign_id);
  const spend = data?.spend ?Math.round( data?.spend) : 0;
  const spendPercentage = Math.round((spend / data.lifetime_budget) * 100);

  const navigate = useNavigate();
  function handleNavigateAdOverview() {
    navigate("/campaign-overview", {
      state: {
        campaign_id: data?._id,
        cmpid:data?.campaign_id
      },
    });
  }

  function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }


// formate data
function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Define months as an array
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Get day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Function to add ordinal suffix to the day
  // const getOrdinalSuffix = (number) => {
  //   const suffixes = ['th', 'st', 'nd', 'rd'];
  //   const v = number % 100;
  //   return (v >= 11 && v <= 13) ? 'th' : suffixes[(v - 1) % 10] || 'th';
  // };

  // Add ordinal suffix to the day
  const formattedDay = `${day}`;

  // Assemble the final formatted date
  const formattedDate = `${formattedDay} ${month} ${year}`;

  return formattedDate;
}




  return (
    <>

    
     
        <div className="wallet-2 flex flex-col ">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width:'100%'
            }}
            className=""
          >          
            <div className="Campaign flex-grow-1" onClick={handleNavigateAdOverview}>            
              {/* <span
            className=" d-flex align-items-center justify-content-center me-1"
            style={{
              height: "30px",
              width: "30px",
              backgroundColor: "#62c",
              borderRadius: "50%",
            }}
          >
            <span style={{ color: "white" }}>{data?.name?.slice(0, 1)}</span>
          </span> */}
              <div className="" style={{minWidth:'60%'}}>
                <p
                  style={{
                    
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform:'capitalize',
                    width:'100%'
                  }}
                  className=""
                
                >
                  {data.name.length>28?`${data?.name.slice(0, 29)}..`:data?.name}
                 
                </p>
                {/* <h6 className="campaign-home-h6"># 159 </h6> */}
                {/* <h6 className="campaign-home-h6">#{data?.campaign_id} </h6> */}
                <h6 className="campaign-home-h6">{data?.date?formatDate(data?.date):null} </h6>
              </div>
            </div>
            {/* </Link> */}
            <div   className=" d-flex flex-column align-items-center" >
              <p className="status-live">
               
                <span
                  style={{
                    overflow: "hidden",
                    maxWidth: "15ch",
                    textOverflow: "ellipsis",
                    color:data?.status==='ACTIVE'?'#0EAA00':data?.status==='PAUSED'?'#F07A4C':'#6622CC'
                  }}
                >
                  {" "}
                  {data?.status}
                </span>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                width:'70px'
                }}
              >
                
                <img src="socialIcons/fbLogo.svg" alt="" />
                <img style={{width:'15px', height:'15px'}} src="socialIcons/instagram.png" alt="facebook" />
                <img src="socialIcons/googleLogo.svg" alt="" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <div className="left-kapil">
                <div>
                  {/* <img src="" alt="" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <rect
                      width="39.6547"
                      height="41.543"
                      rx="4.1543"
                      fill="#E2D6F3"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.0029 21.943C28.4258 22.9089 29.4228 24.2175 29.4228 25.9623V29.078H32.5385C33.1098 29.078 33.5771 28.6106 33.5771 28.0394V25.9623C33.5771 23.6982 29.8694 22.3584 27.0029 21.943Z"
                      fill="#6622CC"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.7646 21.943C10.3418 22.9089 9.34477 24.2175 9.34477 25.9623V29.078H6.22904C5.65782 29.078 5.19046 28.6106 5.19046 28.0394V25.9623C5.19046 23.6982 8.89818 22.3584 11.7646 21.943Z"
                      fill="#6622CC"
                    />
                    <path
                      d="M19.0352 20.7705C21.3295 20.7705 23.1895 18.9106 23.1895 16.6162C23.1895 14.3219 21.3295 12.4619 19.0352 12.4619C16.7408 12.4619 14.8809 14.3219 14.8809 16.6162C14.8809 18.9106 16.7408 20.7705 19.0352 20.7705Z"
                      fill="#6622CC"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24.2276 20.7705C26.5228 20.7705 28.3819 18.9115 28.3819 16.6162C28.3819 14.321 26.5228 12.4619 24.2276 12.4619C23.7394 12.4619 23.2825 12.5658 22.8463 12.7112C23.74 13.8164 24.2275 15.1948 24.2275 16.6162C24.2275 18.0376 23.74 19.416 22.8463 20.5213C23.2825 20.6667 23.7394 20.7705 24.2276 20.7705ZM17.9961 21.8091C15.2231 21.8091 9.6875 23.2008 9.6875 25.9634V28.0405C9.6875 28.6118 10.1549 29.0791 10.7261 29.0791H25.2661C25.8374 29.0791 26.3047 28.6118 26.3047 28.0405V25.9634C26.3047 23.2008 20.7691 21.8091 17.9961 21.8091Z"
                      fill="#6622CC"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.8418 20.7705C11.5465 20.7705 9.68747 18.9115 9.68747 16.6162C9.68747 14.321 11.5465 12.4619 13.8418 12.4619C14.3299 12.4619 14.7869 12.5658 15.2231 12.7112C14.3294 13.8164 13.8418 15.1948 13.8418 16.6162C13.8418 18.0376 14.3294 19.416 15.2231 20.5213C14.7869 20.6667 14.3299 20.7705 13.8418 20.7705ZM20.0732 21.8091C22.8462 21.8091 28.3818 23.2008 28.3818 25.9634V28.0405C28.3818 28.6118 27.9145 29.0791 27.3433 29.0791H12.8032C12.232 29.0791 11.7646 28.6118 11.7646 28.0405V25.9634C11.7646 23.2008 17.3002 21.8091 20.0732 21.8091Z"
                      fill="#6622CC"
                    />
                  </svg>
                </div>
                <Link to="/lead-management">
                  <div className="left-right">
                    <p>Leads</p>
                    <p>
                      {/* 250  */}
                      {data?.leads_count ? Number(data?.leads_count) : 0}
                      {/* <span>+0</span> */}
                    </p>
                  </div>
                </Link>
              </div>
              <div className="left-kapil">
                <div>
                  <p className="d-flex justify-content-center align-items-center" style={{height:'38px', width:'38px', backgroundColor:'#E2D6F3', borderRadius:'4px', padding:'10px'}}>
                  <MessageIcon sx={{color:'#62c'}}/>
                  </p>
                 
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <rect
                      width="39.6547"
                      height="41.543"
                      rx="4.1543"
                      fill="#E2D6F3"
                    />
                    <path
                      d="M20 12C15.0304 12 11 16.0282 11 21C11 25.9718 15.0304 30 20 30C24.9711 30 29 25.9718 29 21C29 16.0282 24.9718 12 20 12ZM20 14.1892C21.35 14.1892 22.6067 14.5892 23.6663 15.2693L14.2693 24.6663C13.5641 23.5735 13.1891 22.3005 13.1892 21C13.1892 17.2447 16.2447 14.1892 20 14.1892ZM20 27.8108C18.6995 27.8106 17.4266 27.4356 16.3337 26.7307L25.73 17.3337C26.4352 18.4265 26.8102 19.6995 26.81 21C26.81 24.7553 23.7553 27.8108 20 27.8108Z"
                      fill="#6622CC"
                    />
                  </svg> */}
                </div>
                <div className="left-right">
                  <p>Messages</p>
                  <p>
                    0 
                    {/* <span>+0</span> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="slider">
              <div style={{ width: 80, height: 80 }}>
                <CircularProgressbar
                  value={spendPercentage?spendPercentage:0}
                  text={`${spendPercentage ? spendPercentage : 0}% `}
                  styles={{
                    path: {
                      // stroke: `#ffffff`,
                      stroke: "#62c",
                      strokeLinecap: "butt",
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                      
                    },
                    trail: {
                      // stroke: "#FFFFFF83",
                      stroke: "#8E8E8E6B",
                      strokeLinecap: "butt",
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },
                    text: {
                      // fill: "#ffffff",
                      fill: "#A19fac",
                      fontSize: "16px",
                    },
                    background: {
                      fill: "#3e98c7",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="spend2">
                  {/* <h6>&#8226; Spent</h6> */}
                  <h6>
                    <div className="circle-type"></div> <span> Spent</span>
                  </h6>
                  {/* <p>₹ 15,000</p> */}
                  <p className="p-0" style={{fontSize:'11px'}}> <CurrencyRupeeIcon sx={{fontSize:'11px'}}/> {data?.spend ? formatIndianNumber(Math.round( data?.spend)) : 0}</p>
                </div>
                <div className="spend2">
                  {/* <h6>&#8226; Budget</h6> */}
                  <h6>
                    <div className="circle"></div> <span></span>
                    Deployed
                  </h6>
                  {/* <p>₹ 25,000</p> */}
                  <p className="p-0" style={{fontSize:'11px'}}>
                    <CurrencyRupeeIcon sx={{fontSize:'11px'}}/>
                    {data?.lifetime_budget
                      ? formatIndianNumber(Math.floor(data.lifetime_budget))
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    
    {/* <section className="d-none d-md-block">
    <div className="wallet-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >          
            <div className="Campaign" onClick={handleNavigateAdOverview}>            
              <span
            className=" d-flex align-items-center justify-content-center me-1"
            style={{
              height: "30px",
              width: "30px",
              backgroundColor: "#62c",
              borderRadius: "50%",
            }}
          >
            <span style={{ color: "white" }}>{data?.name?.slice(0, 1)}</span>
          </span>
              <div>
                <p
                  style={{
                    maxWidth: "10ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                
                >
                  {data.name.length>9?`${data?.name.slice(0, 12)}..`:data?.name}
                 
                </p>
               
                <h6 className="campaign-home-h6">#{data?.campaign_id} </h6>
              </div>
            </div>
           
            <div   className=" d-flex flex-column align-items-center" >
              <p className="status-live ">
               
                <span
                  style={{
                    overflow: "hidden",
                    maxWidth: "15ch",
                    textOverflow: "ellipsis",
                  }}
                >
                  {" "}
                  {data?.status}
                </span>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                width:'70px'
                }}
              >
                
                <img src="socialIcons/fbLogo.svg" alt="" />
                <img style={{width:'15px', height:'15px'}} src="socialIcons/instagram.png" alt="facebook" />
                <img src="socialIcons/googleLogo.svg" alt="" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <div className="left-kapil">
                <div>
                
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <rect
                      width="39.6547"
                      height="41.543"
                      rx="4.1543"
                      fill="#E2D6F3"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.0029 21.943C28.4258 22.9089 29.4228 24.2175 29.4228 25.9623V29.078H32.5385C33.1098 29.078 33.5771 28.6106 33.5771 28.0394V25.9623C33.5771 23.6982 29.8694 22.3584 27.0029 21.943Z"
                      fill="#6622CC"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.7646 21.943C10.3418 22.9089 9.34477 24.2175 9.34477 25.9623V29.078H6.22904C5.65782 29.078 5.19046 28.6106 5.19046 28.0394V25.9623C5.19046 23.6982 8.89818 22.3584 11.7646 21.943Z"
                      fill="#6622CC"
                    />
                    <path
                      d="M19.0352 20.7705C21.3295 20.7705 23.1895 18.9106 23.1895 16.6162C23.1895 14.3219 21.3295 12.4619 19.0352 12.4619C16.7408 12.4619 14.8809 14.3219 14.8809 16.6162C14.8809 18.9106 16.7408 20.7705 19.0352 20.7705Z"
                      fill="#6622CC"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24.2276 20.7705C26.5228 20.7705 28.3819 18.9115 28.3819 16.6162C28.3819 14.321 26.5228 12.4619 24.2276 12.4619C23.7394 12.4619 23.2825 12.5658 22.8463 12.7112C23.74 13.8164 24.2275 15.1948 24.2275 16.6162C24.2275 18.0376 23.74 19.416 22.8463 20.5213C23.2825 20.6667 23.7394 20.7705 24.2276 20.7705ZM17.9961 21.8091C15.2231 21.8091 9.6875 23.2008 9.6875 25.9634V28.0405C9.6875 28.6118 10.1549 29.0791 10.7261 29.0791H25.2661C25.8374 29.0791 26.3047 28.6118 26.3047 28.0405V25.9634C26.3047 23.2008 20.7691 21.8091 17.9961 21.8091Z"
                      fill="#6622CC"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.8418 20.7705C11.5465 20.7705 9.68747 18.9115 9.68747 16.6162C9.68747 14.321 11.5465 12.4619 13.8418 12.4619C14.3299 12.4619 14.7869 12.5658 15.2231 12.7112C14.3294 13.8164 13.8418 15.1948 13.8418 16.6162C13.8418 18.0376 14.3294 19.416 15.2231 20.5213C14.7869 20.6667 14.3299 20.7705 13.8418 20.7705ZM20.0732 21.8091C22.8462 21.8091 28.3818 23.2008 28.3818 25.9634V28.0405C28.3818 28.6118 27.9145 29.0791 27.3433 29.0791H12.8032C12.232 29.0791 11.7646 28.6118 11.7646 28.0405V25.9634C11.7646 23.2008 17.3002 21.8091 20.0732 21.8091Z"
                      fill="#6622CC"
                    />
                  </svg>
                </div>
                <Link to="/lead-management">
                  <div className="left-right">
                    <p>Leads</p>
                    <p>
                    
                      {data?.leads_count ? data?.leads_count : 0}
                      <span>+0</span>
                    </p>
                  </div>
                </Link>
              </div>
              <div className="left-kapil">
                <div>
                  <p className="d-flex justify-content-center align-items-center" style={{height:'38px', width:'38px', backgroundColor:'#E2D6F3', borderRadius:'4px', padding:'10px'}}>
                  <MessageIcon sx={{color:'#62c'}}/>
                  </p>
                 
                </div>
                <div className="left-right">
                  <p>Messages</p>
                  <p>
                    0 <span>+0</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="slider">
              <div style={{ width: 80, height: 80 }}>
                <CircularProgressbar
                  value={spendPercentage}
                  text={`${spendPercentage ? spendPercentage : 0}% `}
                  styles={{
                    path: {
                   
                      stroke: "#62c",
                      strokeLinecap: "butt",
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                      
                    },
                    trail: {
                     
                      stroke: "#8E8E8E6B",
                      strokeLinecap: "butt",
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },
                    text: {
                     
                      fill: "#A19fac",
                      fontSize: "16px",
                    },
                    background: {
                      fill: "#3e98c7",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="spend2">
               
                  <h6>
                    <div className="circle-type"></div> <span> Spent</span>
                  </h6>
                
                  <p className="p-0" style={{fontSize:'11px'}}> <CurrencyRupeeIcon sx={{fontSize:'11px'}}/> {data?.spend ? formatIndianNumber(Math.round( data?.spend)) : 0}</p>
                </div>
                <div className="spend2">
                 
                  <h6>
                    <div className="circle"></div> <span></span>
                    Deployed
                  </h6>
            
                  <p className="p-0" style={{fontSize:'11px'}}>
                    <CurrencyRupeeIcon sx={{fontSize:'11px'}}/>
                    {data?.lifetime_budget
                      ? formatIndianNumber(Math.floor(data.lifetime_budget))
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section> */}
    </>
  );
};

export default HomepageCarouselCard;
