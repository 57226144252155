// FacebookPixel.js
import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FacebookPixel = () => {
  useEffect(() => {
    const options = {
      autoConfig: true, 
      debug: false,
    };
    ReactPixel.init('1216736066151542', null, options);
    ReactPixel.pageView();

    // Optional: Track custom events
    // ReactPixel.track('CustomEventName', { param1: 'value1', param2: 'value2' });

    // Ensure page view is tracked on route changes
    const handleRouteChange = () => {
      ReactPixel.pageView();
    };

    // Listen for route changes (if using react-router)
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return null;
};

export default FacebookPixel;
