
const GoogleTabManagerWeb = () => {
    
    return (
        <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NDHN7XB3"
            height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
    );
};




export default GoogleTabManagerWeb;
