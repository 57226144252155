import { createContext, useState } from "react";

export const HomePageContext = createContext();

const HomePageProvider = ({ children }) => {
  const [campaignMgnPage, settCampaignMgnPage] = useState([]);
  const [leadMgnPage, setLeadMgnPage] = useState([]);
  const [allHomePageData, setallHomePageData] = useState([]);

  return (
    <>
      <HomePageContext.Provider
        value={{
          campaignMgnPage,
          settCampaignMgnPage,
          leadMgnPage,
          setLeadMgnPage,
          allHomePageData,
           setallHomePageData
        }}
      >
        {children}
      </HomePageContext.Provider>
    </>
  );
};

export default HomePageProvider;
