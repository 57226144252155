import React from 'react'
import impressionicon from '../../asets/webimages/home/impressionicon.png'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import LinkIcon from '@mui/icons-material/Link';
// import Tooltip from '@mui/material/Tooltip';
import totalleadicon from '../../asets/totallead icon.svg'
import { FaIndianRupeeSign } from "react-icons/fa6";
// //import viewwsicon from '../../asets/webimages/home/viewsicon.png'
// import IconButton from '@mui/material/IconButton';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import spenticon from '../../asets/webimages/home/spenticon.png'
import { useNavigate } from 'react-router-dom';
function ForWebImpression({ graphIpmression, resultSpend, totalleadsData, campid}) {
    const navigate = useNavigate()
//format in indian rupee
    function formatIndianNumber(number) {
        const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
        return formattedNumber;
      }
     
    
  return (
       <>
        <div>
        <div className='d-flex justify-content-start align-items-center' style={{marginTop:'20px'}}> 
            <div className='d-flex flex-column justify-content-center' style={{width:'89%', height:'82px', backgroundColor:'#F3EFF8', borderRadius:'11px'}}>
                <div>
                <p className='ps-2 m-0' style={{color:'#C4C4C4', fontSize:'16px', fontWeight:'500'}}>Impressions</p>
               
                </div>
                <div className='d-flex align-items-ceenter'>
                <img src={impressionicon} className='me-1'  alt="" />
                    <p className='p-0 m-0' style={{color:'black', fontSize:'18px', fontWeight:'700'}}>{graphIpmression?.impression?formatIndianNumber(Number(graphIpmression?.impression)):0}</p>
                </div>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center' style={{marginTop:'20px'}}> 
            <div className='d-flex flex-column justify-content-center p-2' style={{width:'89%', height:'82px', backgroundColor:'#F3EFF8', borderRadius:'11px'}}>
                <div>
                <p className='p-0 m-0' style={{color:'#C4C4C4', fontSize:'16px', fontWeight:'500'}}>Clicks(All)</p>
               
                </div>
                <div className='d-flex align-items-ceenter'>
                
                <div className=' d-flex justify-content-center align-items-center me-2' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
                <AdsClickIcon sx={{color:'#6622cc', fontSize:'15px'}}/>
                </div>
                    <p className='p-0 m-0' style={{color:'black', fontSize:'18px', fontWeight:'700'}}>{graphIpmression?.views?formatIndianNumber(Number(graphIpmression?.views)):0}</p>
                </div>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center' style={{marginTop:'20px'}}> 
            <div className='d-flex flex-column justify-content-center p-2' style={{width:'89%', height:'82px', backgroundColor:'#F3EFF8', borderRadius:'11px'}}>
                <div>
                <p className='p-0 m-0' style={{color:'#C4C4C4', fontSize:'16px', fontWeight:'500'}}>Link Clicks</p>
               
                </div>
                <div className='d-flex align-items-ceenter'>
                
                <div className=' d-flex justify-content-center align-items-center me-2' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
                <LinkIcon sx={{color:'#6622cc', fontSize:'20px'}}/>
                </div>
                    <p className='p-0 m-0' style={{color:'black', fontSize:'18px', fontWeight:'700'}}>{graphIpmression?.linkClicks?formatIndianNumber(Number(graphIpmression?.linkClicks)):0}</p>
                </div>
            </div>
        </div>

        <div className='d-flex flex-column justify-content-center' style={{marginTop:'20px'}}> 
            <div className='d-flex flex-column justify-content-center p-2' style={{width:'89%', height:'82px', backgroundColor:'#F3EFF8', borderRadius:'11px'}}>
                <div>
                <p className='p-0 m-0' style={{color:'#C4C4C4', fontSize:'16px', fontWeight:'500'}}>Spent</p>
                
                </div>
                <div className='d-flex align-items-ceenter'>
                {/* <img src={spenticon} className='me-1'  alt="" /> */}
                  <div className='d-flex align-items-center justify-content-center border' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
                  <p className='m-0 p-0' style={{fontSize:'15px', fontWeight:'600', color:'#6622cc'}} ><FaIndianRupeeSign style={{fontSize:'17px'}} /></p>

                  </div>
                    <p className='p-0 m-0' style={{color:'black', fontSize:'18px',  fontWeight:'700'}}>{graphIpmression.spend?formatIndianNumber(Math.round(graphIpmression?.spend)):0}</p>
                </div>
            </div>
        </div>


        <div className='d-flex flex-column justify-content-center position-relative' style={{marginTop:'20px'}}> 
       

            <div onClick={()=>{navigate(`/leads`, {state:campid })}} sx={{ color:'rgb(196, 196, 196)'}} className='d-flex flex-column justify-content-center p-2' style={{width:'89%', height:'82px', backgroundColor:'#F3EFF8', borderRadius:'11px', cursor:'pointer'}}>
                <div>
                <p className='p-0 m-0' style={{color:'#C4C4C4', fontSize:'16px', fontWeight:'500'}}>Total Leads
              
                </p>
                
                </div>
                <div className='d-flex align-items-ceenter'>
                <div className=' d-flex justify-content-center align-items-center me-2' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
                  <img width={25} height={25} src={totalleadicon} alt="" />
                </div>
                    <p className='p-0 m-0' style={{color:'black', fontSize:'18px',  fontWeight:'700'}}>{totalleadsData?formatIndianNumber(Math.round(totalleadsData)):0}</p>
                </div>
            </div>
        </div>


        </div>
      
       </>
  )
}

export default ForWebImpression