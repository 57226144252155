import React, { useContext, useEffect, useState } from 'react'
import './webleadmgn.css'
import { Link, useNavigate } from 'react-router-dom'

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Axios from '../../Axios'
import Papa from 'papaparse';
import SortIcon from '@mui/icons-material/Sort';
import DownloadIcon from '@mui/icons-material/Download';

import googlesheetdisable from '../../asets/googlesheet disable.png'
import { AuthContext } from '../../AuthProvider'
import { Box, Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import googlesheeticon from '../../asets/google sheets icon.png'

function WebLeadMgn() {

  const { userToken, allFbData } = useContext(AuthContext);
  const [draftUserData, setDraftUserData] = useState([]);

  const [filterCompleted, setFilterCompleted] = useState([])
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActive, setFilterActive] = useState([])
  const open = Boolean(anchorEl);
  const [allLeadsMgn, setAllLeadsMgn] = useState([])
  const [loadLeadData, setLoadLeadData] = useState([])

  const formatName = (name) => {

    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');

    trimmedName = trimmedName.replace(/_/g, ' ').trim();

    return trimmedName.replace(/\s+/g, ' ');
  };


  const CampMgnFilterData = (data) => {

    let campigncard = [];
    if (data) {

      data?.forEach((val) => {


        const obj1 = {
          campaign_id: val?.id,
          name: val?.name ? val?.name : '',
          lifetime_budget: val?.lifetime_budget ? Number(val?.lifetime_budget) : 0,
          status: val?.status,
          impressions: val?.insights?.data[0]?.impressions,
          spend: val?.insights?.data[0]?.spend ? Number(val?.insights?.data[0]?.spend) : 0,
          leads_count: 0,
          _id: val?.dbId,
          discount: val?.discount,
          date: val?.createdAt,
          sheet: val?.sheet ? val?.sheet : '',
          //  gsheetIdInt:val?.sheetIdInt?val?.sheetIdInt:''
        };


        val?.insights?.data?.forEach((val) => {

          obj1.leads_count =
            obj1?.leads_count +
            (parseInt(val?.actions?.length ? val?.actions?.find((action) => action?.action_type === 'lead')?.value || 0 : 0));

          obj1.impressions = obj1.impressions + (val?.impressions ? Number(val?.impressions) : 0);
          //spent
          obj1.spend = obj1.spend + (val?.spend ? Number(val?.spend) : 0);



        });


        if (obj1?.name) {
          campigncard?.push(obj1);
        }
      })
    }

    if (campigncard.length) {


      const filterdate = [...campigncard].sort((a, b) => new Date(b?.date) - new Date(a?.date))

      if (filterdate) {
        setAllLeadsMgn(filterdate)
        const flterCompleted = filterdate?.filter((val) => {
          return val.status === "STOPPED" || val.status === "COMPLETED"
        })
        if (flterCompleted) {
          setFilterCompleted(flterCompleted)
        }

        const flterCompleted2 = filterdate.filter((val) => {
          return val.status === "ACTIVE" || val.status === "PAUSED"
        })
        if (flterCompleted2) {
          setFilterActive(flterCompleted2)
        }
      }





    }

  }

  useEffect(() => {
    CampMgnFilterData(allFbData)
  }, [allFbData?.length])

  //for draft
  // const draftUserDataApi = async () => {
  //   Axios.get("/get-properties", {
  //     headers: {
  //       Authorization: userToken,
  //     },
  //   })
  //     .then((r) => {
  //       if (!r.data.valid) {
  //         console.log("api valid field is false");
  //       } else {
  //         const data = r.data.message;
  //         // console.log(data);
  //         setDraftUserData(data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  // useEffect(() => {
  //   draftUserDataApi();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);



  const SearchfilterData = () => {
    return allLeadsMgn.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };



  // sorting by leads , date, spent
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onFilterByLead = () => {
    //active
    const sortedData = [...allLeadsMgn].sort((a, b) => b.leads_count - a.leads_count);
    if (sortedData) {
      //  console.log("filterlead", sortedData)
      setAllLeadsMgn(sortedData)
    }




    setAnchorEl(null);
  }
  const onFilterBySpent = () => {
    const sortedData = [...allLeadsMgn].sort((a, b) => b.spend - a.spend);
    if (sortedData) {
      //   console.log("filterlead", sortedData)
      setAllLeadsMgn(sortedData)
    }


    setAnchorEl(null);
  }
  const onFilterByDate = () => {
    const filterdate = [...allLeadsMgn].sort((a, b) => new Date(b?.date) - new Date(a?.date))
    if (filterdate) {
      setAllLeadsMgn(filterdate)
    }


    setAnchorEl(null);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };


  // for cv download
  const handleDownloadCSV = async (id) => {
    setLoadLeadData(true)
    try {
      const res = await Axios.get("/get-all-leads", {
        headers: {
          Authorization: userToken
        },
        params: {
          campaign_id: id,
        }
      })

      if (res.status === 200) {
        const data = res?.data?.message;
        
        if (data) {

          const leadvalue = data?.all_leads?.flat()
          if (leadvalue) {

             //  console.log("leadsvalue", leadvalue)
            let leadsResult = [];

            leadvalue.forEach((val) => {
              let innerlead = {}

              val?.field_data?.forEach((data) => {
                innerlead[data?.name] = data?.values[0];
                innerlead['created_time'] = val?.created_time;
                innerlead['id'] = val?.id;
                innerlead['comment'] = val?.comment;
              });
              if (Object.keys(innerlead)?.length) {
                leadsResult.push(innerlead)
              }
            });

              if(leadsResult?.length>0){
                DownloadcsvMethod(leadsResult, id)
                 //  console.log("leadsresult", leadsResult)
              }
          }
        }

      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoadLeadData(false)
    }
  }

  //for csv
  const DownloadcsvMethod = async (leadsResult, id) => {
           
      // Get a set of all unique keys in the array
      const allKeys = Array.from(new Set(leadsResult?.flatMap(obj => Object.keys(obj))));

      // Update each object to include all keys with values or null
      const updatedArray = leadsResult?.map(obj => {
        const updatedObj = { ...obj };
        allKeys.forEach(key => {
          if (!obj.hasOwnProperty(key)) {
            updatedObj[key] = null;
          }
        });
        return updatedObj;
      });
      if (updatedArray?.length) {
        // console.log("transformedLeads", updatedArray)
        downloadCsvByPap(updatedArray, id)

      }  

  };


  const downloadCsvByPap = (data, id) => {

    const csv = Papa.unparse(data);
    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `#${id}.csv`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = (id) => {
    handleDownloadCSV(id);
  };

  function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }


  //console.log("allFbData", allFbData)

  return (
    <>
      <div className="container-fluid">
        <div className="row">

          <div className="col-9">
            {/* searchBox, fiter-sort btns */}
            <Box
              component={"div"}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <div className="searchBox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M8.48307 9.85618C8.43311 9.80622 8.34711 9.79937 8.28574 9.84431C8.28574 9.84431 8.29167 9.84089 8.24011 9.87716C7.3892 10.4755 6.35211 10.8269 5.2329 10.8269C2.34296 10.8269 0 8.48423 0 5.59423C0 2.70422 2.3429 0.361328 5.2329 0.361328C8.1229 0.361328 10.4656 2.70422 10.4656 5.59423C10.4656 6.70794 10.1177 7.74072 9.52431 8.58912C9.48552 8.64478 9.48187 8.64866 9.48187 8.64866C9.43898 8.70683 9.44651 8.79603 9.49488 8.8444L9.80787 9.15739C9.85783 9.20736 9.95798 9.23359 10.0232 9.21899C10.0232 9.21899 10.0166 9.21831 10.0764 9.20895C10.5242 9.13983 10.9978 9.27785 11.3428 9.62278L12.978 11.258C13.5529 11.8327 13.5529 12.7646 12.978 13.3393C12.4033 13.9142 11.4714 13.9142 10.8968 13.3393L9.26151 11.704C8.91817 11.3609 8.77992 10.89 8.84676 10.4443C8.85612 10.3823 8.8568 10.3893 8.8568 10.3893C8.87323 10.3177 8.84448 10.2176 8.79612 10.1692L8.48307 9.85618ZM5.23291 8.86469C7.03904 8.86469 8.5034 7.40033 8.5034 5.5942C8.5034 3.78784 7.03904 2.32372 5.23291 2.32372C3.42655 2.32372 1.96242 3.78784 1.96242 5.5942C1.96242 7.40033 3.42655 8.86469 5.23291 8.86469Z"
                    fill="#949494"
                  />
                </svg>
                <input type="text" placeholder="Search by campaign" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

              </div>

              {/* sorting by leads, date, spent */}
              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ margin: '0px', padding: '0px' }}
                  className="p-0 m-0"
                >
                  <SortIcon sx={{ color: '#6622cc', margin: '0', padding: '0' }} />
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={onFilterByDate}>By date</MenuItem>
                  <MenuItem onClick={onFilterByLead}>By leads</MenuItem>
                  <MenuItem onClick={onFilterBySpent}>By spend</MenuItem>
                </Menu>
              </div>


            </Box>
            <div className="leadtabl">
              <table className='table-web' style={{ width: '100%', }} >
                <tr className="table-bg  p-2">
                  <th> S. No.  </th>
                  <th> Campaign Name  </th>
                  <th> Leads  </th>
                  {/* <th> Not connected </th> */}
                  <th> Spent Amount  </th>
                  <th> Status  </th>
                  <th style={{ minWidth: '110px' }} className=''>&nbsp; Action  </th>
                  {/* <th> Export Leads </th> */}
                </tr>
                {(searchTerm ? SearchfilterData() : allLeadsMgn)?.map((val, idx) => (
                  <tr key={idx} className={idx % 2 !== 0 ? 'table-bg table-hover-web' : 'table-hover-web'} >
                    <td className='px-1 table-color ps-2' style={{ cursor: 'pointer' }} onClick={() => { val?.leads_count ? navigate(`/leads`, { state: val?.campaign_id }) : navigate("/lead-management") }}>{idx + 1}</td>
                    {/* <td style={{cursor:'pointer'}} onClick={()=>{navigate("/ad-overview", {state: { campaign_id: val?._id, cmpid:val?.campaign_id },})}} className='table-color'>{val?.name.slice(0, 25)}..</td> */}
                    <td style={{ cursor: 'pointer' }} onClick={() => { val?.leads_count ? navigate(`/leads`, { state: val?.campaign_id }) : navigate("/lead-management") }} className='table-color table-fs'>{val?.name?.length > 26 ? val?.name.slice(0, 24) + '..' : val?.name}</td>
                    <td onClick={() => { val?.leads_count ? navigate(`/leads`, { state: val?.campaign_id }) : navigate("/lead-management") }} style={{ cursor: 'pointer' }} className='table-color position-relative'><span >{val?.leads_count}</span>
                      {/* <span className='position-absolute' style={{color:'#0EAA00', fontSize:'12px', bottom:'26px', fontWeight:'700'}}>+0</span>  */}
                    </td>
                    {/* <td className='table-color-bold position-relative'><span>0</span><span className='position-absolute' style={{color:'#0EAA00', fontSize:'12px', bottom:'21px', fontWeight:'700'}}>+0</span></td> */}
                    <td onClick={() => { val?.leads_count ? navigate(`/leads`, { state: val?.campaign_id }) : navigate("/lead-management") }} style={{ cursor: 'pointer' }} className="table-color">< CurrencyRupeeIcon sx={{fontSize: {xs: '12px',sm: '14px', md: '14px',  lg: '14px' }, marginBottom:'2px'}} />{val?.spend ? formatIndianNumber(Math.round(Number(val?.spend))) : 0}</td>
                    <td className='' onClick={() => { val?.leads_count ? navigate(`/leads`, { state: val?.campaign_id }) : navigate("/lead-management") }} style={{ color: val?.status === 'ACTIVE' ? '#0EAA00' : val?.status === 'PAUSED' ? '#F07A4C' : '#6622CC', fontSize: '10px', fontWeight: '700', cursor: 'pointer' }}>{val?.status}</td>
                    <td style={{ minWidth: '110px', cursor: val?.leads_count ? 'pointer' : 'not-allowed' }} className=''>
                      {/* {val?.leads_count ? (<>
                        <Tooltip title="View Leads" placement='top' arrow>
                                  <IconButton onClick={()=>{navigate(`/leads`, {state:val?.campaign_id })}} sx={{color:'#6622CC'}} aria-label="visibility">
                                <VisibilityIcon/>
                                 </IconButton>
                                  </Tooltip>



                        &nbsp;
                        <Tooltip title="Download Leads" placement='top' sx={{ padding: '0' }} arrow>
                          <IconButton onClick={() => handleDownload(val?.campaign_id)} sx={{ color: '#6622CC', padding:'0px' }} aria-label="visibility">
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>


                      </>) : (<>

                                                          
                                  <IconButton sx={{color:'#6622CC'}} disabled aria-label="visibility">
                                <VisibilityIcon/>
                                  </IconButton> 
                         <Tooltip title="View Goole Sheet" placement='top' arrow>
                                   
                                   <IconButton disabled  aria-label="visibility">
                                     <img src={googlesheetdisable} width={22} height={22} alt="" />
                                 </IconButton>

                                  
                                  </Tooltip>


                        &nbsp;
                        <IconButton disabled sx={{ color: '#6622CC', cursor: 'not-allowed', padding:'0px' }} aria-label="visibility">
                          <DownloadIcon />
                        </IconButton>


                      </>)} */}



                      {(val?.sheet?.url) ? (<>
                        <Tooltip title="View in Google Sheet" placement='top' arrow>
                          <a target='_blank' rel="noreferrer" href={val?.sheet?.url}>
                            <IconButton sx={{ color: '#6622CC', padding:'4px' }} aria-label="visibility">
                              <img src={googlesheeticon} width={22} height={22} alt="" />
                            </IconButton>
                          </a>

                        </Tooltip>
                      </>) : (<>
                        <Tooltip title="View in Google Sheet" placement='top' arrow>

                          <IconButton disabled aria-label="visibility" sx={{padding:'4px'}}>
                            <img src={googlesheetdisable} width={22} height={22} alt="" />
                          </IconButton>


                        </Tooltip>
                      </>)}
                    </td>
                    {/* <td  className='px-1 table-color'>
                                <Button
                                  onClick={() => handleDownload(val?.campaign_id)}
                                  disabled={val?.leads_count?false:true}
                                  sx={{
                                    backgroundColor: "#62c",
                                    fontSize: "10px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    padding: "6px 2px",
                                    textTransform:'capitalize'
                                   
                                  }}
                                  variant="contained"
                                >
                                 
                                 <DownloadIcon/>
                                </Button>


                                </td> */}
                  </tr>
                ))}

              </table>
            </div>
          </div>
          <div className="col-3">
            {/* {draftUserData?.length?(<>
                 
                <div className='d-flex justify-content-between'>
                <p style={{fontSize:'18px', fontWeight:'500'}}>Draft</p>
                <Link style={{fontSize:'12px', fontWeight:'700', color:'rgb(102, 34, 204)'}}>View All</Link>
                </div>
              
            <div className="row">
                  {draftUserData?.map((val, idx)=>(
                    <div key={idx+1} className="col-12 mb-3">
                <DraftCard data={val} handleDeleteDraft={handleDeleteDraft}/>
                </div>
                  ))}
                
               </div>
               </>): <p className='text-center'></p> } */}
          </div>
        </div>
      </div>

    </>
  )
}

export default WebLeadMgn