/* eslint-disable react-hooks/exhaustive-deps */
// import { ArrowBack } from "@mui/icons-material";
import Navbar2 from "../../navbar/Navbar2";
import "./leadMgmtPage.css";
import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import ExploreLeadCard from "../../components/Cards/ExploreLeadCard";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import { useContext, useEffect, useState } from "react";
import Papa from 'papaparse';
import { AuthContext } from "../../AuthProvider";
import SortIcon from '@mui/icons-material/Sort';
import Axios from "../../Axios";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import { HomePageContext } from "../../HomepageProvider";

// navigation tabs value
const tabs = ["All", "Active", "Completed"];
export default function LeadMgmtPage() {
// const {leadMgnPage } = useContext(HomePageContext)
const [searchTerm, setSearchTerm] = useState('');
  const [completeLeads, setCompleteLeads] = useState([]);
  const { userToken, fbToken, allFbData } = useContext(AuthContext);
  const [chosenNavigation, setChosenNavigation] = useState("All");
  const [activeleads, setActiveLeads] = useState([])
  const [allLead, setAllLead] = useState([])
   const [loadLeadData, setLoadLeadData] = useState(false)
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] =useState(null);
  const open = Boolean(anchorEl);

  const formatName = (name) => {
   
    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');
   
    trimmedName = trimmedName.replace(/_/g, ' ').trim();
    
    return trimmedName.replace(/\s+/g, ' ');
  };

  const filterForLead = (data)=>{
    let campigncard = [];

        if(data){          
         data?.forEach((val) => {
         // console.log("val", val);
        

      const obj1 = {
        campaign_id: val?.id,
        name: val?.name?val?.name:'',
        lifetime_budget: val?.lifetime_budget,
        status: val?.status,
        impressions: 0,
        spend: 0,
        leads_count: 0,
        _id:val?.dbId,
        date:val?.createdAt,
        sheet: val?.sheet ? val?.sheet : '',
      };


      val?.insights?.data?.forEach((val) => {

        obj1.leads_count =
         obj1?.leads_count +
         (parseInt(val?.actions?.length ? val?.actions?.find((action)=>action?.action_type==='lead')?.value || 0 : 0));
    
         obj1.impressions =  obj1.impressions + (val?.impressions?Number(val?.impressions): 0 );
         //spent
         obj1.spend = obj1.spend + (val?.spend?Number(val?.spend):0  );

        
        
     });





      if (obj1?.name) {
        campigncard?.push(obj1);
      }
         })

   
   }
      if(campigncard?.length){
       
          const leadsdata =  campigncard.filter((val)=>val.status === "ACTIVE" || val.status === "UNDER REVIEW" || val.status === "PAUSED")
               if(leadsdata){
                 setActiveLeads(leadsdata?.reverse())
              //   console.log("leaddaaa",leadsdata)
               }
               const leadsData2 =  campigncard.filter((val)=>{
         
                        return val?.status === "COMPLETED" || val?.status === "STOPPED"
               })
                  if(leadsData2){
                     // console.log("lead2", leadsData2)
                      setCompleteLeads(leadsData2)
                  }
                  if(leadsdata || leadsData2){
                    const  allleaddata = leadsdata.concat(leadsData2)
                          if(allleaddata.length){
                            setAllLead(allleaddata)
                          }
                 
         }
      }
  }
useEffect(()=>{
  filterForLead(allFbData)
},[allFbData.length])



  // for cv download
  const handleDownloadCSV = async (id) => {
    setLoadLeadData(true)
    try {
      const res = await Axios.get("/get-all-leads", {
        headers: {
          Authorization: userToken
        },
        params: {
          campaign_id: id,
        }
      })

      if (res.status === 200) {
        const data = res?.data?.message;
        
        if (data) {

          const leadvalue = data?.all_leads?.flat()
          if (leadvalue) {

             //  console.log("leadsvalue", leadvalue)
            let leadsResult = [];

            leadvalue.forEach((val) => {
              let innerlead = {}

              val?.field_data?.forEach((data) => {
                innerlead[data?.name] = data?.values[0];
                innerlead['created_time'] = val?.created_time;
                innerlead['id'] = val?.id;
                innerlead['comment'] = val?.comment;
              });
              if (Object.keys(innerlead)?.length) {
                leadsResult.push(innerlead)
              }
            });

              if(leadsResult?.length>0){
                DownloadcsvMethod(leadsResult, id)
                 //  console.log("leadsresult", leadsResult)
              }
          }
        }

      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoadLeadData(false)
    }
  }

  //for csv
  const DownloadcsvMethod = async (leadsResult, id) => {
           
      // Get a set of all unique keys in the array
      const allKeys = Array.from(new Set(leadsResult?.flatMap(obj => Object.keys(obj))));

      // Update each object to include all keys with values or null
      const updatedArray = leadsResult?.map(obj => {
        const updatedObj = { ...obj };
        allKeys.forEach(key => {
          if (!obj.hasOwnProperty(key)) {
            updatedObj[key] = null;
          }
        });
        return updatedObj;
      });
      if (updatedArray?.length) {
        // console.log("transformedLeads", updatedArray)
        downloadCsvByPap(updatedArray, id)

      }  

  };


  const downloadCsvByPap = (data, id) => {

    const csv = Papa.unparse(data);
    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `#${id}.csv`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = (id) => {
    handleDownloadCSV(id);
  };

 
  // function handleCompleteLeadsApi() {
  //   const urlToken =
  //     "EAAO9e3ZAvFL4BOZCzH1uXdpskxdEUNElIdagiEdlMxIzMry1jyo0V1ITwKUCciBgyZCzXHxh8yNxUCriZAjFKVHvqC764fqaJXx3VicRFQK4ZCxgc5jwXCfKC8ZCfOsRZAJN6ceWTzZB6LVAuZClLZCOES7bHiZBDCpjtHAFk0jqwgRzrNDqCOPVeAM6QwE";

  //   Axios.get(`error/get-campaign-data?access_token=${urlToken}`, {
  //     headers: {
  //       Authorization: userToken,
  //     },
  //   })
  //     .then((r) => {
  //       if (!r.data.valid) {
  //         console.log("Api valid field is false");
  //         return;
  //       } else {
  //         const data = r?.data?.message;
  //         setCompleteLeads(data);
  //       }
  //     })
  //     .catch((err) => {
  //      // alert(`API Fetch Error - CompleteLeads: ${err?.message}`);
  //      // console.log(`API Fetch Error - (CompleteLeads): ${err?.message}`);
  //     });
  // }
  // effect for completeData
  // useEffect(() => {
  //   handleCompleteLeadsApi();
  // }, []);

 

  const SearchfilterData = () => {
    return activeleads?.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const SearchfilterAllData = () => {
    return allLead?.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  
  const SearchfilterCompleted = () => {
    return completeLeads?.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // sorting by leads , date, spent
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onFilterByLead = () =>{
//active
    const sortedData = [...activeleads].sort((a, b) => b.leads_count - a.leads_count);
       if(sortedData){
        //console.log("filterlead", sortedData)
        setActiveLeads(sortedData )
       }

       //all
       const sortedAllData = [...allLead].sort((a, b) => b.leads_count - a.leads_count);
       if(sortedAllData){
       // console.log("filterlead", sortedData)
        setAllLead(sortedAllData )
       }
       //completed
       const sortedCompletedData = [...completeLeads].sort((a, b) => b.leads_count - a.leads_count);
       if(sortedCompletedData){
      //  console.log("filterlead", sortedData)
        setCompleteLeads(sortedCompletedData )
       }
    setAnchorEl(null);
  }

  const onFilterByDate = () =>{
      //active
       const filterdate = [...activeleads].sort((a, b)=> new Date(b?.date) - new Date(a?.date))
         if(filterdate){
          setActiveLeads(filterdate)
         }
       //all
       const filterdateall = [...allLead].sort((a, b)=> new Date(b?.date) - new Date(a?.date))
       if(filterdateall){
        setAllLead(filterdateall)
       }
       //completed
       const filterdateCompltted = [...completeLeads].sort((a, b)=> new Date(b?.date) - new Date(a?.date))
       if(filterdateCompltted){
        setCompleteLeads(filterdateCompltted)
       }
    setAnchorEl(null);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className="leadMgmtPage">
        <Navbar2 menu={true} />
        <Box component={"div"} sx={{ paddingInline: "10px" }}>
          <div className="previous">
            <span
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </span>
            <h1>Lead Management</h1>
          </div>
          {/* searchBox, fiter-sort btns */}
          <Box
            component={"div"}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <div className="searchBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M8.48307 9.85618C8.43311 9.80622 8.34711 9.79937 8.28574 9.84431C8.28574 9.84431 8.29167 9.84089 8.24011 9.87716C7.3892 10.4755 6.35211 10.8269 5.2329 10.8269C2.34296 10.8269 0 8.48423 0 5.59423C0 2.70422 2.3429 0.361328 5.2329 0.361328C8.1229 0.361328 10.4656 2.70422 10.4656 5.59423C10.4656 6.70794 10.1177 7.74072 9.52431 8.58912C9.48552 8.64478 9.48187 8.64866 9.48187 8.64866C9.43898 8.70683 9.44651 8.79603 9.49488 8.8444L9.80787 9.15739C9.85783 9.20736 9.95798 9.23359 10.0232 9.21899C10.0232 9.21899 10.0166 9.21831 10.0764 9.20895C10.5242 9.13983 10.9978 9.27785 11.3428 9.62278L12.978 11.258C13.5529 11.8327 13.5529 12.7646 12.978 13.3393C12.4033 13.9142 11.4714 13.9142 10.8968 13.3393L9.26151 11.704C8.91817 11.3609 8.77992 10.89 8.84676 10.4443C8.85612 10.3823 8.8568 10.3893 8.8568 10.3893C8.87323 10.3177 8.84448 10.2176 8.79612 10.1692L8.48307 9.85618ZM5.23291 8.86469C7.03904 8.86469 8.5034 7.40033 8.5034 5.5942C8.5034 3.78784 7.03904 2.32372 5.23291 2.32372C3.42655 2.32372 1.96242 3.78784 1.96242 5.5942C1.96242 7.40033 3.42655 8.86469 5.23291 8.86469Z"
                  fill="#949494"
                />
              </svg>
              <input type="text" placeholder=" Search campaign" onChange={(e)=>setSearchTerm(e.target.value)}></input>
            </div>

            <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{margin:'0px', padding:'0px'}}
        className="p-0 m-0"
      >
         <SortIcon sx={{color:'#6622cc', margin:'0', padding:'0'}}/>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onFilterByDate}>By date</MenuItem>
        <MenuItem onClick={onFilterByLead}>By leads</MenuItem>
      </Menu>
    </div>
           
          </Box>
          {/* navigation tabs */}
          <div>
            <nav className="tabBtns">
              {tabs.map((tabValue) => (
                <button
                  key={tabValue}
                  value={tabValue}
                  className={
                    "tabBtn " +
                    (tabValue === chosenNavigation ? "tabBtnSelected" : "")
                  }
                  onClick={(e) => setChosenNavigation(e.target.value)}
                >
                  {tabValue}
                </button>
              ))}
            </nav>
          </div>

          {/* cards */}
          <Stack direction={"column"} spacing={2} marginBottom={"200px"}>
            {chosenNavigation === "Completed" &&
           completeLeads?.length>0 && (searchTerm?SearchfilterCompleted():completeLeads)?.map((lead, index) => (
                <ExploreLeadCard
                  key={index}
                  data={lead}
                  handleDownload={handleDownload}
                />
              ))}
            {chosenNavigation === "Active" &&
             activeleads?.length && (searchTerm?SearchfilterData():activeleads)?.map((lead, index) => (
                <ExploreLeadCard
                  data={lead}
                  key={index}
                  handleDownload={handleDownload}
                />
              ))}
            {chosenNavigation === "All" &&
              (searchTerm?SearchfilterAllData():allLead)?.map((lead) => (
                <ExploreLeadCard
                  key={lead?.campaign_id}
                  data={lead}
                  handleDownload={handleDownload}
                />
              ))}
           
          </Stack>
        </Box>
      </div>
      <BottomNavbar isPressed={true} whichOnePressed={3} />
    </>
  );
}
