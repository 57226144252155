import React from 'react'
import './landingpage-component.css';
// import footerbrandlogog from '../../asetes/Group 1171277298.svg'
import footerbrandlogog from '../../asets/asetes/Group 1171277298.svg'
import logo1 from '../../asets/asetes/Vector.svg'
// import logo1 from '../../asetes/Vector.svg';
import logo2 from '../../asets/asetes/Vector (1).svg'
import socialicon2 from '../../asets/asetes/LinkedIn.svg';
import socialicon3 from '../../asets/asetes/Social (1).svg';
import socialicon4 from '../../asets/asetes/bird.svg';
import facebook from '../../asets/asetes/Social (1).png'
import instagram from '../../asets/asetes/Vector (3).png'


function LandingPageFooter() {
  return (
      <div className="container-fluid lp-footer-container pb-0">
        <div className="container lp-footer-containe-inner position-relative" style={{borderTop:'1px solid rgba(58, 58, 58, 1)', borderBottom:'1px solid rgba(58, 58, 58, 1)'}}>
            <div className="row p-0 m-0 pb-1 pb-md-0">
                <div className="col-12 col-md-5  m-0">
                    <div className="row p-0 m-0">
                        <div className="col-10 p-0 m-0">
                        <div>
                        <div className='p-0 m-0'>
                            <img width={172} height={67} src={footerbrandlogog} alt="" className=" brand-logo-leapx p-0 m-0" />
                        </div>
                        <p className='lp-footer-p-right py-2'>
                        {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen. */}
                        LeapX is a GenAI & Data Science powered platform which simplifies & automates the creation & launch of digital ads to drive high ROI for Small medium businesses within minutes
                        </p>
                     </div>
                        </div>
                        <div className="col-2"></div>
                    </div>
                     

                </div>
                <div className="col-12 col-md-3  m-0">
                      <div className='p-0 '>
                        <p className='p-0 m-0 lp-footer-socialtext-h'>Follow us on social media</p>
                        <div className='py-2'>
                        <a target='_blank' href="https://www.facebook.com/leapxai" ><img src={facebook} className='me-2 opacity-foote-social'  alt="" /></a>
                            <a target='_blank' href="https://www.instagram.com/leapxai/" ><img src={instagram} className='me-2 opacity-foote-social' alt="" /></a>
                            <a target='_blank' href="https://www.linkedin.com/company/theleapx/"><img src={socialicon2} className='me-2 opacity-foote-social' alt="" /></a>
                           <a target='_blank' href="https://www.youtube.com/@leapx_ai"><img src={socialicon3} className='me-2 opacity-foote-social' alt="" /></a> 
                            <a target='_blank' href="https://twitter.com/leapx_ai"><img src={socialicon4} className='me-2 opacity-foote-social' alt="" /></a>
                        </div>
                      </div>

                </div>
                <div className="col-12 mb-[16px] sm:mb-[16px]  mb-md-0  col-md-4 d-flex justify-content-start justify-content-md-end  pt-4 pt-md-0  ">
                    <div className=' m-0'>
                        <p className='lp-footer-right-h p-0 m-0 mb-1'>Quick Link</p>
                        <p className='p-0 m-0 mt-1 footer-privacy-term-of-use-text'><a className='p-0 m-0 lp-footer-p-right' target='_blank' style={{textDecoration:'none', color:'rgba(255, 255, 255, 0.55)'}} href="https://www.leapx.ai/terms-of-use">Terms of Use</a></p>
                        <p className= 'p-0 m-0 mt-1 footer-privacy-term-of-use-text'><a className='p-0 m-0 lp-footer-p-right' target='_blank' style={{color:'rgba(255, 255, 255, 0.55)', textDecoration:'none'}} href="https://www.leapx.ai/refundpolicy">Refund Policy</a> </p>
                   <p className='p-0 m-0 mt-1 footer-privacy-term-of-use-text'><a className='p-0 m-0 lp-footer-p-right' target='_blank' style={{color:'rgba(255, 255, 255, 0.55)', textDecoration:'none'}} href="https://www.leapx.ai/privacy-policy">Privacy Policy</a></p>
                       
                        {/* <p className='lp-footer-right-p'>613-614, Ocus Quantum, Sector 51, Gurugram, Haryana, 122018</p>
                        <div>
                           <div className='mb-3 '>
                           <img src={logo1}  alt="" style={{opacity:'0.5'}} /> &nbsp;<span style={{color:'rgba(255, 255, 255, .55)'}}>hi@leapx.ai</span>
                            </div> 
                            <div className=''>
                            <img src={logo2} alt="" style={{opacity:'0.5'}} />&nbsp; <span style={{color:'rgba(255, 255, 255, .55)'}}>+91-7042413012</span>
                            </div>

                           
                        </div> */}
                    </div>
                </div>
                
            </div>
            <div className="footer-bottom-section   py-3" style={{borderTop:'1px solid rgba(58, 58, 58, 1)'}}>
               <p className=' text-start text-md-center footer-privacy-term-of-use-text' style={{color:'rgba(255, 255, 255, 0.55)'}}>© TerraTrends Technologies Pvt Ltd 2024</p>
                {/* <div><p className='p-0 m-0 footer-privacy-term-of-use-text'><a target='_blank' style={{textDecoration:'none', color:'white'}} href="https://www.leapx.ai/terms-of-use">Terms of Use</a></p></div>
                <div className='d-flex justify-content-around align-items-center gap-3'>
                    <div><p className= 'p-0 m-0 footer-privacy-term-of-use-text'><a target='_blank' style={{color:'white', textDecoration:'none'}} href="https://www.leapx.ai/refundpolicy">Refund Policy</a> </p></div>
                    <div><p className='p-0 m-0 footer-privacy-term-of-use-text'><a target='_blank' style={{color:'white', textDecoration:'none'}} href="https://www.leapx.ai/privacy-policy">Privacy Policy</a></p></div>

                </div> */}
            </div>
           
        </div>
      </div>
  )
}

export default LandingPageFooter