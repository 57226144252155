import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import Navbar2 from "../../navbar/Navbar2";
import "./CampignMgmtPage.css";
import { ArrowBack } from "@mui/icons-material";
import DraftCard from "../../components/Cards/DraftCard";
import CampignCompletedCard from "../../components/Cards/CampignCompletedCard";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ViewLeadsCard from "../../components/Cards/ViewLeadsCard";
import SortIcon from '@mui/icons-material/Sort';
// import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import Axios from "../../Axios";
import { AuthContext } from "../../AuthProvider";
import { HomePageContext } from "../../HomepageProvider";
const tabs = ["All", "Active", "Completed"];
// const tabs = ["Active", "Completed", "Draft"];


export default function CampignMgmtPage({ text, filterAdOverOtherCamp }) {
  const { campaignMgnPage } = useContext(HomePageContext)
  const [choseTab, setChoseTab] = useState("Active");
  const { userToken, fbToken, allFbData } = useContext(AuthContext);
  const [draftUserData, setDraftUserData] = useState([]);
  const [filterCompleted, setFilterCompleted] = useState([])
  const navigate = useNavigate()
  const [allCamapignData, setAllCamapignData] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActive, setFilterActive] = useState([])
  const [filteredDraftUserData, setFilteredDraftUserData] = useState([]);
  const [filteredCompleteAndLiveData, setFilteredCompleteAndLiveCardData] = useState([]);
  const [underRiview, setUnderRiview] = useState([])
  const [activeUnderReviewData, setActiveUnderReviewData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const formatName = (name) => {

    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');

    trimmedName = trimmedName.replace(/_/g, ' ').trim();

    return trimmedName.replace(/\s+/g, ' ');
  };

  

  const CampMgnFilterData = (data) => {

    let campigncard = [];
    if (data) {



      data?.forEach((val) => {

       
        const obj1 = {
          campaign_id: val?.id,
          name: val?.name ? val?.name : '',
          lifetime_budget: val?.lifetime_budget ? (Number(val?.lifetime_budget)) : 0,
          status: val?.status,
          impressions: 0,
          spend: 0,
          leads_count: 0,
          _id: val?.dbId,
          discount: val?.discount,
          date: val?.createdAt,
        };


        val?.insights?.data?.forEach((val) => {

          obj1.leads_count =
            obj1?.leads_count +
            (parseInt(val?.actions?.length ? val?.actions?.find((action) => action?.action_type === 'lead')?.value || 0 : 0));

          obj1.impressions = obj1.impressions + (val?.impressions ? Number(val?.impressions) : 0);
          //spent
          obj1.spend = obj1.spend + (val?.spend ? Number(val?.spend) : 0);

          //cpm
          obj1.cpm = obj1.cpm + (val?.cpm ? Number(val?.cpm) : 0)
          //cpc
          obj1.cpc = obj1.cpc + (val?.cpc ? Number(val?.cpc) : 0)
          //ctr
          obj1.cpc = obj1.ctr + (val?.ctr ? Number(val?.ctr) : 0)

        });

       


        if (obj1?.name) {

          campigncard?.push(obj1);
        }
      })
    }

    if (campigncard.length) {

      setAllCamapignData(campigncard?.reverse())


    }
    if (campigncard.length) {
      const flterCompleted = campigncard?.reverse()?.filter((val) => {
        return val.status === "STOPPED" || val.status === "COMPLETED"
      })
      if (flterCompleted) {
        setFilterCompleted(flterCompleted)
      }

      const flterCompleted2 = campigncard?.reverse()?.filter((val) => {
        return val.status === "ACTIVE" || val.status === "PAUSED" || val.status === "UNDER REVIEW"
      })
      if (flterCompleted2) {
        setFilterActive(flterCompleted2)
      }


    }


  }

  useEffect(() => {
    CampMgnFilterData(allFbData)
  }, [allFbData?.length])

  //for draft
  const draftUserDataApi = async () => {
    Axios.get("/get-properties", {
      headers: {
        Authorization: userToken,
      },
    })
      .then((r) => {
        if (!r.data.valid) {
          console.log("api valid field is false");
        } else {
          const data = r.data.message;
          // console.log(data);
          setDraftUserData(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    draftUserDataApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [completeAndLiveData, setCompleteAndLiveData] = useState([]);

  const handleUnderRiview = async () => {
    try {
      const res = await Axios.get("/get-under-review", {
        headers: {
          Authorization: userToken
        }
      })
      if (res.status === 200) {
        let underreview = []
        const data = res?.data?.message

        data.forEach((val) => {

          const obj1 = {
            campaign_id: val?.campaign_id,
            name: val?.name,
            lifetime_budget: Number(val.lifetime_budget),
            status: val?.status,
            impressions: 0,
            spend: 0,
            leads_count: 0,
            _id: val?._id,
            discount: Number(val?.discount) ? Number(val?.discount) : 30

          };
          if (obj1) {
            underreview.unshift(obj1)
          }
        })
        if (underreview.length > 0) {
          setUnderRiview(underreview)
        }


      }
    } catch (error) {

    }
  }

  useEffect(() => {
    handleUnderRiview()
  }, [])


  // delete draftcard
  const handleDeleteDraft = async (id) => {
    try {
      const res = await Axios.post("/delete-draft-campaign", { campaign_id: id }, {
        headers: {
          Authorization: userToken
        }
      })
      if (res.status === 200) {
        if (draftUserData.length) {
          const draftResult = draftUserData?.filter((val) => val?._id !== id)
          if (draftResult?.length > 0) {
            setDraftUserData(draftResult)
          }
        }

      }
    } catch (error) {
      console.log(error?.message)
    }
  }


  useEffect(() => {
    const activeUnderReviewcamp = filterActive.concat(underRiview)
    if (activeUnderReviewcamp) {
      setActiveUnderReviewData(activeUnderReviewcamp)
    }

  }, [filterActive?.length, underRiview?.length])

  const SearchfilterData = () => {
    return filterActive.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };


  const SearchfilterDataCompleted = () => {
    return filterCompleted?.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const SearchfilterAllData = () => {
    return allCamapignData?.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const SearchfilterDataDraft = () => {
    return draftUserData?.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };



  // sorting by leads , date, spent
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onFilterByLead = () => {
    //active
    const sortedData = [...filterActive].sort((a, b) => b.leads_count - a.leads_count);
    if (sortedData) {
      //  console.log("filterlead", sortedData)
      setFilterActive(sortedData)
    }
    //completed
    const sortedDataCompleted = [...filterCompleted].sort((a, b) => b.leads_count - a.leads_count);
    if (sortedDataCompleted) {
      // console.log("filterlead", sortedData)
      setFilterCompleted(sortedDataCompleted)
    }

    const sortedAllData = [...allCamapignData].sort((a, b) => b.leads_count - a.leads_count);
    if (sortedAllData) {
      // console.log("filterlead", sortedData)
      setAllCamapignData(sortedAllData)
    }



    setAnchorEl(null);
  }
  const onFilterBySpent = () => {
    const sortedData = [...filterActive].sort((a, b) => b.spend - a.spend);
    if (sortedData) {
      //   console.log("filterlead", sortedData)
      setFilterActive(sortedData)
    }
    //active
    const sortedDataComplted = [...filterCompleted].sort((a, b) => b.spend - a.spend);
    if (sortedDataComplted) {
      //  console.log("filterlead", sortedData)
      setFilterCompleted(sortedDataComplted)
    }
    const sortedAllData = [...allCamapignData].sort((a, b) => b.spend - a.spend);
    if (sortedAllData) {
      // console.log("filterlead", sortedData)
      setAllCamapignData(sortedAllData)
    }


    setAnchorEl(null);
  }
  const onFilterByDate = () => {
    const filterdate = [...filterActive].sort((a, b) => new Date(b?.date) - new Date(a?.date))
    if (filterdate) {
      setFilterActive(filterdate)
    }
    //completed
    const filterdateComplted = [...filterCompleted].sort((a, b) => new Date(b?.date) - new Date(a?.date))
    if (filterdateComplted) {
      setFilterCompleted(filterdateComplted)
    }

    const filterdateall = [...allCamapignData].sort((a, b) => new Date(b?.date) - new Date(a?.date))
    if (filterdateall) {
      setAllCamapignData(filterdateall)
    }

    //draft
    const filterdateDraft = [...draftUserData].sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
    if (filterdateDraft) {
      setDraftUserData(filterdateDraft)
    }

    setAnchorEl(null);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <>
      <div className="campignMgmtPage">
        {/* <Toast ref={toastRef} /> */}
        <div className="d-md-none">
          <Navbar2 menu={"true"} />
        </div>
        <Box component={"div"} sx={{ paddingInline: "10px" }}>
          <div className="previous">
            <span className="d-md-none" onClick={() => navigate(-1)}>
              <ArrowBack />
            </span>
            <h1>{text ? text : 'Campaign Management'}</h1>
          </div>
          {/* searchBox, fiter-sort btns */}
          <Box
            component={"div"}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <div className="searchBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M8.48307 9.85618C8.43311 9.80622 8.34711 9.79937 8.28574 9.84431C8.28574 9.84431 8.29167 9.84089 8.24011 9.87716C7.3892 10.4755 6.35211 10.8269 5.2329 10.8269C2.34296 10.8269 0 8.48423 0 5.59423C0 2.70422 2.3429 0.361328 5.2329 0.361328C8.1229 0.361328 10.4656 2.70422 10.4656 5.59423C10.4656 6.70794 10.1177 7.74072 9.52431 8.58912C9.48552 8.64478 9.48187 8.64866 9.48187 8.64866C9.43898 8.70683 9.44651 8.79603 9.49488 8.8444L9.80787 9.15739C9.85783 9.20736 9.95798 9.23359 10.0232 9.21899C10.0232 9.21899 10.0166 9.21831 10.0764 9.20895C10.5242 9.13983 10.9978 9.27785 11.3428 9.62278L12.978 11.258C13.5529 11.8327 13.5529 12.7646 12.978 13.3393C12.4033 13.9142 11.4714 13.9142 10.8968 13.3393L9.26151 11.704C8.91817 11.3609 8.77992 10.89 8.84676 10.4443C8.85612 10.3823 8.8568 10.3893 8.8568 10.3893C8.87323 10.3177 8.84448 10.2176 8.79612 10.1692L8.48307 9.85618ZM5.23291 8.86469C7.03904 8.86469 8.5034 7.40033 8.5034 5.5942C8.5034 3.78784 7.03904 2.32372 5.23291 2.32372C3.42655 2.32372 1.96242 3.78784 1.96242 5.5942C1.96242 7.40033 3.42655 8.86469 5.23291 8.86469Z"
                  fill="#949494"
                />
              </svg>
              <input type="text" placeholder="Search Campaign" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

            </div>

            {/* sorting by leads, date, spent */}
            <div>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ margin: '0px', padding: '0px' }}
                className="p-0 m-0"
              >
                <SortIcon sx={{ color: '#6622cc', margin: '0', padding: '0' }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={onFilterByDate}>By date</MenuItem>
                <MenuItem onClick={onFilterByLead}>By leads</MenuItem>
                <MenuItem onClick={onFilterBySpent}>By spend</MenuItem>
              </Menu>
            </div>



          </Box>
          {/* navigation tabs */}
          <div>
            <nav className="tabBtns">
              {tabs.map((tabValue) => (
                <button
                  key={tabValue}
                  value={tabValue}
                  className={
                    "tabBtn " + (tabValue === choseTab ? "tabBtnSelected" : "")
                  }
                  onClick={(e) => setChoseTab(e.target.value)}
                >
                  {tabValue}
                </button>
              ))}
            </nav>
          </div>
          {/* cards */}

          <Stack direction={"column"} marginBottom={"150px"} spacing={2}>
            {filteredCompleteAndLiveData !== null ||
              filteredDraftUserData !== null ? (
              filteredCompleteAndLiveData.length > 0 ||
                filteredDraftUserData.length > 0 ? (

                <SelectCard
                  chosenTab={choseTab}
                  completeAndLiveData={searchTerm ? SearchfilterData() : filterActive}
                  draftUserData={searchTerm ? SearchfilterDataDraft() : draftUserData}
                  completeData={searchTerm ? SearchfilterDataCompleted() : filterCompleted}
                  handleDeleteDraft={handleDeleteDraft}
                  allCamapignData={searchTerm ? SearchfilterAllData() : allCamapignData}
                />
              ) : (
                // <p></p>
                // /*
                <SelectCard
                  chosenTab={choseTab}
                  completeAndLiveData={searchTerm ? SearchfilterData() : filterActive}
                  draftUserData={searchTerm ? SearchfilterDataDraft() : draftUserData}
                  completeData={searchTerm ? SearchfilterDataCompleted() : filterCompleted}
                  handleDeleteDraft={handleDeleteDraft}
                  allCamapignData={searchTerm ? SearchfilterAllData() : allCamapignData}
                />
                // */
              )
            ) : (
              <p></p>
            )}
          </Stack>
        </Box>
      </div>
      <div className="d-md-none">
        <BottomNavbar isPressed={true} whichOnePressed={4} />
      </div>
    </>
  );
}
const SelectCard = ({
  chosenTab,
  draftUserData,

  completeAndLiveData,
  completeData,
  handleDeleteDraft,
  allCamapignData

}) => {

  // console.log(completeAndLiveData);
  if (chosenTab === "Active") {
    if (completeAndLiveData?.length > 0) {
      return completeAndLiveData.map((data) => {
        return <ViewLeadsCard key={data.campaign_id} data={data} />;
      });
    } else {
      return <p>No campaign is live</p>;
    }
  }
  if (chosenTab === "Completed") {
    if (completeData.length > 0) {
      return completeData.map((data) => {
        return <CampignCompletedCard key={data.campaign_id} data={data} />;
      });
    } else {
      return <p>No Campaign is completed</p>;
    }
  }

  if (chosenTab === "All") {
    if (allCamapignData === null) {
      return <></>;
    }
    if (allCamapignData?.length > 0) {
      return allCamapignData.map((data) => {
        return <ViewLeadsCard key={data._id} data={data} />;
      });
    } else {
      return <p>No Campaign is in draft</p>;
    }
  }

 

};
