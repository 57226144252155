import React from 'react'
import AdOverViewPage from '../../pages/ad-overview/adOverViewPage'
import { useLocation } from 'react-router-dom'

function WebAddCampOverview() {
  const location = useLocation();
  // console.log("location", location?.state?.cmpid)


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <AdOverViewPage />
        </div>

      </div>
    </div>
  )
}

export default WebAddCampOverview