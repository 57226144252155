import React from 'react'
import './landingpage-component.css';
// import brandlogo from '../../asetes/brandlogo.png'

import brandlogo from '../../asets/asetes/brandlogo.png'
import { ArrowUpRight } from 'lucide-react';

function LandiingNavbar() {
    const openSignupInNewTab = () => {
        window.open("/signup", "_blank");
      };
   
  return (
        <div className="container-fluid bg-navbar shadow-sm fixed-top">
            <div className="container" style={{position:'relative'}}>
                 <div className="lp-navbar py-2 d-flex justify-content-between align-items-center">
                    <div>
                        <img src={brandlogo} className='img-fluid' alt="" />

                    </div>
                    <div>
                    {/* <div class="box">content inside</div> */}
                    <button onClick={openSignupInNewTab} className='try-for-free-btn flex items-center justify-center'>
                            <span>Try for free</span> &nbsp; <span><ArrowUpRight className=' h-4 md:h-5'/></span>
                        </button>
                    </div>
                 </div>
            </div>
        </div>
  )
}

export default LandiingNavbar