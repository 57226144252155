import React from 'react'
import LandiingNavbar from './landingpage-component/LandiingNavbar'
import HeroContent from './landingpage-component/HeroContent'
// import LaunchCampaignInThreeStep from './landingpage-component/LaunchCampaignInThreeStep'
import ImportantAndOutcome from './landingpage-component/ImportantAndOutcome'
import SimpleIntuitiveFeatures from './landingpage-component/SimpleIntuitiveFeatures'
import OurClient from './landingpage-component/OurClient'
import PopularClients from './landingpage-component/PopularClients'
import StartFreeTrial from './landingpage-component/StartFreeTrial'
import FrequentalyAskedQuestions from './landingpage-component/FrequentalyAskedQuestions'
import PreviewVideo from './landingpage-component/PreviewVideo'
import LandingPageFooter from './landingpage-component/LandingPageFooter'
import LaunchAdsInThreeStepsProcess from './landingpage-component/lunchads-inthree-steps/LaunchAdsInThreeStepsProcess'



function LandingPage() {
  return (
<>
   <LandiingNavbar/>
   <div style={{background:'black'}}>
   <HeroContent/>
   <PreviewVideo/>
 {/* <PopularClients/> */}
  <ImportantAndOutcome/>
  {/* <PreviewVideo/> */}
  {/* <LaunchCampaignInThreeStep/> */}
  <LaunchAdsInThreeStepsProcess/>
  <SimpleIntuitiveFeatures/>
  <StartFreeTrial/>
  <FrequentalyAskedQuestions/>
  {/* <OurClient/> */}
  <LandingPageFooter/>
 
  
   </div>
  
</>
  )
}

export default LandingPage