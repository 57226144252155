import React from 'react'
import LeadPage from '../../pages/lead/leadPage'


function WebLeadsPage() {
  return (   
    <>
        <div className="container-fluid m-0 p-0">
           
                <div className="row">
                    <div className="col-12">
                         <LeadPage/>
                    </div>
                    {/* <div className="col-">
                          <LeadsPageForm/>
                        <CampignMgmtPage text="Other Campaigns"/>
                    </div> */}
                </div>
            </div>
        
    </>
  )
}

export default WebLeadsPage