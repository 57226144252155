import { Chart } from "primereact/chart";
import React, { useEffect, useState } from 'react'
import { Calendar } from "../../../components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover"
import { Button } from "../../../components/ui/button";
import { parseISO, isBefore, isAfter, isDate } from 'date-fns';
import { cn } from "../../../lib/utils"
import zIndex from "@mui/material/styles/zIndex";

function WebHomeGraph({ className ,datePints, dateStartEnd}) {
    const [chartOptions, setChartOptions] = useState({});
    const [openGraphFilter, setOpenGraphFilter] = useState(false);
    const [chartData, setChartData] = useState({ datasets: [] });
    const [dateRangeForFilter, setDateRangeForFilter] = useState({})
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [chartOptionFilterValue, setChartOptionsFilterValue] = useState("last 7 days");
   

    const createGradient = (ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );
        gradient.addColorStop(0, "rgba(255, 0, 0, 1)"); // Start color
        gradient.addColorStop(1, "rgba(0, 0, 255, 1)"); // End color
        return gradient;
      };
      // effect for chart
      useEffect(() => {
        let chartLabels;
        let chartValues4leads;
        let chartValues4message;
      
        if (chartOptionFilterValue === "last 7 days") {
          chartValues4leads = datePints.slice(-7).map((dp) => dp.value);
      
          chartLabels = datePints.slice(-7).map((dp) => dp.date);
        } else if (chartOptionFilterValue === "last 14 days") {
          chartValues4leads = datePints.slice(-14).map((dp) => dp.value);
      
          chartLabels = datePints.slice(-14).map((dp) => dp.date);
        } else if (chartOptionFilterValue === "last 28 days") {
          chartValues4leads = datePints.slice(-28).map((dp) => dp.value);

          chartLabels = datePints.slice(-28).map((dp) => dp.date);
        } else if (chartOptionFilterValue === "custom day") {
          // console.log("setDateRangeForFilter", dateRangeForFilter);
          const fromDateStr = dateRangeForFilter?.from ? dateRangeForFilter.from : "2024-03-02T18:30:00.000Z";
          const toDateStr = dateRangeForFilter?.to ? dateRangeForFilter.to : "2024-03-21T18:30:00.000Z";
          const fromDate = new Date(fromDateStr);
          const toDate = new Date(toDateStr);
      
          const monthMap = {
            Jan: 0,
            Feb: 1,
            Mar: 2,
            Apr: 3,
            May: 4,
            Jun: 5,
            Jul: 6,
            Aug: 7,
            Sep: 8,
            Oct: 9,
            Nov: 10,
            Dec: 11,
          };
      
          const currentYear = fromDate.getFullYear();
      
          const filteredData = datePints?.filter((item) => {
            const [day, month] = item.date.split(" ");
            const itemDate = new Date(currentYear, monthMap[month], parseInt(day));
            return itemDate >= fromDate && itemDate <= toDate;
          });
      
          if (filteredData) {
            chartValues4leads = filteredData.map((dp) => dp.value);
            chartLabels = filteredData.map((dp) => dp.date);
          }
        } else {
          chartValues4leads = datePints.map((dp) => dp.value);
     
          chartLabels = datePints.map((dp) => dp.date);
        }
      
        const maxLabelsToShow = 8;
        const stepSize = chartLabels.length > maxLabelsToShow ? Math.ceil(chartLabels.length / maxLabelsToShow) : 1;
      
        const data = {
          labels: chartLabels,
          datasets: [
            {
              label: "Leads",
              fill: true,
              backgroundColor: "transparent",
              yAxisID: "y",
              tension: 0.4,
              data: chartValues4leads,
              borderColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
      
                if (!chartArea) {
                  return null;
                }
                return createGradient(ctx, chartArea);
              },
              pointRadius: 1,
              pointHoverRadius: 7,
              pointBackgroundColor: "white",
              pointBorderColor: "rgb(255, 0, 255)",
              pointHoverBackgroundColor: "rgb(255, 0, 255)",
              pointHoverBorderColor: "white",
            },
          ],
        };
      
        const verticalLinePlugin = {
          id: "verticalLinePlugin",
          afterDraw: (chart, args, options) => {
            if (chart.tooltip._active && chart.tooltip._active.length) {
              const ctx = chart.ctx;
              const x = chart.tooltip._active[0].element.x;
              const topY = chart.scales.y.top;
              const bottomY = chart.scales.y.bottom;
      
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY);
              ctx.lineTo(x, bottomY);
              ctx.lineWidth = 10;
              ctx.strokeStyle = "rgba(0, 0, 0, 0.9)";
              ctx.setLineDash([5, 5]);
              ctx.stroke();
      
              ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
              ctx.fillRect(0, topY, x, bottomY - topY);
      
              ctx.restore();
            }
          },
        };
      
        setChartData(data);
      
        setChartOptions({
          maintainAspectRatio: false,
          aspectRatio: 1.3,
          interaction: {
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              autoSkip: false,
              maxTicksLimit: maxLabelsToShow,
              grid: {
                display: false,
              },
              ticks: {
                stepSize: 1, // Ensure at least 8 ticks
                callback: function (val, index) {
                  return this.getLabelForValue(val);
                },
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Leads",
              },
              grid: {
                display: false,
              },
              ticks: {
                stepSize: 10,
                max: 40,
              },
            },
          },
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
            },
            legend: {
              display: false,
            },
            verticalLinePlugin,
          },
          elements: {
            line: {
              tension: 0.3,
            },
          },
        });
      }, [chartOptionFilterValue, datePints?.length, dateRangeForFilter]);
      // useEffect(() => {
      //   let chartLabels;
      //   let chartValues;
      //   if (chartOptionFilterValue === "last 7 days") {
      //     chartValues = datePints.slice(-7).map((dp) => dp.value);
      //     chartLabels = datePints.slice(-7).map((dp) => dp.date);
      //   //  console.log(chartValues);
      //   } else if (chartOptionFilterValue === "last 14 days") {
      //     chartValues = datePints.slice(-14).map((dp) => dp.value);
      //     chartLabels =datePints.slice(-14).map((dp) => dp.date);
      //   } else {
      //     chartValues = datePints.map((dp) => dp.value);
      //     chartLabels = datePints.map((dp) => dp.date);
      //   }
    
      //   const maxLabelsToShow = 18;
      //   const stepSize = Math.ceil(chartLabels.length / maxLabelsToShow);
      //   const data = {
      //     labels: chartLabels,
      //     datasets: [
      //       {
      //         label: "Leads",
      //         fill: true,
      //         backgroundColor: "transparent",
      //         yAxisID: "y",
      //         tension: 0.4,
      //         data: chartValues,
      //         borderColor: function (context) {
      //           const chart = context.chart;
      //           const { ctx, chartArea } = chart;
    
      //           if (!chartArea) {
      //             return null;
      //           }
      //           return createGradient(ctx, chartArea);
      //         },
      //         pointRadius: 1,
      //         pointHoverRadius: 7,
      //         pointBackgroundColor: "white",
      //         pointBorderColor: "rgb(255, 0, 255)",
      //         pointHoverBackgroundColor: "rgb(255, 0, 255)",
      //         pointHoverBorderColor: "white",
      //       },
      //     ],
      //   };
    
      //   const verticalLinePlugin = {
      //     id: "verticalLinePlugin",
      //     afterDraw: (chart, args, options) => {
      //       if (chart.tooltip._active && chart.tooltip._active.length) {
      //         const ctx = chart.ctx;
      //         const x = chart.tooltip._active[0].element.x;
      //         const topY = chart.scales.y.top;
      //         const bottomY = chart.scales.y.bottom;
    
      //         // Draw the vertical line
      //         ctx.save();
      //         ctx.beginPath();
      //         ctx.moveTo(x, topY);
      //         ctx.lineTo(x, bottomY);
      //         ctx.lineWidth = 10;
      //         ctx.strokeStyle = "rgba(0, 0, 0, 0.9)"; // Light dotted line
      //         ctx.setLineDash([5, 5]);
      //         ctx.stroke();
    
      //         // Shade the left side of the line
      //         ctx.fillStyle = "rgba(0, 0, 0, 0.1)"; // Light shade
      //         ctx.fillRect(0, topY, x, bottomY - topY);
    
      //         ctx.restore();
      //       }
      //     },
      //   };
    
      //   setChartData(data);
    
      //   setChartOptions({
      //     maintainAspectRatio: false,
      //     aspectRatio: 1.3,
      //     interaction: {
      //       mode: "index",
      //       intersect: false,
      //     },
      //     scales: {
      //       x: {
      //         autoSkip: false,
      //         maxTicksLimit: maxLabelsToShow,
      //         grid: {
      //           display: false,
      //         },
      //         ticks: {
      //           callback: function (val, index) {
      //             // Display every nth label
      //             return index % stepSize === 0 ? this.getLabelForValue(val) : "";
      //           },
      //         },
      //       },
      //       y: {
      //         beginAtZero: true,
      //         grid: {
      //           display: false,
      //         },
      //         title: {
      //           display: true,
      //           text: 'Leads', // Your Y-axis label text
      //         },
      //         ticks: {
      //           stepSize: 10,
      //           max: 40,
      //         },
      //       },
      //     },
      //     plugins: {
      //       tooltip: {
      //         mode: "index",
      //         intersect: false,
      //       },
      //     },
      //     plugins: [verticalLinePlugin],
      //     plugins: {
      //       legend: {
      //         display: false,
      //       },
      //     },
      //     elements: {
      //       line: {
      //         tension: 0.3, // Adjust the line tension to create a slight curve
      //       },
      //       // ... other element properties
      //     },
      //     // ... other chart options
      //   });
      // }, [chartOptionFilterValue, datePints?.length]);

      const handleSelect = (range) => {
        setDateRange(range);
      };

      const handleUpdate = () => {
        setDateRangeForFilter(dateRange)
        // console.log('Selected Date Range:', dateRange);
        setChartOptionsFilterValue("custom day");
        setOpenGraphFilter(false);
        
        
      };

      const datestart = dateStartEnd?.startDate?dateStartEnd?.startDate:'2024-02-14';
      const dateend = dateStartEnd?.endDate?dateStartEnd?.endDate:'2024-03-22'
        //  console.log("datestart", datestart)
        //  console.log("dateend", dateend)
      const dateStart = parseISO(datestart);
      const dateEnd = parseISO(dateend);

      const isDateDisabled = (date) => {
        return isBefore(date, dateStart) || isAfter(date, dateEnd);
      };
    
    
  return (
    <>
<div className="d-flex justify-content-center  align-ietems-center" style={{width:'100%', height:'324px', backgroundColor:'#FAFAFA', borderRadius:'12px', marginTop:'22px'}}>
<div className="chart-kapil-container shadow-sm m-0 p-1s" style={{height:'371px', width:'100%'}}>
          <div className="title">
            <h4>Reports</h4>
            <div className="btns">
              <div
                className="select-btn"
                onClick={() => setOpenGraphFilter(!openGraphFilter)}
              >
                <span>{chartOptionFilterValue}</span>
                <svg
                  width="4"
                  height="3"
                  viewBox="0 0 4 3"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.82322 2.77521C1.92085 2.87285 2.07915 2.87285 2.17678 2.77521L3.76777 1.18422C3.8654 1.08659 3.8654 0.928302 3.76777 0.830671C3.67014 0.733039 3.51184 0.733039 3.41421 0.830671L2 2.24488L0.585786 0.830671C0.488155 0.733039 0.329864 0.733039 0.232233 0.830671C0.134602 0.928302 0.134602 1.08659 0.232233 1.18422L1.82322 2.77521ZM1.75 2.39844V2.49844H2.25V2.39844H1.75ZM1.75 2.49844V2.59844H2.25V2.49844H1.75Z"
                    fill="black"
                  />
                </svg>
              </div>
              {openGraphFilter && (
                <>
                 <button
                                  className="select-btn-options"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 7 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 7 days
                                </button>
                                <button
                                  className="select-btn-options"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 14 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 14 days
                                </button>
                                <button
                                  className="select-btn-options border"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 28 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 28 days
                                </button>

                                <button
                                  className="select-btn-options border"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <div className={cn("grid gap-2", className)}>
                                    <Popover style={{zIndex:'10000'}} >
                                      <PopoverTrigger  asChild>
                                        <button
                                          id="date"

                                          variant={"outline"}
                                          style={{ FontSize: '9px', Padding: '0px' }}

                                          className={cn(
                                            "w-[100%]  overflow-hidden p-0 border-none",
                                            !dateRange && "text-muted-foreground"
                                          )}
                                        >
                                          custom date
                                        </button>
                                      </PopoverTrigger>
                                      <PopoverContent  className="w-auto p-0 z-[10000]" align="start">
                                        <Calendar
                                          initialFocus
                                          classNames=""
                                          style={{zIndex:'10000'}}
                                          className="clalender-bg"
                                          mode="range"
                                          defaultMonth={dateRange?.from}
                                          selected={dateRange}
                                          onSelect={handleSelect}
                                          disabled={isDateDisabled}
                                          numberOfMonths={2}
                                          
                                        />
                                        <div className=" text-end pb-2 px-2">
                                        <Button  onClick={handleUpdate} className="bg-[#62c] text-white">Update</Button>

                                        </div>
                                      </PopoverContent>
                                    </Popover>
                                  </div>
                                </button>
                </>
              )}
            </div>
            {/* ) */}
          </div>
          <div className="chart">
            <Chart
              style={{ width: "100%" }}
              type="line"
              data={chartData}
              options={chartOptions}
            />
          </div>
          
        </div>
        </div>

      {/* <div className="d-flex justify-content-center align-ietems-center" style={{width:'100%', height:'315px', backgroundColor:'#FAFAFA', borderRadius:'12px', marginTop:'20px'}}>
            <Chart
              style={{ width: "100%" }}
              type="line"
              data={chartData}
              options={chartOptions}
            />
          </div> */}
    </>
  )
}

export default WebHomeGraph