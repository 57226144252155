import React from 'react';
import Navbar2 from '../../navbar/Navbar2';
import successIcon from '../../asets/Success Icon.png'
import { Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentSuccessful = () => {
  const navigate = useNavigate()
  const {state} = useLocation()

        // console.log("location", state)

    return (
        <>
             <div className="container-fluid px-0 ">
             <div className="d-md-none"> <Navbar2 menu={true} /></div>
                   <div className="row  pt-4 p-0 " style={{minHeight:'90vh', }}>
                    <div className="col-12 px-0 px-md-3 mt-4 mt-md-2 d-flex justify-content-center align-items-center"  >
                        <div className="payment-successful   position-relative d-flex justify-content-center align-items-center px-1 px-md-2 mx-1 py-0 shadow" style={{backgroundColor:'#fce4db', borderRadius:'12px', minHeight:'474px',  maxWidth:'380px'}}>
                             <span className='position-absolute' style={{top:'-39px', left:'38%'}}><img src={successIcon} className='' alt="" /></span>
                          <div className='p-0 m-0' style={{width:'100%'}}>
                          <div className='payment-content p-0 m-0 '>

                            <p className='text-center mb-0 mt-0' style={{fontWeight:'600', fontSize:'1.3rem'}}>Payment Success!</p>
                            <p className='text-center mt-0 pt-0' style={{fontWeight:'400', fontSize:'14px', color:'rgba(0, 0, 0, 0.72)'}}>Your payment has been recorded successfully.</p>
                            </div>
                            <div className='text-center d-flex  justify-content-center align-items-center'>
                            <p className='my-3' style={{ width:'90%', border:'1.01px solid rgba(0, 0, 0, 1)'}} ></p>
                            </div>

                            <div className='payment-total'>
                                <p className='text-center pb-0 mb-0' style={{fontWeight:'400', color:'(0, 0, 0, 0.72)'}}>Total Payment</p>
                                <p className='text-center' style={{fontWeight:'600', color:'(0, 0, 0, 1)', fontSize:'25px'}}>Rs {state?.paymentdata?state?.paymentdata.amount:'Unavailable'}</p>
                            </div>
                            <div className='payment-card1 gap-2 d-flex justify-content-center  flex-wrap align-items-center'>
                                <div className=''  style={{height:'64.41px', width:'152.68px', padding:'12px', borderRadius:'6px', border:'1.01px solid rgba(0, 0, 0, 1)'}}>
                                    <p className='text-start pb-0 mb-0' style={{fontSize:'12px'}}>Payment Id</p>
                                    <p className='text-start' style={{fontSize:'13px', fontWeight:'500', color:'rgba(0, 0, 0, 1)'}}>{state?.paymentdata?`${state?.paymentdata?.payment_id?.slice(0, 16)}..`:'Unavailable'}</p>
                                </div>
                                <div className=''  style={{height:'64.41px', width:'152.68px', padding:'12px', borderRadius:'6px', border:'1.01px solid rgba(0, 0, 0, 1)'}}>
                                    <p className='text-start pb-0 mb-0' style={{fontSize:'12px'}}>Payment Time</p>
                                    <p className='text-start' style={{fontSize:'13px', fontWeight:'500', color:'rgba(0, 0, 0, 1)'}}>{state?.paymentdata?state?.paymentdata?.time:'Unavailable'}</p>
                                </div>
                            
                            </div>
                            <div className='payment-card2 mt-2 gap-2 d-flex flex-wrap justify-content-center align-items-center'>
                                <div className='' style={{height:'64.41px', width:'152.68px', padding:'12px', borderRadius:'6px', border:'1.01px solid rgba(0, 0, 0, 1)'}}>
                                <p className='text-start pb-0 mb-0' style={{fontSize:'12px'}}>Payment Method</p>
                                <p className='text-start' style={{fontSize:'13px', fontWeight:'500', color:'rgba(0, 0, 0, 1)'}}>{state?.paymentdata?state?.paymentdata?.method:'Unavailable'}</p>
                                </div>
                                <div className=''  style={{height:'64.41px', width:'152.68px', padding:'12px', borderRadius:'6px', border:'1.01px solid rgba(0, 0, 0, 1)'}}>
                                <p className='text-start pb-0 mb-0' style={{fontSize:'12px'}}>Sender Name</p>
                                <p className='text-start' style={{fontSize:'13px', fontWeight:'500', color:'rgba(0, 0, 0, 1)'}}>{state?.paymentdata?state?.paymentdata?.userName:'Unavailable'}</p>
                                </div>
                            
                            </div>
                          </div>
                           
                        </div>

                       
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                    <div className='text-center mt-3 px-4' style={{ width:'380px'}}>
                            <Button variant='contained' onClick={()=>navigate("/")} className='shadow' sx={{borderRadius:'8px', backgroundColor:'#6622CC',  color:'white', padding:'10px 16px', width:'100%', textTransform:'unset', '&:hover': {
      backgroundColor:'#6622CC'} }}> Go to homepage <ArrowRightAltIcon sx={{color:'white'}}/>  </Button>
                        </div>
                    </div>
                   </div>
             </div>
        </>
    );
};





export default PaymentSuccessful;
