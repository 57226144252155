import React from 'react'
import clienticon from '../../asets/asetes/client circle1.svg'

function OurClient() {
  return (
    <>
      <div className="m-0 container-fluid ourclient-container">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className='ourclient-content-top'>
                        <p className='text-center ourclient-h p-0 m-0'>Our Clients <span className='oc-love-text'>Love</span> Us</p>
                        <p className='text-center ourclient-h p-0 '>And Our Work</p>
                        <p className='text-center ourclient-p p-0 m-0'>we healp you to build your website and brand, join our family and</p>
                        <p className='text-center ourclient-p p-0 m-0'>let's make somthing blog together</p>
                    </div>
                </div>
            </div>

            <div className="row py-3">
                <div className="col-12 col-md-6 text-center text-md-start d-flex justify-content-center align-items-center justify-content-md-start">
                    <div className="client-feedback-container border p-3">
                      <img src={clienticon} className='client-circle-photo' alt="" />
                       <p className='client-feedback-p p-0'> this is actually what i needed right now... In the process of designing a website..! Would love to try this</p>
                        <p className='client-feedback-name'>Dipak Kumar</p>
                    </div>
                </div>
                <div className="col-12 col-md-6"></div>

            </div>
        </div>
      </div>
    </>
  )
}

export default OurClient