import React from 'react'
import { useEffect, useRef, useState } from "react";
import Navbar2 from "../../navbar/Navbar2";
//import loadermp4 from "./asets/loadingmp4.mp4";
//import loadermp4 from "../../asets/loadingmp4.mp4";
import "./LoadingScreen.css";
import loaderImg from '../../asets/loaderv2.gif'
import { Box, CircularProgress, Step, StepLabel, Stepper, circularProgressClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";

const steps = [
    {
      label: "Incorporating your edits",
    },
    {
      label: "Tailoring audience segments",
    },
    {
      label: "Selecting optimal Ad formats",
    },
    {
      label: "Comparing Price benchmarks",
    }
    
  ];

function LoadingForReviewDetails() {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
      let timeout;
      if (activeStep > 3) {
        return;
      } else {
        timeout = setTimeout(() => {
          setActiveStep(activeStep + 1);
        }, 6000);
      }
      return ()=>{
        if(timeout){
          return clearTimeout(timeout);
        }
      }
    }, [activeStep]);

  return (
     <div className="loadingScreen" style={{ backgroundColor: "white" }}>
         <div className="d-md-none">
         <Navbar2 menu={false} />
         </div>
      
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center  my-5">

        {/* <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={100}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? 'rgba(102, 34, 204, 1);' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={100}
        thickness={4}
        {...props}
      />
    </Box>  */}
   <div>
   <div className="loading" style={{ width: "200px", height: "200px" }}>
          <img src={loaderImg} alt="" style={{width:'100%', height:'auto'}} />
      </div>
   </div>


        </div>
      </div>

      <div className="steppe d-flex justify-content-center align-items-center"  style={{ backgroundColor: "white" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => {
            return (
              <Step key={index}  sx={{'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text':{fill:'white'},'& .MuiStepLabel-root .Mui-active':{color:'#6622cc'}, '& .MuiStepLabel-root .Mui-completed':{color:'#6622cc'} }}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  )
}

export default LoadingForReviewDetails