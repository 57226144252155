import { NavLink, useLocation } from "react-router-dom";
import Navbar2 from "../../navbar/Navbar2";
import "./supportSuccess.css";
import { useState } from "react";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
export default function SupportSuccess() {
  const { state } = useLocation();
  const [loading, setloading] = useState(false);
  const ticketNumber = state?.ticketNumber;
  return (
    <div className="supportSuccessContainer">
      <Navbar2 menu={false} />
      <section>
        <div>
          <div className="image">
            <img src="supportSuccess.svg" alt="support success" />
          </div>
          <p>
            Thank You! for reaching out to us <br />
            Your ticket id: <span>{ticketNumber}</span>
            <br /> We'll get back to you shortly
          </p>
        </div>
        <NavLink to="/">
          <button
            className="dashboardBtn"
            onClick={() => setloading(true)}
            disabled={loading === true}
            style={{ backgroundColor: loading && "rgb(76, 67, 90)" }}
          >
            {loading ? "Going to Dashboard" : "Go to dashboard"}
          </button>
        </NavLink>
      </section>
      <BottomNavbar />
    </div>
  );
}
