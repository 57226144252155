import React from 'react'
import './launchadsthreestepsprocess.css';
import stepimg1 from '../../../asets/asetes/Step1.png'
import stepimg2 from '../../../asets/asetes/Desktop21.png'
import stepimg3 from '../../../asets/asetes/Desktop31.png'
import mobile1 from '../../../asets/asetes/Mobile1.png'
import mobile2 from '../../../asets/asetes/Mobile21.png'
import mobile3 from '../../../asets/asetes/Mobile31.png'

function LaunchAdsInThreeStepsProcess() {
  return (
       <div className="container-fluid section-py-threestep" style={{overflow:'hidde'}}>
       
        <div className="container">
              <div className="row position-sticky threestep-heading-container">
              <div className="col-12  " >
                          <div className=' d-flex justify-content-center align-items-center text-center'>
                          <div className='text-center'>
                            <p className='h3-text text-center'><span className='text-gradient'>3 Steps</span>  is all what it takes</p>
                         </div>    
                          </div>
                        
                    </div>
              </div>
            {/* <div className="row">
                <div className="col-12 col-md-6">
                    <div className='content-cotainer border'>
                        <p>Provide Details</p>
                        <div>
                        <h1>Get</h1>
                        <p>Enter Project website landing page url</p>
                        </div>
                        
                    </div>

                </div>
                <div className="col-12 col-md-6">
                    <div>
                        <img src="" alt="" />
                    </div>
                </div>
            </div> */}
               {/* step1 */}
                <div  className="row pt-3 pt-md-2 position-sticky threestep-height threestep-gradient threestep-position" style={{ zIndex:'9'}}>
                    <div className="col-12  col-md-6 order-2 order-md-1">
                    <div className="threestep-img d-flex justify-content-center align-items-start align-items-md-end">
                        {/* <div className="lcs1-bluebg-bo"></div> */}
                        <img src={stepimg1} className='threestep-main-img d-none d-md-block' style={{maxWidth:'100%', maxHeight:'400px'}} alt=""  />
                        <img src={mobile1} className='threestep-main-img d-md-none' style={{maxWidth:'100%', maxHeight:'400px'}} alt=""  />
                    
                    </div>
                    </div>
                    <div className="col-12 order-1 order-md-2 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <div className='threestep-content-box '>
                        
                            <div className='threestep-content'>
                                <p className='p-0 m-0 text-center text-md-start threestep-subh text-grd1'><span className='text-provide'>Provide</span> Details</p>
                                <h3 className='p-0 m-0  text-center text-md-start threestep-h'>Get</h3>
                                <div className='text-center d-flex justify-content-center justify-content-md-start align-items-center text-md-start'>
                                <p className='p-0 m-0  text-center text-md-start threestep-body threestep-body-width'>Enter project’s website landing page URL</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

             {/* step2 */}

             <div  className="row pt-3  pt-md-5 position-sticky threestep-height threestep-gradient2 threestep-position" style={{ zIndex:'10'}}>
                    <div className="col-12  col-md-6 order-2 order-md-2">
                    <div className="threestep-img d-flex justify-content-start align-items-start align-items-md-end">
                        {/* <div className="lcs1-bluebg-bo"></div> */}
                        <img src={stepimg2} className='threestep-main-img d-none d-md-block' style={{maxWidth:'100%', maxHeight:'400px'}} alt=""  />
                        <img src={mobile2} className='threestep-main-img d-md-none' style={{maxWidth:'100%', maxHeight:'400px'}} alt=""  />
                   
                    </div>
                    </div>
                    <div className="col-12 order-1 order-md-1  col-md-6 d-flex justify-content-center justify-content-md-center align-items-center">
                    <div className='threestep-content-box'>
                        
                            <div className='threestep-content'>
                                <p className='p-0 m-0 text-center text-md-start threestep-subh text-grd1'><span className='text-provide'>Review</span> Details</p>
                                <p className='p-0 m-0 text-center text-md-start threestep-h'>Set</p>
                                 <div className='text-center d-flex justify-content-center justify-content-md-start align-items-center text-md-start'>
                                 <p className='p-0 m-0 text-center text-md-start threestep-body threestep-body-width'>Confirm/modify the details, & high quality images of the property</p>

                                 </div>
                            </div>
                        </div>
                    </div>
                </div> 

              {/* step3 */}

                <div  className="row pt-3 pt-md-5  position-sticky threestep-height threestep-gradient threestep-position" style={{ zIndex:'999'}}>
                    <div className="col-12  col-md-6 order-2 order-md-1">
                    <div className="threestep-img d-flex justify-content-center align-items-start align-items-md-end">
                        {/* <div className="lcs1-bluebg-bo"></div> */}
                        <img src={stepimg3} className='threestep-main-img d-none d-md-block' alt=""  />
                        <img src={mobile3} className='threestep-main-img d-md-none' style={{maxWidth:'100%', maxHeight:'400px'}} alt=""  />
                   
                    </div>
                    </div>
                    <div className="col-12 order-1 order-md-2 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <div className='threestep-content-box '>
                        
                            <div className='threestep-content'>
                                <p className='p-0 m-0 text-center text-md-start threestep-subh text-grd1'><span className='text-provide'>Confirm &</span>Pay</p>
                                <p className='p-0 m-0 text-center text-md-start threestep-h'>Leap</p>
                                <div className='text-center d-flex justify-content-center justify-content-md-start align-items-center text-md-start'>
                                <p className='p-0 m-0 text-center text-md-start threestep-body threestep-body-width'>Check action points, choose facebook page, decide budget & campaign period. Confirm & pay to launch your ad</p>                             
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
        </div>
       </div>
  )
}

export default LaunchAdsInThreeStepsProcess