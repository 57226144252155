const ToolTip = ({ current = 0, percentage = 0 }) => {
  return (
    <div class="container p-0" style={{ width: "100%" }}>
      <div
        class="tool-tip-container"
        style={{
          position: " relative",
          height: "28px",
          width: "fit-content",
          transform: "translateX(-50%)",
          left: percentage + "%",
        }}
      >
        <div
          class="tooltip-block"
          style={{
            width: "34px",
            height: "22px",
            flexShrink: "0",
            backgroundColor: " #62c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            borderRadius: "3px",
            fontSize: "12px",
          }}
        >
          {current}
        </div>
        <svg
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            bottom: 1,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <path
            d="M3.86602 4.5C3.48112 5.16667 2.51887 5.16667 2.13397 4.5L0.401923 1.5C0.0170231 0.833333 0.498149 0 1.26795 0H4.73205C5.50185 0 5.98298 0.833333 5.59808 1.5L3.86602 4.5Z"
            fill="#6622CC"
          />
        </svg>
      </div>
      <div
        id="myProgress"
        style={{
          width: "100%",
          backgroundColor: "#D0D0D0",
          borderRadius: "12px",
          height: "6px",
        }}
      >
        <div
          id="myBar"
          style={{
            width: percentage + "%",
            height: "100%",
            backgroundColor: "#F07A4C",
            borderRadius: "12px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ToolTip;
