import { ProfilePageContext } from '../../context/ProfilePageContext'
import React, {useContext} from 'react'
import { useNavigate } from 'react-router-dom'

function TopBanner({positionbanner, accountPercentage, fbpagePercentage, businessPercentage, notificationPercentage}) {
    const {profileCompleteStatus} = useContext(ProfilePageContext)
    // const { getUserProfileData, overallPercentage} = useContext(ProfilePageContext);
    
     const navigate = useNavigate()
    const handleProfileNavigate = () =>{
           
          if(accountPercentage !== 20){
              navigate("/account-details")
          }else if(businessPercentage !== 30){
            navigate("/business-details")
          }else if(fbpagePercentage !== 40){
            navigate("/facebook-page")
          }else if(notificationPercentage !== 10 ){
            navigate("/notification-settings")
          }else{
            navigate("/account-details")
          }
    }
  return (
      <>
      <div className="top-banner z-[999999] gap-12 top-0 w-full flex items-center justify-center  h-[35px] bg-[#FF7C4F]" style={{position:positionbanner?'static':'fixed'}}>
            <div className='flex-1 md:flex-none text-center'>
                <p className='m-0 p-0 text-[10px]  md:text-[14px] font-[500] text-white '>🎉 Hurry! Free Membership For First 50 Users</p>
            </div>
            <div className=' pr-2'>
                 <p className='text-end p-0 m-0 text-[10px] md:text-[14px] font-[500] text-white'><button className=' text-white' onClick={handleProfileNavigate}>Complete Your Profile ↗</button></p>
            </div>
      </div>
      
      </>
  )
}

export default TopBanner