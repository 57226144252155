import "./FirstPage.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { Input } from "../../components/ui/input"
import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Navbar2 from "../../navbar/Navbar2";
import { useContext, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import Axios from "../../Axios";
import { AuthContext } from "../../AuthProvider";
import ResetPassword from "./ResetPassword";
import ConnectToFbAndSelectPageInfo from "./ConnectFacebookPageInfo";


function FirstPage() {
  const [user, setUser] = useState({});
  const toast = useRef(null);
  const { userToken } = useContext(AuthContext)
  const [loadSubmitUser, setLoadSubmitUser] = useState(false)
  const [errorLog, setErrorLog] = useState("")
  const [openAccordion, setOpenAccordion] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [businessData, setBusinessData] = useState({})
  const [emailVerifedLoad, setEmailVerifedLoad] = useState(false)
  const [emailVerifyError, setEmailVerifyError] = useState("")
  const [value, setValue] = useState({
    Name: "",
    Email: "",
    mobile: "",
    BusinessName: "",
    Category: "",
    Pan: "",
    Gst: "",
    BusinessWeb: "",
  });
  const [pagesList, setPagesList] = useState([]);
  const [panPreview, setPanPreview] = useState("")
  const [panImage, setPanImage] = useState("")
  const [logoPreview, setLogoPreview] = useState("")
  const [logoImage, setLogoImage] = useState("")


  const handleInputChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    handleUser();
  }, []);
  const handleUser = async () => {
    Axios
      .get("/user", {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response?.data?.user;
        setUser(data);
        // console.log("data", data)
        setPagesList(data?.page_id);

        setBusinessData(data)
        setValue({
          ...value,
          Name: data?.name ? data?.name : "",
          Email: data?.email ? data?.email : "",
          mobile: data?.mobile ? data?.mobile : "",
          BusinessName: data?.business_name ? data?.business_name : "",
          Category: data?.category ? data?.category : "",
          Pan: data?.pan_no ? data?.pan_no : "",
          Gst: data?.gst ? data?.gst === 'undefined' ? "" : data?.gst : "",
          BusinessWeb: data?.website ? data?.website === 'undefined' ? "" : data?.website : "",

        });
        if(data?.email_verified_at){
          setIsEmailVerified(true)
          
        }else{
          setIsEmailVerified(false)
        }
      })
      .catch((error) => {
        console.log("Error setting up request:", error);
      });
  };

  const handleProfile = async () => {
    setLoadSubmitUser(true)
    // console.log("val", value)
    const formData = new FormData();
    formData.append('business_name', value?.BusinessName);
    formData.append('category', value?.Category);
    formData.append('pan_no', value?.Pan);
    if (!user?.pan) {
      formData.append('pan', panImage)
    }

    formData.append('gst', value?.Gst ? value?.Gst : '');
    if (!user?.logo) {
      formData.append('logo', logoImage);
    }

    formData.append('website', value?.BusinessWeb ? value?.BusinessWeb : "");

    await Axios
      .post("/complete-profile", formData, {
        headers: {
          Authorization: userToken
        },
      })
      .then((response) => {
        if (
          response?.status === 200
        ) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response?.data?.message,
            life: 3000,
          });
        }
        handleUser()
        setLoadSubmitUser(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error?.response?.data?.message,
            life: 3000,
          });
        }
        console.log("Error setting up request:", error);

        setErrorLog(error?.response?.data.message)
        setLoadSubmitUser(false)
      });
  };

  const reloadPage = () => {
    window?.location?.reload()
  }




  const handlePanImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPanPreview(imageUrl);
      setPanImage(file);
    }
  };

  const handlePanRemoveImage = () => {
    setPanPreview(null);
    setPanImage(null);
    //fileInputRef.current?.value = '';
  };


  const ImagePanThumbnail = ({ src, onDelete }) => {
    // console.log("src", src)
    return (
      <>

        <div className="image-thumbnail-container">
          <img className="image-thumbnail " src={src} alt="thumbnail" />
          <span onClick={() => onDelete(onDelete)} className="delete-preview">
            <CancelIcon sx={{ fontSize: '18px', }} />
          </span>

        </div>
      </>
    )
  };


  const ImageThumbnail = ({ src, onDelete }) => {
    // console.log("src", src)
    return (
      <>

        <div className="image-thumbnail-container">
          <img className="image-thumbnail " src={src} alt="thumbnail" />
          <span onClick={() => onDelete(onDelete)} className="delete-preview">
            <CancelIcon sx={{ fontSize: '18px', }} />
          </span>

        </div>
      </>
    )
  };



  // for upload logo

  const handleLogoImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setLogoPreview(imageUrl);
      setLogoImage(file);
    }
  };

  const handleLogoRemoveImage = () => {
    setLogoPreview(null);
    setLogoImage(null);
    //fileInputRef.current?.value = '';
  };


  const LogoImageThumbnail = ({ src, onDelete }) => {
    // console.log("src", src)
    return (
      <>

        <div className="image-thumbnail-container">
          <img className="image-thumbnail " src={src} alt="thumbnail" />
          <span onClick={() => onDelete(onDelete)} className="delete-preview">
            <CancelIcon sx={{ fontSize: '18px', }} />
          </span>

        </div>
      </>
    )
  };

  
  // verifyemail
  const handleVerifyEmail  = async ()=>{
      setEmailVerifedLoad(true)
      setEmailVerifyError("")
    try {
      const res = await Axios.post("/create-token", {requestForEmailVerify:true}, {
        headers: {
          Authorization: userToken
        },
      })
          
        if(res.status===200){
            if(res.data?.valid){
              setEmailVerifyError(res.data?.message)
            }else{
              
              setEmailVerifyError(res.data?.message)
            }
           console.log("Email Verified")
          
        }
    } catch (error) {
      setIsEmailVerified(false)
      setEmailVerifyError("Enternal server error")
      console.log("error", error)
    }finally{
      setEmailVerifedLoad(false)
    }
}

  

  return (
    <>
      <div className="firstPage"  >
        <div className="d-md-none"><Navbar2 menu={true} /></div>
        <Toast ref={toast} />
        <Box
          id="accordian-container"
          sx={{
            margin: "0 auto",
            marginTop: "10px",
            width: "100%",

            // backgroundColor: "#F6F6F6",
            background: "#F3EFF8",
          }}
        >
          <Accordion sx={{ background: "#efebf4" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Personal Details {!isEmailVerified && <span className="text-danger" style={{fontSize:'12px'}}>(Please verify your email)</span>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction={"column"}
                gap={4}
                sx={{ paddingBottom: "15px" }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    // placeholder="Maink Verma"
                    placeholder="Enter Name"
                    value={value?.Name}
                    disabled
                    id="Name"
                    name="Name"
                    type="text"
                    // defaultValue={value?.Name}
                    // onChange={handleInputChange}
                    onChange={() => { }}

                    sx={{
                      color: "#000",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      opacity: '1',
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Email Id
                  </InputLabel>
                  <TextField
                     error={emailVerifyError.length>0 && Boolean(emailVerifyError)}
                     helperText={emailVerifyError.length>0 && emailVerifyError}
                    placeholder="Enter Email"
                    disabled
                    type="email"
                    value={value?.Email}
                    sx={{
                      color: "#000",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        isEmailVerified ? (
                          <InputAdornment position="end">
                            <CheckCircleIcon sx={{color:'green'}} />
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            {emailVerifedLoad?<Button variant="outlined"  sx={{fontSize:'14px', textTransform:'capitalize', color:'red', border:'1px solid red', '&:hover': {
                            border: '1px solid red',
                          },}} >Verifying...</Button>: <Button
                              variant="outlined"
                              
                              size="small"
                              sx={{fontSize:'14px', textTransform:'capitalize', color:'red', border:'1px solid red', '&:hover': {
      border: '1px solid red',
    },}}
                              onClick={handleVerifyEmail}
                            >
                              Verify
                            </Button>}
                           
                          </InputAdornment>
                        )
                      )
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Phone number
                  </InputLabel>
                  <TextField
                    placeholder="Enter Mobile Number"
                    disabled
                    // placeholder="+91 7357878127"
                    value={value?.mobile}
                    sx={{
                      fontSize: "12px",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Password
                  </InputLabel>
                  <TextField
                    placeholder="Enter Password"
                    id="password"
                    name="password"
                    type="password"
                    value={value?.Name}
                    disabled
                    // onChange={handleInputChange}
                    sx={{
                      color: "#000",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>
                <Typography
                  color="#62c"
                  fontSize={"14px"}
                  cursor="pointer"
                  textAlign={"right"}
                  fontWeight={500}
                  sx={{ cursor: "pointer" }}
                // onClick={() => {Input()}}
                >
                  <ResetPassword />
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          id="accordian-container"
          sx={{
            margin: "0 auto",
            marginTop: "10px",
            width: "100%",
            background: "#F7F4F3",
            boxShadow: "0px 4px 33.6px 0px rgba(0, 0, 0, 0.12)",

            // backgroundColor: "#F6F6F6",
          }}
        >
          <Accordion sx={{ background: "#efebf4" }} expanded={openAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              onClick={() => setOpenAccordion(!openAccordion)}
            >
              <Typography>Business Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction={"column"}
                gap={4}
                sx={{ paddingBottom: "15px" }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Business Name
                  </InputLabel>
                  <TextField
                    id="BusinessName"
                    name="BusinessName"
                    type="text"
                    value={value?.BusinessName}
                    onChange={handleInputChange}
                    disabled={user?.business_name ? true : false}
                    placeholder="Business Name"

                    sx={{
                      color: "#000",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Category
                  </InputLabel>
                  <TextField
                    id="Category"
                    name="Category"
                    type="text"
                    value={value?.Category}
                    onChange={handleInputChange}
                    placeholder="Enter Category"
                    disabled={user?.category ? true : false}
                    sx={{
                      color: "#000",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    PAN
                  </InputLabel>
                  <TextField
                    id="Pan"
                    name="Pan"
                    value={value?.Pan ? value?.Pan : null}
                    disabled={user?.pan_no ? true : false}
                    onChange={handleInputChange}
                    placeholder="Enter Pan No."

                    type="text"

                    sx={{
                      color: "#000",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },


                    }}
                  />
                </Box>

                {/* upload pand card */}

                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Pan Card
                  </InputLabel>



                  <div className="" style={{ width: '100%', maxHeight: '200px' }}>
                    {user?.pan?.length > 0 ? <img style={{ maxWidth: '100%', maxHeight: '180px', display: 'block' }} src={`${user?.pan}`} alt="Pan" /> : (
                      <>


                        {panPreview ? (<>
                          <div className="image-upload-container mt-md-2 mb-md ">


                            <ImageThumbnail

                              src={panPreview}
                              onDelete={() => handlePanRemoveImage()}
                            />




                          </div>

                        </>) : (
                          <>
                            <div className="pt-2">
                              <label className='p-2 col-12 mb-1 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#E0D3F5', borderRadius: '8px', cursor: 'pointer', color: '#6622CC', height: '54px' }}>

                                <span > <FileUploadIcon /> Upload Pancard</span>
                                <input accept="image/png, image/jpeg" type="file" name="photo"
                                  onChange={
                                    e => {
                                      // setPhoto(e.target.files[0])
                                      handlePanImageChange(e);

                                    }

                                  }

                                  hidden
                                />
                              </label>
                            </div>
                          </>




                        )}


                      </>)}

                  </div>

                </Box>





                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    GST
                  </InputLabel>
                  <TextField
                    placeholder="Enter GST"
                    id="Gst"
                    name="Gst"
                    type="text"
                    value={value?.Gst ? value?.Gst : null}
                    onChange={handleInputChange}
                    disabled={user?.gst ? user?.gst === 'undefined' ? false : true : false}
                    sx={{
                      fontSize: "12px",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>

                {/* upload logog */}
                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Logo
                  </InputLabel>



                  <div className="" style={{ width: '100%', maxHeight: '200px' }}>
                    {(user?.logo && user?.logo !== 'null') ? <img style={{ maxWidth: '100%', maxHeight: '180px', display: 'block' }} src={`${user?.logo}`} alt="Logo" /> : (
                      <>


                        {logoPreview ? (<>
                          <div className="image-upload-container mt-md-2 mb-md ">


                            <LogoImageThumbnail

                              src={logoPreview}
                              onDelete={() => handleLogoRemoveImage()}
                            />




                          </div>

                        </>) : (
                          <>
                            <div className="pt-2">
                              <label className='p-2 col-12 mb-1 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#E0D3F5', borderRadius: '8px', cursor: 'pointer', color: '#6622CC', height: '54px' }}>

                                <span > <FileUploadIcon /> Upload Logo</span>
                                <input accept="image/png, image/jpeg" type="file" name="photo"
                                  onChange={
                                    e => {
                                      // setPhoto(e.target.files[0])
                                      handleLogoImageChange(e);

                                    }

                                  }

                                  hidden
                                />
                              </label>
                            </div>
                          </>




                        )}


                      </>)}

                  </div>

                </Box>

                {/* upload logo end */}


                <Box>
                  <InputLabel
                    sx={{
                      color: "#656565",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14.452px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    Business Websites
                  </InputLabel>
                  <TextField
                    value={value?.BusinessWeb ? value?.BusinessWeb === 'undefined' ? null : value?.BusinessWeb : null}
                    id="BusinessWeb"
                    name="BusinessWeb"
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter Business Website "
                    disabled={user?.website ? user?.website === 'undefined' ? false : true : false}
                    sx={{
                      fontSize: "12px",
                      boxSizing: "border-box",
                      width: "100%",
                      height: "40px",
                      marginTop: "7px",
                      "& .MuiOutlinedInput-input.Mui-disabled": {
                        '-webkit-text-fill-color': '#000'
                      },
                    }}
                  />
                </Box>

                <Box
                  component={"div"}
                  sx={{ display: "flex ", justifyContent: "right " }}
                >
                  <Button
                    sx={{
                      padding: "8px 16px",
                      outline: "none",
                      border: "none",
                      borderRadius: "5px",
                      backgroundColor: "#62c",
                      color: "#fff",
                      fontSize: "12px",
                      '@media (max-width: 600px)': {
                        minWidth: '100%',
                      },
                      '@media (min-width: 600px)': {
                        minWidth: '120px',
                      },
                      fontWeight: 500,
                      '&:hover': {
                        backgroundColor: "#62c",
    },
                    }}
                    disabled={loadSubmitUser ? true : false}
                    variant="contained"
                    onClick={() => handleProfile()}
                  >
                    {loadSubmitUser ? 'loading...' : 'Save'}
                  </Button>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          component={"div"}
          sx={{
            borderRadius: 1,
            margin: "0 auto",
            marginBlock: "10px",
            width: "100%",
            background: "#6622CC0D",
            padding: "0px 0px 20px 0px",

            // boxShadow: "0px 4px 33.6px 0px rgba(0, 0, 0, 0.12)",
          }}
        >

          <Accordion sx={{ width: '100%', borderRadius: 1, background: "#efebf4" }} className="">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography> Manage Facebook Pages</Typography>
            </AccordionSummary>
            <AccordionDetails>


              <Stack direction={"row"} className="" gap={1} flexWrap={"wrap"}>
                {pagesList.length > 0 ? (
                  <>
                    {pagesList?.map((keyvalue) => {
                      return (
                        <>

                          {(keyvalue?.name || keyvalue?.phone) && <Card key={keyvalue?.id} sx={{ width: "100%", fontSize: '1rem' }}>
                            <div className="p-3">

                              {keyvalue?.name && <div className="d-flex align-items-center justify-content-between">
                                <p >Name :</p>
                                <p style={{ fontWeight: '500' }}>{keyvalue?.name}</p>
                              </div>}



                              {(keyvalue?.name || keyvalue?.phone) && <div className="text-end">

                                {/* <UpdateFbSelectedPages keyValue={keyvalue} handleUser={handleUser} pagesListfromprofile={pagesList}/> */}
                              </div>}
                            </div>

                          </Card>}

                        </>
                      );
                    })}

                  </>
                ) : (
                  <p>No Page Found</p>
                )}
              </Stack>

              <Box
                component={"div"}
                sx={{ display: "flex ", justifyContent: "right ", marginTop: 3 }}
              >
                <div className="pb-4 pb-md-0" style={{ backgroundColor: '' }}>
                  <ConnectToFbAndSelectPageInfo text="Add Pages" pagesListfromprofile={pagesList} bgcolor="rgb(238 235 243)" handleUser={handleUser} />
                </div>

              </Box>

            </AccordionDetails>
          </Accordion>

        </Box>
      </div>
      <div className="d-md-none"> <BottomNavbar /></div>












    </>
  );
}

export default FirstPage;
