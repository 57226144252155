import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import Axios from '../../Axios';
import { AuthContext } from '../../AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ResetPassword() {
    const {userToken} = React.useContext(AuthContext)
    const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPasswordOld, setShowPasswordOld] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordMatch, setShowPasswordMatch] = React.useState(false);
   const [ OldPassword,  setOldPassword] = useState("")


  const handleNewPassword = (event) => {
    setPassword(event.target.value);
    if(event.target.value === confirmPassword){
        setPasswordsMatch(true)
        
   }else{
     setPasswordsMatch(false)
     
   }
   
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if(event.target.value === password){
        setPasswordsMatch(true)
        
   }else{
     setPasswordsMatch(false)
     
   }
    
  };

  



  
  const handleMouseDownPassword = (event) => {
   // event.preventDefault();
  };
  const handleClickShowPasswordOld = () => setShowPasswordOld((show) => !show);
  
  const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
  
  const handleClickShowPasswordMatch = () => setShowPasswordMatch((show) => !show);
 



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async() => {
      if(!OldPassword){
        alert("Enter Your Old Password")
        return
      }
      const body={
        "oldPassword":OldPassword,
        "newPassword":password
      }
    try {
        const res = await Axios.post("/change-password", body, {
            headers:{
                Authorization:userToken
            }
        } )

          if(res.status===200){
             if(res?.data?.valid){
                alert(res?.data?.message)
                handleClose()
                
             }else{
                alert(res?.data?.message)
             }
              
            alert(res?.data?.message)
          }
     } catch (error) {
        console.log(error)
     }
       
     

      
      
  };

  return (
    <React.Fragment>
      <Button  sx={{color:'#6622cc',  textTransform:'unset'}}  onClick={handleClickOpen}>
        Reset Password
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
         Reset Your Password
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
             
         <div>
         <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Old Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPasswordOld ? 'text' : 'password'}
            onChange={(e)=>setOldPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordOld}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
         </div>

         <div>
         <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPasswordNew ? 'text' : 'password'}
            value={password}
             onChange={handleNewPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordNew}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
         </div>

         <div>
         <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPasswordMatch ? 'text' : 'password'}
            value={confirmPassword}
        onChange={handleConfirmPasswordChange}
       
        error={!passwordsMatch}

            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordMatch}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordMatch ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
           <FormHelperText id="component-error-text">{passwordsMatch ? 'Passwords match' : 'Passwords do not match'}</FormHelperText>
        </FormControl>
         </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" sx={{color:'#6622cc'}} autoFocus disabled={passwordsMatch?false:true}   onClick={handleSubmit}>
           Submit
          </Button>
        </DialogActions>
        
      </BootstrapDialog>
    </React.Fragment>
  );
}
