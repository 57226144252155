import React from 'react'
import impressionicon from '../../../asets/webimages/home/impressionicon.png'
import AdsClickIcon from '@mui/icons-material/AdsClick';
// import viewwsicon from '../../../asets/webimages/home/viewsicon.png'
import LinkIcon from '@mui/icons-material/Link';
import spenticon from '../../../asets/webimages/home/spenticon.png'
import BlinkLoader from '../../../BlinkLoader';
function WebHomeIpressionViewSpentCard({graphIpmression, isFbDataLoader}) {
    // console.log("grapgh", graphIpmression)
  //indian formate number
function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }
  return (
       <>
       <div className="row mt-5 gap-lg-4 mt-lg-4 align-items-center">
        <div className="col-3  col-lg-12 ">
        
            <div className='d-flex justify-content-start align-items-center shadowsm HomeIpressionViewSpentCard' style={{backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div>
                <img src={impressionicon} className='me-1'  alt="" />
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Impressions</p>
                    <p className='p-0 m-0 imp-num'>{isFbDataLoader?<BlinkLoader/>:graphIpmression?.impression?formatIndianNumber(graphIpmression?.impression):0}</p>
                </div>
            </div>
       
        </div>

        <div className="col-3 col-lg-12 ">
        <div className='d-flex justify-content-start align-items-center HomeIpressionViewSpentCard shadowsm ps-2' style={{backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div className=' d-flex justify-content-center align-items-center me-2' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
               
                <AdsClickIcon sx={{color:'#6622cc', fontSize:'15px'}}/>
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Clicks</p>
                    <p className='p-0 m-0 imp-num' >{isFbDataLoader?<BlinkLoader/>:graphIpmression?.views?formatIndianNumber(graphIpmression?.views):0}</p>
                </div>
            </div>
        </div>

        <div className="col-3 col-lg-12">
        <div className='d-flex justify-content-start align-items-center HomeIpressionViewSpentCard shadowsm ps-2' style={{ backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div className='me-2'>
                {/* <img src={spenticon} className='me-1'  alt="" /> */}
                <div className=' d-flex justify-content-center align-items-center me-2' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
               
                <LinkIcon sx={{color:'#6622cc', fontSize:'15px'}}/>
                </div>
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Link Clicks</p>
                    <p className='p-0 m-0 imp-num' >{isFbDataLoader?<BlinkLoader/>:graphIpmression?.linkclicks?formatIndianNumber(Math.round(graphIpmression?.linkclicks)):0}</p>
                </div>
            </div>
        </div>

        <div className="col-3 col-lg-12">
        <div className='d-flex justify-content-start align-items-center HomeIpressionViewSpentCard shadowsm ps-2' style={{ backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div className='me-2'>
                <img src={spenticon} className='me-1'  alt="" />
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Spent</p>
                    <p className='p-0 m-0 imp-num' >{isFbDataLoader?<BlinkLoader/>:graphIpmression?.spend?formatIndianNumber(Math.round(graphIpmression?.spend)):0}</p>
                </div>
            </div>
        </div>
        
       </div>
        {/* <div className=''>
        <div className='d-flex justify-content-start align-items-center ' style={{marginTop:'20px'}}> 
            <div className='d-flex justify-content-start align-items-center shadowsm HomeIpressionViewSpentCard' style={{backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div>
                <img src={impressionicon} className='me-1'  alt="" />
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Impressions</p>
                    <p className='p-0 m-0 imp-num'>{isFbDataLoader?<BlinkLoader/>:graphIpmression?.impression?formatIndianNumber(graphIpmression?.impression):0}</p>
                </div>
            </div>
        </div>

        <div className='d-flex justify-content-start align-items-center' style={{marginTop:'20px'}}> 
            <div className='d-flex justify-content-start align-items-center HomeIpressionViewSpentCard shadowsm ps-2' style={{backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div className=' d-flex justify-content-center align-items-center me-2' style={{width:'34px', height:'34px', backgroundColor:'#e2d6f3', borderRadius:'4px'}}>
                
                <AdsClickIcon sx={{color:'#6622cc', fontSize:'15px'}}/>
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Clicks</p>
                    <p className='p-0 m-0 imp-num' >{isFbDataLoader?<BlinkLoader/>:graphIpmression?.views?formatIndianNumber(graphIpmression?.views):0}</p>
                </div>
            </div>
        </div>

        <div className='d-flex justify-content-start align-items-center' style={{marginTop:'20px'}}> 
            <div className='d-flex justify-content-start align-items-center HomeIpressionViewSpentCard shadowsm ps-2' style={{width:'95%', height:'92px', backgroundColor:'#F3EFF8', borderRadius:'6px'}}>
                <div className='me-2'>
                <img src={spenticon} className='me-1'  alt="" />
                </div>
                <div>
                    <p className='p-0 m-0 imp-h' >Spent</p>
                    <p className='p-0 m-0 imp-num' >{isFbDataLoader?<BlinkLoader/>:graphIpmression?.spend?formatIndianNumber(Math.round(graphIpmression?.spend)):0}</p>
                </div>
            </div>
        </div>
        </div> */}
       
       </>
  )
}

export default WebHomeIpressionViewSpentCard